.help-modal {
    position: relative;
    &:hover {
        .navigate-button {
            opacity: 1;
        }
    }
    .navigate-button {
        position: absolute;
        top: 40%;
        left: 18px;
        right: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0.4;
        span {
            position: relative;
            span.navigate-icon::before {
                position: absolute;
                content: "";
                top: -8px;
                bottom: -4px;
                left: -5px;
                right: -4px;
                border-radius: 50%;
                background-color: #445cfe;
            }
            cursor: pointer;
            svg {
                font-size: 20px;
                color: #fff;
            }
        }
    }
    &.ant-modal {
        // width: 78vw !important;
        @media screen and (max-width: 768px) {
            // width: 90vw !important;
        }
    }
    .modal-wraper {
        margin: 0 auto;
        border-radius: 10px;
    }
    .slick-slide {
        opacity: 0;
    }
    .slick-slide.slick-active.slick-current {
        opacity: 1;
        // max-width: 58vw;
    }
    .no-data {
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 10vh;
        img {
            width: 100%;
            max-width: 400px;
        }
        > div {
            color: #888;
            font-size: 1.2rem;
        }
    }

    //antd modal header custom
    .ant-modal-header {
        background-color: #07f;
    }
    .ant-modal-title {
        color: #fff;
        font-weight: 700;
        font-size: 26px;
    }
    .ant-modal-close-x {
        svg {
            fill: white;
            width: 20px;
            height: 20px;
            &:hover {
                opacity: 0.6;
            }
        }
    }
    .ant-modal-content {
        border-radius: 15px;
        overflow: hidden;
    }
    .ant-modal-body {
        padding: 0px;
        padding-top: 0;
    }
}
