@use 'src/assets/styles/utilities/colors' as colors;
@use "src/assets/styles/utilities/functions" as f;

@mixin page-header-title($color: #ffffff) {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: -0.03em;
    color: $color;
    cursor: pointer;
}

.page-header-wrapper {
    .page-header {
        position: relative;
        background-color: colors.$primary;
        border-radius: 20px;
        min-height: 90px;
        padding: 0 30px;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
        height: 100%;

        .header-left-section,
        .header-mid-section {
            z-index: 2;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../../assets/images/decoration-clock.svg);
            background-repeat: no-repeat;
            background-position: right center;
            z-index: 1;
            mix-blend-mode: soft-light;
            opacity: 0.25;
        }
    }

    .btn-app-title {
        @include page-header-title();
    }

    .btn-go-back {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: revert;
        flex-wrap: nowrap;
        @include page-header-title();

        .go-back-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            width: 36px;
            height: 36px;

            > span,
            > span > svg {
                display: block;
                width: auto;
                height: 36px;
            }

            > img {
                display: block;
                width: auto;
                height: 36px;
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .btn-icon-only {
        z-index: 10;

        svg {
            display: inline-block;
            width: auto;
            height: 26px;
            max-width: 26px;
            color: #fff;
            cursor: pointer;
        }

        .act-button-wrapper,
        .act-button,
        .anticon {
            display: block;
        }
    }

    .action-list-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    .action-list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;

        .action-item {
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
        }

        .action-link,
        .action-button {
            color: #ffffff;
            padding: 9px 12px;
            border-radius: 10px;
            border: 1px solid #ffffff;
            box-sizing: border-box;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.03em;
            min-width: 125px;
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.primary {
                color: colors.$primary;
                background-color: #ffffff;
                opacity: 0.8;
                font-weight: 700;
            }

            &.disabled {
                cursor: auto;
                opacity: 0.8;
            }

            &:not(.disabled):hover {
                opacity: 0.8;
                transform: scale(0.95);
            }

            > svg {
                margin-right: 10px;
                width: auto;
                height: 20px;
                max-width: 20px;
            }
        }

        .action-icon {
            display: inline-block;
            min-width: 20px;
            width: 20px;
            height: 20px;
            margin-right: 10px;

            > img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .page-header-wrapper {
        .page-header {
            min-height: 80px;
        }

        .btn-go-back {
            font-size: 18px;

            .go-back-icon {
                width: 24px;
                height: 24px;

                > span,
                > span > svg {
                    display: block;
                    width: auto;
                    height: 24px;
                    max-width: 24px;
                }

                > img {
                    display: block;
                    width: auto;
                    height: 24px;
                    max-width: 24px;
                }
            }
        }

        .action-list-wrapper {
            gap: 16px;
        }

        .action-list {
            .action-item {
                margin-right: 16px;
            }

            .action-link,
            .action-button {
                padding: 6px;

                > svg {
                    height: 16px;
                    max-width: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .page-header-wrapper {
        .page-header {
            min-height: 70px;

            .btn-app-title {
                font-size: 22px;
            }
        }

        .action-list {
            .action-item {
                margin-right: 16px;
            }

            .action-button {
                > svg {
                    margin-right: 0;
                }
            }

            .action-icon {
                margin-right: 0;
            }

            .action-title {
                display: none;
            }

            .action-link,
            .action-button {
                min-width: unset;

                > svg {
                    height: 16px;
                    max-width: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .page-header-wrapper {
        .page-header {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            padding: 16px;

            .header-mid-section {
                margin-left: auto;
            }

            .btn-app-title {
                font-size: 18px;
            }
        }
    }
}
