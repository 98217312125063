.gradebook-item-loadmore {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 140px;
    cursor: pointer;
    > span {
        font-size: 18px;
    }
}

.gradebook-list-item-mobile {
    margin-left: 10px;
    margin-right: 10px;
}
