@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

html {
    scroll-behavior: smooth;
}

*,
body {
    font-family: "mulish", sans-serif;
}

body {
    overflow-y: scroll;
}

#root {
    min-height: 100vh;
    background-color: #f8f8f8;
    font-size: 16px;
    line-height: 24px;
}

.content-highlight,
.contentHighlight {
    .selected {
        user-select: none;
        cursor: pointer;
        background-color: yellow;
        color: red;

        * {
            display: inline;
        }
    }

    .selected[data-selected="true"] {
        outline: 3px solid #4099ff;
    }
}

.m-0 {
    margin: 0 !important;
}

.pdf__logo {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    img {
        width: 300px;
    }

    span {
        font-size: 100px;
    }
}

//disable text selection
.un_selectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

//enable text selection
.selectable {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-center {
    text-align: center;
}
