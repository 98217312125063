@use "src/assets/styles/utilities/breakpoint" as *;

.NumberQuestion {
    overflow-x: hidden;
    .NumberQuestion_title {
        font-weight: bold;
        line-height: 26px;
        font-size: 18px;
        text-align: center;
    }

    .NumberQuestion_sliderArrow {
        position: absolute;
        cursor: pointer;
        padding: 8px;
        border-radius: 4px;

        &:hover {
            background-color: #ddd;
        }

        &.NumberQuestion_sliderArrow--prev {
            left: -50px;
            top: 50%;
            transform: translateY(-50%);
        }

        &.NumberQuestion_sliderArrow--next {
            right: -50px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .NumberQuestion_list {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        justify-content: center;

        &.NumberQuestion_list--slider {
            display: block;
            padding: 0 20px;

            .NumberQuestion_item {
                margin: 0 auto;
            }
        }

        .NumberQuestion_item {
            border-radius: 8px;
            height: 45px;
            width: 45px;
            background: #eeeeee;
            margin: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;

            &:active {
                transform: scale(0.97);
            }

            &.active {
                background: #ffd00d;
                color: #000;
            }
        }
    }
}

@include tablet-down {
    .NumberQuestion {
        padding: 10px 0;
        .NumberQuestion_list .NumberQuestion_item {
            width: 36px;
            height: 36px;
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 450px) {
    .NumberQuestion_Responsive {
        .NumberQuestion {
            .NumberQuestion_list {
                &.NumberQuestion_list--slider {
                    padding: 0 40px;
                }
            }

            .NumberQuestion_sliderArrow {
                &.NumberQuestion_sliderArrow--prev {
                    left: -25px;
                }

                &.NumberQuestion_sliderArrow--next {
                    right: -25px;
                }
            }
        }
    }
}
