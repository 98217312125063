/**
 * 1.
 * [Text-displaying-related components] CSS variables:
 * - Editor.
 * - QuestionPanel.
 */
$txt-font-size: 12pt;
$txt-line-height: 1.5;

/**
  * 2.
  * [Default device screen size].
  */
$w: 1440;
$h: 1024;
