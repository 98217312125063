.question-detail-preview {
    .content-shading {
        overflow: auto;
        padding: 10px;
    }
}

.viewScoreStudent_shading {
    .content-shading {
        overflow: auto;
        padding: 10px;
    }
}

.question-panel {
    .content-shading.student {
        overflow: auto;
        padding: 10px;
    }
}
