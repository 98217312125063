.subnav-dropdown-menu {
    ul > li {
        margin: 0 !important;
        padding: 0 !important;
    }
    .subnav-link {
        display: flex;
        align-items: center;
        color: #a1becb;
        &.active {
            color: #6c94fb;
        }
        &:hover {
            color: #798a92;
        }
        span {
            margin-right: 20px;
            display: flex;
            align-items: center;
        }
        span svg {
            width: 30px;
            height: 24px;
        }
    }
}
