// ClassNames: _.
// - Extra classNames by type: app-txt-h1, app-txt-h2, app-txt-h3, app-txt-body, app-txt-caption, app-txt-tiny.

.app-txt-h1 {
    font-size: 48px;
    line-height: 56px;
    font-style: normal;
    font-weight: 700;
}

.app-txt-h2 {
    font-size: 32px;
    line-height: 36px;
    font-style: normal;
    font-weight: 700;
}

.app-txt-h3 {
    font-size: 24px;
    line-height: 32px;
    font-style: normal;
    font-weight: 700;
}

.app-txt-body {
    font-size: 16px;
    line-height: 24px;
}

.app-txt-caption {
    font-size: 14px;
    line-height: 20px;
}

.app-txt-tiny {
    font-size: 12px;
    line-height: 16px;
}

@media screen and (max-width: 1399.98px) {
    .app-txt-h1 {
        font-size: 28px;
        line-height: 36px;
    }

    .app-txt-h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .app-txt-h3 {
        font-size: 20px;
        line-height: 28px;
    }
}
