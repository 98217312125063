@use 'src/assets/styles/utilities/_colors.scss' as colorSet;
@use 'src/assets/styles/utilities/breakpoint.scss' as b;

.course-topic-info-panel {
    // Variables:
    $itemRadius: 15px;

    .course-topic-info {
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        border-radius: $itemRadius;
        overflow: hidden;
    }

    .topic-quick-info {
        position: relative;
        // min-height: 100px;
        min-height: clamp(80px, calc(100 / 1920 * 100vw), 100px);
        background-color: #ffffff;
        border-radius: calc($itemRadius + 1px);
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .topic-label {
            position: relative;
            display: inline-block;
            min-width: 240px;
            width: 16vw;
            min-height: 50px;
            height: 100%;
            color: #ffffff;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translate(0%, -50%);
                width: 100%;
                min-height: 208px;
                height: 14vw;
                height: clamp(170px, calc(238 / 1920 * 100vw), 238px);
                background: colorSet.$color_primary_Darkest;
                border-radius: 0 50% 50% 0;
                z-index: 0;
            }

            .label-content {
                position: absolute;
                top: 50%;
                left: 42%;
                transform: translate(-50%, -50%);
                z-index: 1;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                font-size: 19px;
                line-height: 1.2;
            }
        }

        .topic-title {
            position: relative;
            width: 100%;
            padding: 8px 11px 8px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            color: #263238;

            .title-content {
                color: inherit;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }

            .title-actions {
                color: inherit;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                column-gap: 8px;
                row-gap: 8px;
            }
        }

        .view-detail-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: clamp(4px, calc(8 / 1920 * 100vw), 8px);
            min-width: 93px;
            padding: 5px 0;
            cursor: pointer;
            color: #263238;
            font-size: clamp(14px, calc(18 / 1920 * 100vw), 18px);
            & > svg {
                fill: #263238;
                height: clamp(14px, calc(18 / 1920 * 100vw), 18px);
            }
        }
    }
    .topic-quick-info.active,
    .topic-quick-info:hover {
        background: colorSet.$color_primary_Darkest;

        .topic-label {
            color: #ffffff;

            &::after {
                background: none;
                background-color: #ffffff;
            }

            .label-content {
                background: colorSet.$color_primary_Darkest;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                // text-fill-color: transparent;
            }
        }

        .topic-title {
            color: #ffffff;
        }

        .view-detail-btn {
            color: #ffffff;

            & > svg {
                fill: #ffffff;
            }
        }
        button.dropdown-btn {
            border-color: #fff;
            svg {
                fill: #fff;
            }
        }
    }
    button.dropdown-btn {
        background-color: transparent;

        svg {
            font-size: 18px;
        }
        padding: 2px 6px;

        @media screen and (max-width: 1600px) {
            padding: 0 6px;
        }
    }

    @include b.tablet-down {
        .course-topic-info {
            border-radius: 8px;
        }
        .topic-quick-info {
            border-radius: 8px;
            min-height: unset;
            height: 60px;
            .topic-label {
                min-width: 100px;

                width: 16vw;
                &::after {
                    height: 20vw;
                    min-height: 90px;
                }
                .label-content {
                    -webkit-line-clamp: 1;
                    font-size: 16px;
                    line-height: 1.6;
                    padding-left: 4px;
                    padding-right: 4px;
                    text-align: center;
                    transform: translate(0, -50%);
                    right: 0;
                    left: 0;
                }
            }
            .topic-title .title-content {
                font-size: 16px;
                font-weight: 300;
                -webkit-line-clamp: 1;
            }
            .view-detail-btn {
                min-width: unset;
                > span {
                    display: none;
                }
                svg {
                    width: 16px;
                }
            }
            button.dropdown-btn svg {
                font-size: 12px;
            }
            .topic-title .title-actions {
                column-gap: 0;
            }
            .view-detail-btn {
                padding: 14px 8px;
            }
        }
        button.dropdown-btn {
            border: none;
            box-shadow: unset;
            padding: 14px 8px 14px 12px;
        }
    }
    @include b.small-mobile {
        .topic-quick-info {
            height: 45px;
            .topic-label .label-content {
                font-size: 14px;
            }
            .topic-title .title-content {
                font-size: 14px;
            }
            .view-detail-btn {
                svg {
                    width: 12px;
                }
            }
            button.dropdown-btn svg {
                font-size: 10px;
            }
        }
    }
}
