@use 'src/assets/styles/utilities/colors' as c;

.setting-header {
    margin-bottom: 30px;
}

.account-info-tabs {
    .ant-tabs > .ant-tabs-nav {
        width: auto;
        height: 55px;
        padding: 5px;
        // background: rgba($color: c.$primary, $alpha: 0.1);
        border: 1px solid rgba($color: c.$primary, $alpha: 0.5);
        border-radius: 50px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        min-width: 900px;

        .ant-tabs-nav-list {
            gap: 8px;
            width: 100%;
            border-radius: 30px;
            overflow: hidden;
            transition: transform 0.3s linear;
        }

        &::before {
            display: none;
        }
    }

    .ant-tabs-tab {
        justify-content: center;
        width: 100%;
        flex: 1;
        font-size: 16px;

        & + .ant-tabs-tab {
            margin-left: 0;
        }

        &:hover {
            color: unset;
        }

        &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: #fff;
                text-align: center;
                font-weight: 700;
            }
        }
    }

    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
        height: 100%;
        background-color: c.$primary;
        border-radius: 30px;
        z-index: -1;
    }

    .ant-tabs-nav-operations {
        display: none !important;
    }

    .separator {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            background-color: #fff;
            padding: 0 10px;
            z-index: 3;
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            border-top: 1px solid #bfbfbf;
        }
    }
}

// RESPONSIVE DESIGN:
@media screen and (max-width: 1399.98px) {
    .account-info-tabs {
        .ant-tabs > .ant-tabs-nav {
            height: 46px;
            min-width: 650px;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .account-info-tabs {
        .ant-tabs > .ant-tabs-nav {
            min-width: unset;
        }
    }
}
