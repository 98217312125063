.app-header-wrapper {
    padding: 0;

    .header-logo-wrapper {
        display: inline-block;
        user-select: none;
        cursor: pointer;

        & > img {
            width: auto;
            height: 68px;
        }

        &.with-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:hover {
                text-shadow: 0 0 5px rgb(0 0 0 / 20%);
            }
        }
    }

    .app-header {
        position: relative;
        background: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        min-height: 57px;
    }

    .act-menu {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 40px;

        .act-menu-item {
            position: relative;
            color: inherit;

            &:not(:last-child) {
                padding-right: 30px;

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    transform: translate(0, -50%);
                    width: 1px;
                    height: 30px;
                    background-color: #979c9e;
                }
            }
        }
    }

    .user-quick-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 20px;

        .user-avatar-wrapper {
            display: inline-block;
            width: 55px;
            height: 55px;
            min-width: 55px;
            border-radius: 50%;
            overflow: hidden;
            user-select: none;

            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .user-info-wrapper {
            display: block;
            max-width: 180px;
            overflow: hidden;

            .user-info {
                white-space: nowrap;
                display: inline-block;
                font-weight: 700;
                color: #303437;
            }

            &.limited {
                .user-info {
                    white-space: unset;
                    max-width: 180px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-clamp: 1;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }

            &.sliding {
                width: 180px;

                .user-info {
                    white-space: nowrap;
                    animation-name: sliding;
                    animation-duration: 8s;
                    animation-delay: 0s;
                    animation-iteration-count: infinite;
                    animation-direction: normal;
                    animation-timing-function: linear;
                    animation-fill-mode: forwards;

                    @keyframes sliding {
                        0% {
                            transform: translateX(180px);
                        }

                        100% {
                            transform: translateX(-100%);
                        }
                    }
                }
            }
        }

        .clickable-item {
            &:hover {
                .user-avatar-wrapper {
                    box-shadow: 0 0 0px 2px #eee;
                }
            }
        }

        .upgrade-btn {
            width: 100%;
            min-width: unset;
            min-height: unset;
            font-weight: normal;

            &.subsc-btn .subsc-btn-icon {
                width: 26px;
                height: 26px;
                min-width: 26px;

                & > svg {
                    display: block;
                    width: 20px;
                    height: 20px;
                }
            }

            &.subsc-btn .subsc-btn-name {
                text-transform: capitalize;
            }
        }
    }

    .language-setting {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 9px;

        & > span:nth-child(2) {
            color: #0047ff;
        }

        & > .ant-dropdown-trigger {
            user-select: none;
            cursor: pointer;
        }
    }

    .act-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 25px;
    }

    .act-button-wrapper {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;

        .act-button {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #f2f4f5;
        }

        .act-button.question {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                font-size: 22px;
                font-weight: 700;
                color: rgb(151, 156, 158);
            }
        }

        .act-icon {
            display: block;
            height: 100%;
            text-align: center;
        }

        .act-icon > svg {
            height: 50px;
        }

        &:hover {
            .act-button {
                background-color: #eee;
            }
        }

        &:active {
            .act-button {
                transform: scale(0.8);
            }
        }
    }

    .app-header-navsidebar {
        display: none;
    }
}

@media screen and (max-width: 1399.98px) {
    .app-header-wrapper {
        .header-logo-wrapper {
            & > img {
                height: 58px;
            }
        }

        .act-menu {
            column-gap: 20px;

            .act-menu-item {
                &:not(:last-child) {
                    padding-right: 20px;
                }
            }
        }

        .user-quick-info {
            .user-avatar-wrapper {
                width: 45px;
                height: 45px;
                min-width: 45px;
            }
        }

        .language-setting {
            column-gap: 8px;

            & > span:nth-child(1) {
                display: none;
            }
        }

        .act-list {
            column-gap: 8px;
        }

        .act-button-wrapper {
            width: 45px;
            height: 45px;

            .act-icon > svg {
                height: 45px;
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .app-header-wrapper {
        .act-menu {
            column-gap: 16px;

            .act-menu-item {
                &:not(:last-child) {
                    padding-right: 16px;
                }
            }
        }

        .user-quick-info {
            column-gap: 16px;

            .user-avatar-wrapper {
                width: 40px;
                height: 40px;
                min-width: 40px;
            }
        }

        .language-setting {
            display: none;
        }

        .act-button-wrapper {
            width: 40px;
            height: 40px;

            .act-icon > svg {
                height: 40px;
            }
        }

        .act-button-wrapper {
            &:not(.not-hide) {
                display: none;
            }
        }

        .app-header-navsidebar {
            display: block;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .app-header-wrapper {
        .header-left-section {
            display: none;
        }

        .header-right-section {
            width: 100%;
        }

        .act-menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;

            .act-menu-item {
                &:not(:last-child) {
                    &::after {
                        display: none;
                    }
                }
            }
        }

        .user-quick-info {
            column-gap: 8px;

            .user-avatar-wrapper {
                width: 40px;
                height: 40px;
                min-width: 40px;
            }
        }

        .language-setting {
            display: none;
        }
    }
}
