.viewScoreStudent_highlightImage {
    overflow: auto;
    .viewScoreStudent_writingTitle {
        font-weight: bold;
    }
    &-editor {
        // overflow-x: auto;
    }
    .zwibbler-container {
        max-height: unset !important;
    }
    .zwibbler-draw-container {
        width: fit-content;
    }
}
