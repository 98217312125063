.question-detail-shading {
    .shade-cells {
        &_container {
        }
        &_grid {
            display: flex;
            overflow: auto;
            padding: 10px;
            max-height: 600px;
        }
        &-title {
            margin-bottom: 10px;
        }
    }

    .shade-options {
        justify-content: space-between;
        &_items {
            flex-direction: column;
        }
        .ant-col {
            flex: 1;
            width: 90%;
        }
        .shade-options-input {
            min-width: 90%;
        }
    }
    .shade-cells-select-method {
        min-width: 300px;
        width: auto !important;
    }
}
