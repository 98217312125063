.video-controls {
    &_container {
        margin: 10px;
        margin-bottom: 0;
        width: 100%;
    }
    &_inner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 4px;
        background-color: rgba(17, 17, 17, 0.1);
        border-radius: 4px;
        gap: 10px;
    }
    &_center {
        flex-grow: 1;

        .ant-slider-rail {
            position: absolute;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: var(--bar_width);
                height: 100%;
                background-color: #fff;
            }
        }
    }
    &_right {
        display: flex;
        gap: 10px;
    }
    &_left {
        .btn-play {
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}
