.table-histories-render {
    border-collapse: collapse;
    width: 100%;

    th {
        background-color: #e8f3fa;
    }

    th,
    td {
        border: 1px solid #aaa;
    }

    th,
    td {
        padding: 8px;
    }

    td {
        vertical-align: top;
        min-width: 200px;
    }
}
