@use "src/assets/styles/utilities/breakpoint" as *;

.table-bottom {
    .ant-table-wrapper {
        min-width: 580px;
        overflow-x: auto;
    }
    th.ant-table-cell {
        div.ant-dropdown-trigger {
            white-space: nowrap;
        }
    }
    .ant-table-content {
        overflow: auto hidden;
    }
    .ant-table-thead > tr > th:first-child {
        // min-width: 230px !important;
    }

    @include tablet-down {
        .ant-table-wrapper {
            min-width: unset;
            overflow-x: unset;
        }
        .gradebook-table {
            table {
                width: 100% !important;
            }
        }
    }
    .table-action-button {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
}
.gradebook-table-col-filter {
    max-height: 200px;
    overflow-y: auto;
}

.gradebook-table {
    &.teacher {
        padding-top: 56px;
    }
}
