.testLayout_wrapper {
    background: #f0f7ff;
    min-height: 100vh;

    .container {
        margin-right: 352px;
    }
}

@media screen and (max-width: 1024px) {
    .testLayout_wrapper {
        .container {
            margin-right: 0;
        }
    }
}
