.student-view-result {
    height: calc(100vh - 100px);

    display: flex;
    align-items: center;
    justify-content: center;

    .email-verify {
        min-height: 300px;
        padding: 30px;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        background-color: #fff;
        max-width: 800px;
        margin: 0 auto;
        overflow: auto;
        animation: appearing 0.5s ease 0s 1 normal forwards;
    }

    .view-result-selection {
        &_actions {
            background-color: #fff;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
        }
    }
}
