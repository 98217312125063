@use 'src/assets/styles/utilities/colors' as c;

.modal-displayschktrggr {
    .check-info-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        color: c.$primary;
    }

    .modal-title {
        text-align: center;
    }

    .modal-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 16px;
    }
}
