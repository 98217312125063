@use 'src/assets/styles/utilities/colors' as c;

.login {
    position: relative;
    margin: 0 -40px 0 -40px;
    padding: 0 40px 0 40px;
    background-color: #fff;

    .login-layout-wrapper {
        min-height: calc(100vh - 108px);
        overflow: auto;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        flex-wrap: wrap;

        .login-tabs {
            box-shadow: none;
        }
    }

    .login-right {
        width: 50%;

        .decoration-wrapper {
            position: relative;
            width: 100%;
            height: 100%;

            .decoration-middle {
                & > svg,
                & > img {
                    display: block;
                    width: 100%;
                }
            }

            .decoration-middle {
                width: 100%;
                max-width: 899px;
                min-height: calc(100vh - 108px);
                padding-left: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .login-left {
        width: 50%;

        .login-tabs-wrapper {
            justify-content: flex-end;
            min-height: calc(100vh - 108px);
            padding-right: 64px;
        }
    }

    .login-middle {
        width: 100%;
    }
}

// RESPONSIVE DESIGN:
@media screen and (max-width: 1399.98px) {
    .login {
        .login-right {
            .decoration-wrapper {
                .decoration-middle {
                    padding-left: 16px;
                }
            }
        }

        .login-left {
            .login-tabs-wrapper {
                padding-right: 32px;
            }
        }
    }
}

@media screen and (max-width: 1199.98px) {
    .login {
        background-color: #fff;

        .login-right {
            display: none;
        }

        .login-left {
            width: 100%;

            .login-tabs-wrapper {
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .login {
        margin: 0 -30px 0 -30px;
        padding: 0 30px 0 30px;
    }
}

@media screen and (max-width: 575.98px) {
    .login {
        margin: 0 -16px 0 -16px;
        padding: 0 16px 0 16px;
    }
}
