.q-result.q-drag-drop {
    .qc_selected-answers {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 8px;

        .answer-for-drag-drop {
            width: 100%;
        }
    }

    .qc_correct-answers {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .answer-for-drag-drop {
            width: 100%;
        }
    }
}
