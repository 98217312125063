@use 'src/assets/styles/utilities/variables' as vars;

/*
 * ----------------------------------------------------------------------------------------------------
 * BE CAREFUL TO MODIFY THIS FILE!
 * ANY CHANGING OF THIS FILE WILL AFFECT MANY OTHER FILES!
 * 
 * PLEASE DON'T CHANGE THE DEFAULT CODE (FROM LINE 9 TO LINE 30)!
 * ----------------------------------------------------------------------------------------------------
 */

// ClassNames: app-modal, flexible-height, type-primary, type-basic, maxw-520.

// (Ant Design) Modal:
.ant-modal.app-modal {
    width: calc(100vw - 40px) !important;
    height: calc(100vh - 40px);
    max-width: 940px;
    max-height: 940px;
    margin: 20px 0;

    .ant-modal-content {
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
    }

    .ant-modal-header {
        background: none;
    }

    .ant-modal-body {
        height: calc(100% - 80px);
        overflow-x: hidden;
        overflow-y: auto;
    }

    /*
    ****************************************************************************************************
    TYPES:
    ****************************************************************************************************
    */
    &.maxw-520 {
        max-width: 520px;
    }

    &.maxw-360 {
        max-width: 360px;
    }

    &.flexible-height {
        height: unset;
        max-height: unset;

        .ant-modal-body {
            overflow: unset;
        }
    }

    &.type-primary {
        .ant-modal-header {
            border-radius: 10px 10px 0 0;
            border: none;
            min-height: 80px;
            padding: 22px 30px;

            .ant-modal-title {
                font-weight: 700;
                font-size: 26px;
                line-height: 36px;
                text-align: left;
                color: #000000;
            }
        }

        .ant-modal-body {
            padding: 0 30px 30px 30px;
        }

        .ant-modal-close {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 15px;
            right: 15px;
            background-color: #fff;
            filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
            border-radius: 5px;
            overflow: hidden;

            &:hover {
                opacity: 0.5;
            }

            .ant-modal-close-x {
                position: relative;
                width: 100%;
                height: 100%;
                display: inline-block;
                display: flex;
                justify-content: center;
                align-items: center;
                fill: #000000;

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: #c4c4c4;
                    opacity: 0.2;
                    z-index: -1;
                }
            }
        }
    }

    &.type-basic {
        .ant-modal-header {
            border-radius: 10px 10px 0 0;
            border: none;
            min-height: 80px;
            padding: 0 30px;

            .ant-modal-title {
                font-weight: 700;
                font-size: 26px;
                line-height: 80px;
                text-align: left;
                color: #000000;
            }
        }

        .ant-modal-body {
            padding: 0 30px 30px 30px;
        }

        .ant-modal-close {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 15px;
            right: 15px;
            background-color: #fff;
            filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
            border-radius: 5px;
            overflow: hidden;

            &:hover {
                opacity: 0.5;
            }

            .ant-modal-close-x {
                position: relative;
                width: 100%;
                height: 100%;
                display: inline-block;
                display: flex;
                justify-content: center;
                align-items: center;
                fill: #000000;

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: #c4c4c4;
                    opacity: 0.2;
                    z-index: -1;
                }
            }
        }

        // Form items:
        .ant-form-horizontal .ant-form-item-label {
            min-width: 200px;
            text-align: left;
            margin-right: 24px;

            & > label {
                font-weight: 700;
                font-size: 18px;
                color: #181818;
                line-height: 45px;
                height: auto;
            }
        }

        // ANT DESIGN - Input:
        .ant-form-item-control-input-content .ant-input {
            border: 1px solid rgba(0, 0, 0, 0.25);
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            border-radius: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 35px;
            padding: 4px 20px;

            &::placeholder {
                color: rgba(#636364, 0.5);
                // opacity: 0.5;
                font-style: inherit;
            }
        }

        // ANT DESIGN - Select:
        .ant-select.app-select:not(.ant-select-customize-input) .ant-select-selector {
            border: 1px solid rgba(0, 0, 0, 0.25);
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            border-radius: 10px;
        }

        .ant-select.app-select .ant-select-selector .ant-select-selection-placeholder {
            font-style: normal;
            font-weight: 400;
            color: #636364;
        }

        // ANT DESIGN - DatePicker:
        .ant-picker {
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            border-radius: 10px;
            width: 100%;
            padding: 4px 20px;

            &:not(.ant-picker-status-error) {
                border: 1px solid rgba(0, 0, 0, 0.25);
            }

            .ant-picker-input > input {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 35px;

                &::placeholder {
                    // color: #636364;
                    // opacity: 0.5;
                    color: rgba(#636364, 0.5);
                }
            }
        }

        // ANT DESIGN - InputNumber:
        .ant-input-number {
            display: block;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.25);
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            border-radius: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 35px;
            padding: 2px 10px;

            &::placeholder {
                // color: #636364;
                color: rgba(#636364, 0.5);
                // opacity: 0.5;
            }
        }

        // Elements:
        .btn-group {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 30px;
            margin-top: 30px;

            .btn {
                flex: 1 1 0%;
                width: 175px;
                max-width: 175px;
            }
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1439.98px) {
    .ant-modal.app-modal.type-basic {
        $headerHeight: clamp(55px, calc(80 / vars.$w * 100vw), 80px);

        .ant-modal-header {
            min-height: $headerHeight;

            .ant-modal-title {
                line-height: $headerHeight;
                font-size: clamp(22px, calc(26 / vars.$w * 100vw), 26px);
            }
        }

        .ant-modal-body {
            height: calc(100% - $headerHeight);
        }

        // Form items:
        .ant-form-horizontal .ant-form-item-label {
            & > label {
                line-height: clamp(36px, calc(45 / vars.$w * 100vw), 45px);
            }
        }

        // ANT DESIGN - Input:
        .ant-form-item-control-input-content .ant-input {
            line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
        }

        // ANT DESIGN - Select:
        .ant-select.app-select:not(.ant-select-customize-input) .ant-select-selector {
            min-height: clamp(36px, calc(45 / vars.$w * 100vw), 45px);
        }

        .ant-select.app-select .ant-select-selector .ant-select-selection-placeholder,
        .ant-select.app-select .ant-select-selector .ant-select-selection-item {
            line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
            line-height: clamp(26px, calc(30 / vars.$w * 100vw), 30px);
        }

        // ANT DESIGN - Select (multiple):
        .ant-select.app-select.ant-select-multiple {
            min-height: clamp(36px, calc(45 / vars.$w * 100vw), 45px);

            &:not(.ant-select-customize-input) .ant-select-selector {
                padding-top: 2px;
                padding-bottom: 2px;
            }

            .ant-select-selector:after {
                line-height: 18px;
            }

            .ant-select-selector .ant-select-selection-overflow {
                min-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
            }

            .ant-select-selector .ant-select-selection-placeholder,
            .ant-select-selector .ant-select-selection-item,
            &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
                line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
            }

            .ant-select-selector .ant-select-selection-item {
                line-height: clamp(20px, calc(29 / vars.$w * 100vw), 35px);
            }
        }

        // ANT DESIGN - DatePicker:
        .ant-picker {
            .ant-picker-input > input {
                line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
            }
        }

        // ANT DESIGN - InputNumber:
        .ant-input-number {
            line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
        }

        // Elements:
        .btn-group {
            gap: clamp(16px, calc(30 / vars.$w * 100vw), 30px);
            margin-top: clamp(16px, calc(30 / vars.$w * 100vw), 30px);

            .btn {
                min-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
                max-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
            }
        }
    }
}
