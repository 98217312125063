.file-viewer-modal.view-file-default {
    .ant-modal-content {
        height: 100%;
    }

    .ant-modal-body {
        height: calc(100% - 77px - 53px);
    }
}

.file-viewer-modal.view-file-ggdrive {
    .modal-view-file {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        display: none;

        .modal-wrapper {
            position: relative;
            width: 100%;
            height: 100%;

            .modal-overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.85);
                z-index: 1100;
            }

            .modal-content {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .modal-header {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                min-height: 55px;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, transparent 100%);
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 15px;
                z-index: 1101;

                .action-button {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    margin-right: 10px;
                    cursor: pointer;

                    > span {
                        height: 100%;
                    }

                    > span > svg {
                        fill: #fff;
                        font-size: 20px;
                        height: 100%;
                    }

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.5);
                        border-radius: 3px;
                    }
                }

                .header-left-section,
                .header-right-section {
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .modal-body {
                position: absolute;
                top: 55px;
                left: 50%;
                transform: translate(-50%, 0);
                width: calc(100% - 55px - 55px);
                height: calc(100% - 55px - 55px);
                background: none;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1101;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    .modal-view-file.visible {
        display: block;
    }
}
