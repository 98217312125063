.sort_item {
    width: 100%;
    display: flex;

    .ant-row {
        margin-bottom: 0px;
        animation: fadeIn 0.3s ease-in-out;
        width: 100%;

        .ant-form-item-label {
            padding: 0 5px;

            .ant-form-item-no-colon {
                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}
