.image-skeleton-item {
    position: relative;
    z-index: 1;
    &.skeleton {
        &::before {
            content: "";
            display: block;
            position: absolute;
            animation: skeleton-loading 1s linear infinite alternate;
            inset: 0 -150%;
        }
        img {
            opacity: 0;
        }
    }
    @keyframes skeleton-loading {
        0% {
            background-color: hsl(200, 20%, 80%);
        }
        100% {
            background-color: hsl(200, 20%, 95%);
        }
    }
}
