.list-violation {
    height: 100%;
    padding: 0 5px;
    min-width: 250px;
    // max-width: 200px;

    .violation-item {
        display: flex;
        gap: 12px;
        margin-bottom: 10px;
        &:hover {
            cursor: pointer;
            color: #4b4192;
        }
    }
}
