.modal_histories {
    .timeline_body {
        max-height: 500px;
        overflow-y: auto;
        padding: 20px;
        margin-bottom: 40px;
        margin-top: 40px;

        .old_value_item {
            // background-color: #ccc;
            padding: 5px;
            display: inline-block;
            border-radius: 10px;
            margin: 4px;
        }
        .new_value_item {
            // background-color: #f0b3b3;
            padding: 5px;
            display: inline-block;
            border-radius: 10px;
            margin: 4px;
        }
        .model_name {
            border: solid 1px #ddd;
            border-radius: 10px;
            padding: 5px;
            &.question {
                cursor: pointer;
                &:hover {
                    border-color: #5e73ec;
                }
            }
        }
    }
}

// style for exam question history
.timeline_body {
    &.exam-question {
        max-height: calc(100vh - 380px);
        overflow-y: auto;
    }
    padding: 0 20px;
}
