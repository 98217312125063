.organization-user-list {
    &__toolbar {
        margin-bottom: 30px;
    }

    .organization-user-list-actions {
        text-align: center;

        svg {
            font-size: 25px;
            color: #1890ff;

            &:hover {
                opacity: 0.7;
            }
        }

        .action-btn {
            cursor: pointer;
        }

        .action-btn.organization-user-delbtn {
            span svg {
                color: #f5222d;
            }
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1199.98px) {
    .organization-user-list {
        .toolbar-filter-wrapper {
            .filter-tools {
                align-items: flex-start;
            }

            .filter-actions {
                width: 100%;
                text-align: right;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .question-bank {
        .toolbar-filter-wrapper {
            .filter-tools {
                align-items: stretch;
                flex-direction: column;
            }
        }
    }
}
