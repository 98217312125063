@use 'src/assets/styles/utilities/colors' as c;

/*
 * Global class names:
 * _
 */

.q-drag-drop-group {
    .q-content-section {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .dragDropGroup_container {
        flex-wrap: wrap;
        margin-bottom: 10px;
        column-gap: 50px;
        margin-left: 15px;
        display: flex;

        .dropEmptyItem {
            // height: calc(100% - 22px);
            min-height: 80px;
            border: 1px dashed #959595;
            max-width: 230px;

            &.answer {
                width: 266px;
                height: 100%;
            }

            // &.group {}
        }
    }

    .dragDropQuestion_container {
        width: 100%;

        .dragDropGroup_listAnswer {
            list-style: none;
            position: relative;

            &.answer {
                min-height: 100px;
                max-width: 250px;
                width: 100%;
                padding: 10px;
                box-shadow: 0px 0px 5px #959595;
                border-radius: 4px;
            }

            &.group {
                flex-grow: 1;
                min-width: 230px;
                display: flex;
                margin-top: 10px;
                justify-content: center;
                column-gap: 10px;
                row-gap: 20px;
                height: auto;
                flex-wrap: wrap;
            }

            .dragDrop_itemWrapper {
                width: 230px;

                .dragDrop_itemTitle {
                    display: block;
                    text-align: center;
                    min-height: 50px;
                }

                .dragDrop_itemTitle_pinyin {
                    text-align: center;
                }

                .dragDrop_item_answerTitle {
                    white-space: nowrap;
                    margin-left: 19px;
                }
            }

            .dragDrop_item {
                box-shadow: 0px 0px 1px 1px #ddd;

                box-sizing: border-box;
                flex-basis: 100%;
                display: flex;
                align-items: center;
                position: relative;
                margin: 0 !important;

                .dragDrop_item-value {
                    // word-break: break-all;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 8px;
                }

                .dragDrop_item-value-pinyin {
                    line-height: 1.2 !important;
                    font-size: 14px !important;
                    & > * {
                        line-height: 1.2 !important;
                        font-size: 14px !important;
                    }
                }

                &.dragging {
                    background: c.$primary;
                    list-style: none;
                    color: #fff;
                }

                &.disabled {
                    background-color: rgba(132, 132, 132, 0.1);
                    cursor: not-allowed !important;

                    img {
                        cursor: not-allowed !important;
                    }
                }

                img {
                    padding: 10px;
                    display: block;
                    cursor: -moz-grab;
                    cursor: grab;
                }

                .pinyin_text,
                .pinyin_text > * {
                    line-height: 1.2 !important;
                    font-size: 14px !important;
                    padding-left: 2px;
                }
            }
        }

        .dragDrop_item {
            .dragDrop_itemTitle_pinyin,
            .dragDrop_itemTitle_pinyin > * {
                font-size: clamp(14px, 1.5vw, 20px) !important;
                line-height: 1.2 !important;
            }
        }

        // .dragDropQuestion_title {
        //     margin: 30px 46px;
        // }

        @media screen and (max-width: 992px) {
            width: 100%;
        }

        @media screen and (max-width: 576px) {
            .dragDropQuestion_title {
                margin: 10px 20px;
            }

            // .dragDropGroup_container {
            //     margin: 20px 0px;
            // }

            .dragDropGroup_listAnswer {
                &.group {
                    justify-content: center;
                }
            }
        }
    }
}
