.webcam-capture {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_container {
        position: relative;
        overflow: hidden;
        border: solid 2px #ccc;
        border-radius: 10px;
        margin-bottom: 20px;
        overflow: hidden;

        video {
            display: block;
        }
    }

    .face-frame {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .bg1 {
            background: rgba(0, 0, 0, 0.8);
            position: absolute;
            height: 19px;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
        }
    }
    .face-frame-img {
        margin-top: 19px;
        width: 100%;
        height: 100%;
    }
}
