@use "src/assets/styles/utilities/breakpoint" as *;

.my-storage-wrapper {
    .page-header-wrapper {
        margin-bottom: 30px;
    }

    .my-storage-panel {
        .space-usage-wrapper {
            height: 100%;
            min-height: 300px;
            background-color: #fff;
            border-radius: 10px;

            .space-usage-header {
                padding: 15px;

                .header-title {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 23px;
                    text-decoration: underline;
                    padding-left: 4px;
                    border-left: 4px solid #000;
                }
            }

            .space-usage-chart-wrapper {
                width: 100%;
                min-height: calc(100% - 53px);

                .disk-usage-statistics {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .file-manager-wrapper {
            height: calc(100vh - 160px);
            min-height: 500px;
            background-color: #fff;
            border-radius: 10px;

            .file-manager-container {
                height: 100%;
                background: none;
                box-shadow: none;
            }

            .file-manager-container .file-manager-modal {
                border-radius: 10px;
            }
        }
    }

    @include tablet-down {
        margin-top: 15px;
    }
}
