.email-verify {
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .email-form {
        max-width: 500px;
        min-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ant-form-item {
            width: 100%;
        }

        .ant-form-item-label label {
            font-size: 16px;
            font-weight: 600;
        }
        .form-actions {
            text-align: center;
        }
    }

    .email-form.code-form {
        .resent-code-btn {
            font-weight: 600;
            cursor: pointer;
            color: #c99400;
            &:hover {
                font-weight: 700;
            }
        }
        .code-sent-btn {
            font-weight: 600;
            svg {
                cursor: pointer;
            }
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 70%;
        min-width: 450px;
    }
    &_form-notice {
        text-align: center;
        word-break: break-word;
        color: rgb(112, 122, 138);
        width: 90%;
        margin-bottom: 30px;
    }

    &_navigate {
        width: 100%;
        margin-bottom: 20px;
        .navigate-wrapper {
            font-size: 18px;
            font-weight: 600;
            opacity: 0.77;
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
        }
    }

    .candidate-info-title {
        margin-bottom: 20px;
    }
}
