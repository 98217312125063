.captcha-with-vertify {
    user-select: none;

    .captcha-input {
        position: relative;

        &.readonly {
            user-select: none;
            pointer-events: none;
        }
    }
}

.captcha-box {
    .canvasArea {
        canvas {
            display: block;
        }
    }

    .vertifyWrap .sliderContainer {
        margin-top: 16px;
        background-color: #eee;
        border: 1px solid #d9d9d9;
    }

    .vertifyWrap .slider {
        height: 38px;
        background-color: #fff !important;
        border: none;

        &::before {
            background-color: transparent;
        }

        &:hover {
            .sliderIcon {
                color: #000;
            }
        }
    }

    .vertifyWrap .sliderIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .vertifyWrap .sliderContainer_active .sliderMask {
        border: none;
    }

    .vertifyWrap .sliderContainer_success .sliderMask,
    .vertifyWrap .sliderContainer_fail .sliderMask {
        border: none;
    }
}

.captcha-with-vertify-dropdown {
    &.ant-dropdown {
        .captcha-box-of-dropdown {
            margin: 0;
            padding: 8px;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #f5f5f5;
            border-radius: 10px;
            outline: none;
            box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
        }
    }
}
