@use "src/assets/styles/utilities/breakpoint" as *;

.gradebook-item-mobile {
    @mixin trext-truncate {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
    border-radius: 8px;
    box-shadow: 0px 5px 20px 0px #0000000d;
    overflow: hidden;

    &_content {
        background-color: #fff;
        padding: 20px;
    }
    &_title {
        font-size: 16px;
        font-weight: 700;

        background: linear-gradient(180deg, #fb3b50 0%, #f54e62 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        @include trext-truncate();
    }
    &_field {
        font-size: 14px;
        > span {
            font-size: 14px;
            font-weight: 700;
        }
    }

    &_action.teacher {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        height: 30px;
        margin-top: 10px;

        .action_btn {
            font-size: 12px;
            border-color: #0077ff;
            color: #0077ff;
            border-radius: 5px;
            height: auto;
            background-color: transparent;
        }
    }

    .table-action-button {
        margin-top: 10px;
        flex-direction: row;
        justify-content: center;
        column-gap: 10px;
    }

    @include small-mobile {
        &_title {
            font-size: 14px;
        }
        &_field {
            font-size: 12px;
            > span {
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }
}
