@use 'src/assets/styles/utilities/colors' as c;

.answer-for-multiple-choice {
    &:hover {
        opacity: 0.8;
    }

    .answer-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 12px;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        border-radius: 10px;
        transition: all 0.3s ease;
        cursor: pointer;

        .answer-content {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .answer-letter {
                user-select: none;
                cursor: pointer;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: radial-gradient(100% 100% at 142.5% 13.75%, #ffffff 0%, #0077ff 100%);
                text-align: center;
                line-height: 30px;
                font-weight: 700;
                font-size: 16px;
                color: #fff;
                flex: none;
            }

            .answer-text {
                margin-left: 10px;
                word-break: keep-all;
            }

            .answer-text-pinyin {
                margin-left: 10px;
                max-height: 0;
                // height: 100%;
                transition: max-height 1.2s linear;
                overflow: hidden;
                font-size: 13px !important;
                // display: inline-block;
                * {
                    font-size: 13px !important;
                    line-height: 1.4;
                    display: inline-block;
                }
            }
            .answer-text-pinyin.show {
                max-height: 500px;
                // height: 100%;
            }
        }
    }

    &.chosen {
        .answer-wrapper {
            background-color: c.$second;

            .answer-content {
                .answer-letter {
                    background: none;
                    background-color: #fff;
                    color: c.$second;
                }

                .answer-text {
                    color: #fff;
                }
            }
        }
    }

    &.disabled {
        .answer-wrapper {
            cursor: not-allowed;
        }
    }
}
