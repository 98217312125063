// ClassNames:
// - Used for Select: app-select, show-arrow.
// - Used for Select's dropdown: app-select-dropdown, type-wordwrap.
// - Responsive design: has-rd.

// Select:
.app-select.ant-select {
    $input-padding-left: 16px;

    &:not(.ant-select-customize-input) {
        .ant-select-selector {
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 4px 35px 4px $input-padding-left;
            height: auto;
        }
    }

    &:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 100%;
    }

    .ant-select-selector {
        .ant-select-selection-search {
            left: $input-padding-left;
            right: 35px;
        }

        .ant-select-selection-placeholder {
            font-style: italic;
            // font-weight: 300;
            font-size: 14px;
            line-height: 35px;
            color: #000000;
            opacity: 0.5;
        }

        .ant-select-selection-item {
            font-size: 14px;
            line-height: 35px;
        }
    }

    &.ant-select-focused {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                border-color: #d9d9d9;
            }
        }
    }
}

// Select - multiple selection:
.app-select.ant-select.ant-select-multiple {
    $input-padding-left: 16px;

    .ant-select-selector {
        .ant-select-selection-search {
            left: 0;
            right: 0;
            margin-inline-start: 0;
        }

        .ant-select-selection-placeholder {
            left: $input-padding-left;
            right: 35px;
            line-height: 43px;
        }

        .ant-select-selection-overflow {
            min-height: 35px;

            .ant-select-selection-item {
                font-size: 14px;
                line-height: 29px;
                height: auto;
            }
        }
    }
}

// Select dropdown:
.app-select-dropdown.ant-select-dropdown {
    &.ant-select-dropdown-empty {
        .ant-select-item,
        .ant-select-item-empty {
            padding: 0;
            line-height: unset;
            min-height: unset;
        }
    }

    &.type-wordwrap {
        padding: 0;

        .rc-virtual-list-holder {
            padding: 4px 0;
            max-width: 480px;
        }

        .ant-select-item-option-content {
            white-space: pre-wrap;
        }
    }

    // Custom element - Not Found Content:
    .notfound-when-loading {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 32px;
        padding: 1px 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        .ant-spin-spinning .ant-spin-dot-spin {
            display: block;
        }
    }
}

// Arrow:
.app-select.ant-select.show-arrow:not(.ant-select-focused) {
    .ant-select-selector {
        &::after {
            content: "";
            position: absolute;
            width: 15px;
            height: 10px;
            margin: 0;
            background-image: url(../../images/icon-select-chevron-down.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            visibility: visible;
            top: 50%;
            right: 10px;
            left: auto;
            transform: translateY(-50%);
        }
    }

    .ant-select-arrow {
        display: none;
    }

    &:hover {
        .ant-select-selector {
            &::after {
                content: unset;
            }
        }
    }
}

// Select - input field inside dropdown:
.app-select-dropdown-input {
    padding: 0 8px 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

// RESPONSIVE DESIGN:
.has-rd {
    @media screen and (max-width: 1399.98px) {
        // Select:
        &.app-select.ant-select {
            .ant-select-selector {
                .ant-select-selection-placeholder,
                .ant-select-selection-item {
                    line-height: 26px;
                }
            }
        }

        // Select - single selection:
        &.app-select.ant-select.ant-select-single {
            &:not(.ant-select-customize-input) {
                .ant-select-selector:after {
                    line-height: 26px;
                }
            }
        }

        // Select - multiple selection:
        &.app-select.ant-select.ant-select-multiple {
            .ant-select-selector {
                .ant-select-selection-placeholder {
                    line-height: 36px;
                }

                .ant-select-selection-overflow {
                    min-height: 26px;
                }

                &:after {
                    line-height: 18px;
                }
            }

            .ant-select-selector .ant-select-selection-placeholder,
            .ant-select-selector .ant-select-selection-item,
            &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
                line-height: 26px;
            }

            .ant-select-selector .ant-select-selection-item {
                line-height: 20px;
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .app-select-dropdown.ant-select-dropdown {
        &.type-wordwrap {
            .rc-virtual-list-holder {
                max-width: 360px;
            }
        }
    }
}
