@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

.subscription-plans-modal {
    .modal-subsc-plans {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1001;
        transition: all 0.5s ease;
        display: none;

        .modal-wrapper {
            position: relative;
            width: 100%;
            height: 100%;

            .modal-overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.85);
                z-index: 1100;
            }

            .modal-content-wrapper {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                overflow-x: hidden;
                overflow-y: auto;
                z-index: 1101;

                .modal-content {
                    width: 100%;
                    min-height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
            }

            .modal-header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 15px;
                margin-top: 50px;
                margin-bottom: 50px;

                .header-title {
                    color: #fce389;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 36px;
                    text-align: center;
                }
            }

            .modal-body {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 50px;
                width: 100%;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    .modal-subsc-plans.visible {
        display: block;
        animation: makeVisible 0.5s ease;
        animation-delay: 0;
        animation-direction: normal;
        animation-fill-mode: forwards;
    }

    .plan-list {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;

        .empty-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-wrap: wrap;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.1);
                z-index: 1101;
            }

            .empty-msg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #000;
                font-size: 18px;
                line-height: 24px;
                z-index: 1102;
            }
        }

        .subscription-plan-card:not(.as-title):not(.skeleton) {
            .card-subsc-plan {
                background-color: #fff;
                transition: all 0.3s ease;
            }

            &:hover {
                .card-subsc-plan {
                    padding: 30px 0;
                    transform: translate(0, -30px);
                    box-shadow: 0px 4px 15px 0px #00000026;
                }
            }
        }
    }
}

// RESPONSIVE:

@media screen and (max-width: 575.98px) {
    .subscription-plans-modal {
        .modal-subsc-plans {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1001;
            display: none;

            .modal-wrapper {
                .modal-body {
                    width: 100%;
                    padding: 0 16px;
                }
            }
        }

        .plan-list {
            .subscription-plan-card {
                width: 100%;

                .card-subsc-plan,
                .card-subsc-plan .card-wrapper {
                    width: 100%;
                }

                .card-subsc-plan .card-wrapper {
                    border-radius: 10px;
                }
            }
        }
    }
}
