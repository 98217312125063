@use "src/assets/styles/utilities/breakpoint" as b;

.course-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff1d86;
    border-radius: 10px;
    color: #fff;
    border: 2px solid #ff1d86;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    padding: 10px 20px;
    min-width: 150px;
    transition: all 0.3s ease;

    &:hover {
        opacity: 0.7;
    }

    span {
        margin-right: 5px;
    }

    &.outline-btn {
        background-color: transparent;
        color: #ff1d86;

        &:hover {
            background-color: #ff1d86;
            color: #fff;
            opacity: 1;
        }
    }
    @include b.tablet-down {
        min-width: unset;
        width: 35px;
        height: 35px;
        padding: 0;
        border-radius: 8px;

        .button-title {
            display: none;
        }

        span {
            margin-right: 0;
        }
    }
    @include b.small-mobile {
        width: 30px;
        height: 30px;
    }
}
