@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as c;
$text_color: #000;
$form_bg_color: #fff;

.ant-modal.app-modal.type-basic.modal-detail-exam .ant-form-horizontal .ant-form-item-label > label {
    color: $text_color;
}

.ant-modal.app-modal.type-basic.modal-detail-exam .ant-modal-body {
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
}

.ant-modal.app-modal.type-basic.modal-detail-exam {
    /* New Modal card*/
    .modal-detail-exam_list {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .exam-card {
            margin: 10px;
            width: auto;
            display: flex;
            align-items: center;
            background-color: rgba(c.$second, 0.1);
            color: c.$text_black;
            border-radius: 20px;
            padding: 20px;
            position: relative;
            cursor: pointer;

            &.active,
            &:hover {
                background-color: rgba(c.$primary, 0.1);
                border: 1px solid c.$primary;
                color: c.$text_black;
                .exam-card_title {
                    color: c.$primary;
                }
            }

            &_image {
                position: relative;
                width: 60%;
                height: 0;
                padding-bottom: 37.5%; /* Set the height as ~62.5% = 60% * 62.5% of the width */
                border-radius: 20px;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            &_title {
                flex: 1;
                text-align: center;
                font-weight: 700;
                color: c.$second;
            }

            .triangle-left {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -50px;
                width: 0;
                height: 0;
                border-top: 25px solid transparent;
                border-right: 36px solid c.$primary;
                border-bottom: 25px solid transparent;
                display: none;
                // transition: all 1s linear;
            }

            &.active .triangle-left {
                display: block;
            }
        }
    }
    /* End New Modal*/

    /* Modal skeleton */
    .modal-detail-exam__skeleton {
        display: flex;

        @media screen and (max-width: 576px) {
            flex-direction: column;
        }
    }

    .modal-detail-exam__right-skeleton {
        padding: 10px;
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: #cfcece;
            border-radius: 10px;
        }
    }

    .modal-detail-exam__left {
        display: flex;
        flex-direction: column;
        padding: 20px 30px;

        padding-left: 15px;
        // width: 60%;
        flex: 50%;

        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: #cfcece;
            border-radius: 10px;
        }
    }

    .modal-detail-exam__right {
        // width: 40%;
        // position: relative;
        flex: 44%;
    }

    // Group
    .modal-detail-exam__left-header {
        font-size: 26px;
        font-weight: 700;
        // margin-bottom:
    }
    .ant-modal-content {
        overflow: unset;
    }
}

//form
.ant-modal.app-modal.type-basic.modal-detail-exam {
    @media screen and (min-width: 1600px) {
        max-width: 1040px;
    }
    @media screen and (min-width: 1920px) {
        max-width: 1200px;
    }

    .anti_cheating-child-item {
        display: flex;
        margin: 2px 25px 2px 40px;
        label {
            font-style: italic;
            color: rgba(0, 0, 0, 0.85);
        }
        .ant-input-number {
            height: 30px;
            line-height: 30px;
            max-width: 30%;
            margin: auto;
        }
        .ant-input-number-input-wrap {
            line-height: 24px;
            input {
                font-style: italic;
                height: inherit;
            }
        }
    }

    .detail-exam-form {
        background-color: $form_bg_color;
        padding: 30px;
        max-height: 90vh;
        overflow-y: auto;
        .note {
            color: $text_color;
        }
        .security-collapse {
            background-color: transparent;
            .ant-collapse-content {
                background-color: transparent;
                color: $text_color;
            }

            .ant-collapse-header {
                color: $text_color;
            }
            .ant-checkbox-wrapper {
                color: $text_color;
            }
        }

        .ant-radio-wrapper-in-form-item {
            color: $text_color;
        }

        .ant-row.ant-form-item {
            flex-flow: column nowrap;
            align-items: flex-start;
            input::placeholder {
                color: $text_color !important;
            }
            input {
                color: $text_color;
            }
            margin-bottom: 10px;
        }
        .ant-select-selection-item {
            color: $text_color;
        }
        .ant-select-selection-placeholder {
            color: $text_color !important;
            opacity: unset;
        }
        .ant-col.ant-form-item-control {
            width: 100%;
        }
        .ant-form-item-control-input-content,
        .ant-input-number {
            width: 100%;
        }
        .ant-picker {
            width: 100%;
            height: 45px;
            border-radius: 5px !important;
            background-color: transparent;
            color: $text_color !important;
        }

        .ant-picker-suffix,
        .ant-select-arrow {
            //icon suffix right
            color: $text_color;
        }
        .ant-form-item-label > label {
            color: $text_color;
            font-weight: 700;
            font-size: 16px;

            &::before {
                color: $text_color !important;
            }
        }

        .ant-select-selector,
        .ant-input-status-error {
            border-radius: 5px !important;
            border-color: $text_color !important;
            background-color: transparent !important;
        }

        .ant-form-item-explain-error {
            // color: c.$yellow;
        }

        .ant-input-number.app-input,
        .ant-input.app-input,
        .ant-input-number,
        .ant-input {
            border-radius: 5px !important;
            border-color: $text_color !important;
            background-color: transparent;
            color: $text_color;
            &::placeholder {
                color: #ffffff;
                opacity: 1;
            }
        }

        .ant-input-number-handler-wrap {
            background-color: transparent;
            .ant-input-number:hover .ant-input-number-handler-wrap {
                opacity: 1;
                svg {
                    fill: c.$white;
                }
            }
        }
        .ant-select.app-select .ant-select-selector .ant-select-selection-placeholder {
            opacity: unset;
        }
    }

    .anti_cheating-checkbox {
        .hide-check .ant-checkbox-checked {
            visibility: hidden;

            .ant-checkbox-inner {
                border-radius: 20px;
                background-color: #332e2e;
                border: none;
                &:after {
                    display: none;
                }
            }
        }

        .hide-check {
            cursor: default;
        }

        .ant-checkbox-wrapper:hover.hide-check .ant-checkbox:after {
            visibility: hidden;
        }
    }

    .ant-modal-close {
        background-color: #ffffff;
        width: 35px;
        height: 35px;
        box-shadow: 0px 0px 4px rgba(86, 85, 85, 0.25);
        border-radius: 50%;
        position: absolute;
        top: calc(-35px + 20px);
        right: calc(-35px + 20px);
        transition: all 0.3s ease;

        &:hover {
            transform: rotate(180deg);
            opacity: unset;
        }

        .ant-modal-close-x {
            width: 100%;
            height: 100%;
            display: inline-block;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ant-modal-close-icon {
            > svg {
                fill: #000000;
            }
        }
    }
}
