.question-detail.highlight {
    $font_size: 18px;
    $title_font_weight: 700;

    .question-setting.set-answers {
        display: none;
        .title {
            font-size: $font_size;
            font-weight: $title_font_weight;
            margin-bottom: 5px;
        }
    }

    .question-editor {
        .question-editor-hint-text {
            font-size: 14px;
            font-weight: normal;
            font-style: italic;

            .hint-text.highlighted {
                color: #0077ff;
                font-weight: bold;
            }
        }
    }
}
