.StaticMath {
    display: inline-block;
    .mq-editable-field {
        border-radius: 4px;
        background: #fff;
    }

    .correct_latex {
        background-color: rgba(0, 255, 41, 0.1);
    }

    .incorrect_latex {
        background-color: #ffe6e6;
    }

    .disabled {
        pointer-events: none;
    }
}

.mq-math-mode .mq-paren {
    line-height: calc(100% - 0.2em);
    transform: scale(1.2, 3.03248);
}
