@use 'src/assets/styles/utilities/colors' as c;

/*
 * Global class names:
 * _
 */

.q-sort {
    .q-content-section {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .sort_container {
        // margin: 54px 47px;
        margin-left: 5px;
        margin-bottom: 10px;
        width: 100%;

        @media screen and (max-width: 992px) {
            width: 100%;
        }

        @media screen and (max-width: 576px) {
            .sort_title {
                margin: 10px 20px;
            }
        }
    }

    .dragDrop_list {
        list-style: none;
        position: relative;
    }

    .dragDrop_item {
        box-shadow: 0px 0px 1px 1px #ddd;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        position: relative;
        margin: 0 !important;

        &.dragging {
            background: c.$primary;
            list-style: none;
            color: #fff;
        }

        &.disabled {
            background-color: rgba(132, 132, 132, 0.1);
            cursor: not-allowed !important;

            img {
                cursor: not-allowed !important;
            }
        }

        img {
            padding: 28px 28px 28px 17px;
            display: block;
            cursor: -moz-grab;
            cursor: grab;
        }

        .pinyin-text {
            font-size: 14px !important;
            display: block;
            line-height: 1.4;
            * {
                font-size: 14px !important;
                display: inline-block;
                line-height: 1.4;
            }
        }
    }
}
