.assignment-report-wrapper .report-student {
    .report-student-chart {
        position: relative;
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 20px;
        padding: 50px 20px;
        height: 100%;

        .ant-spin-nested-loading {
            height: 100%;
        }

        .chart-null {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
        }
    }

    .report-student-detail {
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 20px;
        height: 100%;

        .ant-spin-nested-loading,
        .ant-spin-container {
            height: 100%;
        }

        .ant-spin-nested-loading > div > .ant-spin {
            max-height: unset;
        }

        .detail-data {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            height: 100%;
        }

        h1 {
            font-size: 65px;
            line-height: 65px;
            font-weight: 400;
            color: #0077ff;
            margin-bottom: 30px;
            text-align: center;

            > span {
                display: inline-block;
            }

            > span:nth-child(1) {
                font-weight: 700;
            }
        }

        h3 {
            opacity: 0.6;
            margin-bottom: 0;
            line-height: 32px;
        }

        .total_score {
            // margin-top: 20px;
            text-transform: uppercase;
            font-size: 16px;
            opacity: 0.6;
        }

        p {
            font-size: 18px;
            opacity: 0.5;
            margin-bottom: 0;
        }

        h4 {
            font-size: 18px;
        }
    }

    .detail-student-answer {
        margin-top: 40px;
    }

    .filter-menu-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        .filter {
            > span {
                font-weight: 400;
                font-size: 18px;
                line-height: 35px;
                margin-right: 10px;
            }

            .app-select {
                .ant-select-selector {
                    min-width: 200px;
                    border-color: #0077ff;
                    color: #0077ff;

                    .ant-select-selection-item {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 35px;
                    }
                }

                .ant-select-arrow {
                    color: #0077ff;
                    font-size: 14px;
                }
            }
        }
    }

    .answer-record-list-wrapper {
        > div:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 1199.98px) {
    .assignment-report-wrapper .report-student {
        .filter-menu-wrapper {
            align-items: flex-end;

            .filter {
                > span {
                    display: block;
                    margin-right: 0;
                }
            }

            .question-number-list {
                height: 42.5px;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .assignment-report-wrapper .report-student {
        .filter-menu-wrapper {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 15px;

            .question-number-list {
                height: auto;
                text-align: center;

                > li {
                    margin-bottom: 8px;
                }
            }
        }
    }
}
