.role-picker {
    padding: 30px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    background-color: #fff;

    .role-info {
        margin-bottom: 32px;

        .info-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            font-size: 26px;
            line-height: 32px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    .role-selections {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
    }
}
