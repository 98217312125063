@use 'src/assets/styles/utilities/colors' as c;

.exam-asset-wrapper {
    min-height: calc(100vh - 108px);
    padding: 30px 0;
}

.exam-asset {
    min-height: calc(100vh - 108px - 60px - 114px);
    padding: 30px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    background-color: #fff;
    max-width: 992px;
    margin: 0 auto;
    overflow: auto;

    .exam-asset-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        margin-bottom: 32px;

        .title-icon {
            .anticon {
                display: block;
                font-size: 36px;
            }
        }

        .title-content {
            .content-main {
                font-size: 32px;
                line-height: 36px;
                font-weight: bold;
            }

            .content-descr {
                margin-top: 8px;
            }
        }
    }

    .exam-asset-content {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-direction: column;
    }

    .exam-asset-save {
        margin-bottom: 0;
    }

    .exam-asset-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
    }

    .exam-asset-saveitems {
        background-color: #f3f9f7;
        padding: 8px 30px;
        min-height: 40px;

        .saveitem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;

            .saveitem-content {
                word-break: break-word;
            }

            .saveitem-actions {
                .btn {
                    min-width: unset;
                }

                .btn.btn-icon-only .btn__content {
                    background-color: c.$primary;
                    color: #fff;
                }
            }
        }
    }

    .exam-asset-upload-progress {
        margin-bottom: 16px;

        .progress-bar {
            position: relative;
            display: block;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.06);
            overflow: hidden;

            .progress-fulfilled {
                --width: 0;
                position: relative;
                width: var(--width);
                height: 8px;
                transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

                &.pending {
                    background-color: c.$info;
                }

                &.success {
                    background-color: c.$success;
                }

                &.danger {
                    background-color: c.$danger;
                }
            }
        }
    }

    .exam-asset-redirecting {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-style: italic;
        margin-bottom: 16px;

        .ant-spin-dot-spin {
            display: block;
        }
    }
}

.exam-congrat {
    max-width: 992px;
    margin: 0 auto 30px auto;

    .ant-alert {
        border-radius: 10px;
    }

    .ant-alert-with-description .ant-alert-message {
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
        color: #1890ff;
    }

    .ant-alert-with-description .ant-alert-description {
        font-size: 14px;
        line-height: 22px;
    }
}
