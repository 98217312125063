.exam-proctoring {
    position: relative;

    .page-content {
        &.freezed {
            display: none;
        }
    }
}

// @media screen and (max-width: 767.98px) {
// }

// @media screen and (max-width: 575.98px) {
// }
