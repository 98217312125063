.app-basic-layout {
    position: relative;
    background-color: #f8f8f8;
    min-height: 100vh;
    overflow-y: hidden;
    z-index: 0;

    .layout-header {
        min-height: 88px;
    }

    .layout-content {
        min-height: calc(100vh - 88px);
        padding: 0 40px 0 40px;
    }

    .basic-header-wrapper {
        padding: 10px 40px;
        background-color: #fff;
        box-shadow: 0 2px 15px 0px rgba(0, 0, 0, 0.2);
    }
}

@media screen and (max-width: 767.98px) {
    .app-basic-layout {
        .layout-content {
            padding: 0 30px 0 30px;
        }

        .basic-header-wrapper {
            padding: 20px 30px;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .app-basic-layout {
        .layout-content {
            padding: 0 16px 0 16px;
        }

        .basic-header-wrapper {
            padding: 20px 16px;
        }
    }
}
