@use 'src/assets/styles/utilities/colors' as c;

.webcam-check {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;

    .result-display {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
        padding: 32px 0;

        .webcam-check-displayer {
            display: block;
            width: 100%;
            height: 100%;
            max-width: 350px;
            max-height: 196.875px;
            border-radius: 10px;
            background-color: #333;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
            transform: rotateY(180deg);
        }
    }

    .check-text {
        text-align: center;

        .check-title {
            font-size: 26px;
            line-height: 32px;
        }

        .check-result-info {
            margin-top: 8px;
        }

        .check-result-message {
            .result-tag {
                display: inline-block;
                background-color: #aaa;
                color: #fff;
                padding: 1px 8px;
                border-radius: 4px;

                &.danger {
                    background-color: c.$danger;
                }

                &.success {
                    background-color: c.$success;
                }
            }
        }

        .check-result-desctiption {
            max-width: 350px;
            font-style: italic;
            margin-top: 4px;

            &.danger {
                color: c.$danger;
            }
        }
    }
}
