@use 'src/assets/styles/utilities/colors' as colors;

.question-formitems.top-bar-setting {
    margin-bottom: 35px;

    > div:first-child {
        padding-right: calc(35px / 2);
    }

    > div:last-child {
        padding-left: calc(35px / 2);
    }

    .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-label {
            line-height: 45px;
            height: auto;

            & > label {
                display: flex;
            }
        }
    }
}

.app-select.ant-select.set-tags {
    display: block;
    position: relative;
    min-height: 45px;

    &:not(.ant-select-customize-input) .ant-select-selector {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 45px;
        overflow: hidden;
        transition: all 0.3s ease;
        z-index: 100;
        height: 100%;
    }

    &:not(.ant-select-disabled):hover .ant-select-selector {
        box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
        height: auto;
        min-height: 36px;
        max-height: 350px;
        overflow-y: hidden;
    }

    .ant-select-clear {
        z-index: 101;
    }

    .ant-select-selector {
        .tag-container {
            .tag-btn-del {
                display: none;
            }
        }
    }
}

.set-tags-dropdown.ant-select-dropdown {
    overflow: visible;

    .ant-select-item {
        padding: 0;
    }

    .ant-select-item-option-state {
        display: none;
    }

    .tag-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 12px;
        gap: 8px;

        .tag-name {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .tag-btn-del {
            .btn__content {
                background-color: #ddd;
            }

            .btn__icon-wrapper > svg {
                fill: #000;
                width: 24px;
            }

            &:hover {
                .btn__content {
                    background-color: colors.$danger;
                }

                .btn__icon-wrapper > svg {
                    fill: #fff;
                }
            }
        }
    }
}

.tag-collection-dropdown.from-top-bar-setting {
    .tag-color {
        position: relative;

        .app-color-picker {
            .color-picker-wrapper {
                position: absolute;
                top: calc(100% + 8px);
                right: 0;
                bottom: auto;
                left: auto;
                padding-bottom: 30px;
            }

            .color-picker-overlay {
                display: none;
            }
        }
    }

    .dropdown-bottom-title {
        font-size: 18px;
        font-weight: 500;
        padding: 0 8px 4px;
    }

    .dropdown-bottom-action-list {
        text-align: right;
        padding: 2px 8px;
    }

    .btn-add-tag {
        min-width: unset;
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .app-select.ant-select.set-tags {
        min-height: 36px;

        &:not(.ant-select-customize-input) .ant-select-selector {
            max-height: 36px;
        }

        &.ant-select-disabled:hover .ant-select-selector {
            height: auto;
            max-height: unset;
        }
    }
}

@media screen and (max-width: 991.98px) {
    .question-formitems.top-bar-setting {
        > div:first-child {
            padding-right: 24px;
        }

        > div:last-child {
            padding-left: 24px;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .question-formitems.top-bar-setting {
        .ant-form-item {
            justify-content: space-between;

            .ant-form-item-control {
                max-width: 300px;
            }

            .ant-form-item-label {
                margin-right: 0;
                min-width: 120px;
                text-align: left;
            }
        }

        > div:first-child {
            padding-right: 0;
            margin-bottom: 10px;
        }

        > div:last-child {
            padding-left: 0;
        }

        .ant-select-selector {
            min-width: 230px;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .question-formitems.top-bar-setting {
        .ant-form-item {
            .ant-form-item-control {
                max-width: none;
            }

            .ant-form-item-label {
                line-height: initial;
            }
        }
    }
}
