.question-detail.fill-blanks-input {
    .question-setting.set-input-numbers {
        .ant-form-item .ant-form-item-label > label {
            line-height: 45px;
            height: auto;
            margin-right: 20px;
        }
    }

    .question-editor {
        .question-editor-hint-text {
            font-size: 14px;
            font-weight: normal;
            font-style: italic;

            .hint-text.highlighted {
                color: #0077ff;
                font-weight: bold;
            }
        }
    }

    .question-setting.set-answers {
        margin-bottom: 20px;

        > .question-answer-title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 35px;
        }

        > .question-answer-list {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            row-gap: 10px;
            column-gap: 50px;

            .answer-input {
                min-width: 300px;

                .ant-input {
                    border-radius: 6px;
                }
            }
        }

        .ant-form-item {
            margin-bottom: 0;
        }
    }

    .fill-blank-answers__actions {
        margin: 10px 0;
        .fill-blank-answers__add {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            font-size: 16px;
            line-height: 24px;

            &:hover {
                text-decoration: underline;
            }

            & > span {
                margin-right: 5px;
            }

            button {
                font-size: 25px;
                padding-bottom: 10px;
                background-color: #000;
                color: #fff;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                outline: none;
                border: none;
                cursor: pointer;

                @media (hover: hover) {
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}
