.dragdropgroup-answers {
    display: inline-block;
    border-radius: 10px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;

    .sort-answers-flex {
        display: flex;
        align-items: center;
    }

    .sort-answers__add {
        display: flex;
        align-items: center;

        button {
            background-color: #000;
            color: #fff;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            outline: none;
            border: none;
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    .ant-form-item-label {
        margin-right: 0;
        width: 30px;
    }
}

.dragdrop-listAnswers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .sort_item {
        width: 100%;
        display: flex;
        margin-bottom: 10px;
        align-items: center;

        // .ant-form-item-label {
        //     label {
        //         height: 100% !important;
        //     }
        // }
    }
}
