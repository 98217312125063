.custom-horizontal-navmenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 0px rgb(0 0 0 / 10%);
    background-color: #ffffff;

    .dropdown-btn-show-more {
        height: auto;
        padding: 5px 10px;
        border-radius: 10px;
        margin-left: 16px;
        margin-right: 16px;

        & > span {
            display: block;
        }

        & > span > svg {
            display: block;
            font-size: 20px;
        }
    }
    span[class="anticon anticon-down"] {
        color: #aac0ca;
    }
}

.app-nav-menu.ant-menu-vertical.horizontal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-right: none;
    padding: 10px;
    border-radius: 10px;
    row-gap: 16px;
    max-height: 60px;
    overflow: hidden;
    background: none;

    .nav-menu-item {
        position: relative;
        padding-right: 0;

        .nav-icon {
            width: 25px;
            height: 25px;

            & > svg {
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        .nav-name {
            color: #aac0ca;
        }

        &::after {
            content: "";
            position: absolute;
            top: calc(100% - 2px);
            left: 0;
            width: 0%;
            height: 2px;
            background-color: #aac0ca;
            transition: width 0.3s ease, background-color 0.3s ease;
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }
    }

    & > li.ant-menu-item {
        padding: 0;
        margin: 0;
        padding-left: 16px;
        padding-right: 16px;
    }

    & > li.ant-menu-item-selected {
        background: none;

        .nav-menu-item {
            .nav-name {
                color: #6c94fb;
            }

            &::after {
                width: 100%;
                background-color: #6c94fb;
            }
        }
    }
}
