.qc_speech-to-text_conversation {
    .viewScoreStudent_writingTitle {
        font-weight: bold;
    }
    .user_answer_sentence {
    }

    .sentence-label {
        font-size: 20px;
        font-weight: 700;
        margin-right: 20px;
    }
}
