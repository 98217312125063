.resultpanel__multiplechoicetable__table {
    margin: auto;
    width: 600px;
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;

    td,
    th {
        text-align: left;
        padding: 8px;
    }

    tr {
        background-color: #fff;
    }

    .multiplechoicetable_item {
        max-width: 400px;
        overflow-x: auto;
        overflow-y: hidden;
        margin: 0 5px;
    }

    .text-center {
        text-align: center;
    }

    .ant-input {
        min-width: 150px;
    }

    .multiplechoicetable__table__skeleton {
        width: 100%;
    }

    tbody {
        // &::before {
        //     content: "";
        //     display: block;
        //     width: 100%;
        //     position: absolute;
        //     top: 38px;
        //     height: 4px;
        //     z-index: 6;
        //     background: #fff;
        // }
    }

    .multiplechoicetable__table-skin {
        position: relative;
        overflow: auto;
        white-space: nowrap;
        max-height: 228px;

        &::-webkit-scrollbar-track {
            background: #fff;
        }
    }

    .sticky-row {
        position: -webkit-sticky;
        top: 0;
        z-index: 5;
        position: sticky;
        background-color: white;

        > td {
            text-align: center;
        }

        // &::before {
        //     // box-shadow: 0px 0px 0.5px 1.5px rgba(0, 0, 0, 0.35);
        //     content: "";
        //     display: block;
        //     width: 100%;
        //     position: absolute;
        //     top: 40px;
        //     // height: 3px;
        //     // background: red;
        //     z-index: 2;
        // }
    }

    .sticky-bottom-content {
        position: relative;
    }

    .sticky-col {
        position: -webkit-sticky;
        position: sticky;
        background-color: white;
    }

    .first-col {
        padding: 0;
        z-index: 1;
        // box-shadow: 5px 10px 18px #888888;
        background-color: #fff;
        width: 35%;
        // min-width: 100px;
        // max-width: 100px;
        left: 0px;
        height: 0;
        border: none;

        div {
            height: 100%;
            padding: 8px;
            white-space: pre-wrap;
            min-width: 200px;
        }
    }
}
