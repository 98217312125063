.student-courses {
    .loadmore-btn {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
        padding-right: 0;
        width: 100%;
    }
    .ant-steps-item {
        // margin-bottom: 14px;
        padding-bottom: 14px;
    }
    .ant-steps-icon {
        svg {
            width: 32px;
            height: 32px;
        }
    }

    .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail:after {
        width: 3px;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail:after,
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail:after {
        background-color: #d9d9d9;
    }
    .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
        width: 3px;
        top: 40px;
        padding: 2px 0;
        height: 86%;
    }
    .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
        top: 24px;
    }
}
