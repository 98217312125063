.scanningEffectContainer {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: not-allowed;
}

.scanningEffect {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
}
.ocrloaderText {
    color: yellow;
    position: absolute;
    bottom: -12px;
    left: 38%;
    font-size: 16px;
    font-weight: 600;
    animation: blinker 1.5s linear infinite;
    font-family: sans-serif;
    text-transform: uppercase;
}

.ocrloaderText::before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: yellow;
    position: relative;
    right: 4px;
}

.ocrloader {
    width: 94%;
    height: 94%;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 3%;
    backface-visibility: hidden;
}

.ocrloaderBar {
    position: absolute;
    left: 35px;
    top: 2px;
    width: 85%;
    height: 5px;
    background-color: #c9ebd3;
    box-shadow: 0 0 10px 1px #c9ebd3, 0 0 1px 2px #c9ebd3;
    z-index: 1;
    transform: translateY(95px);
    animation: move 1.7s cubic-bezier(0.15, 0.54, 0.76, 0.74);
    animation-iteration-count: infinite;
}

.ocrloader:before,
.ocrloader:after,
.ocrloaderBorder:after,
.ocrloaderBorder:before {
    border-color: #c9ebd3;
    content: "";
    position: absolute;
    width: 45px;
    height: 46px;
    border-style: solid;
    border-width: 0px;
}
.ocrloader:before {
    left: 0;
    top: 0;
    border-left-width: 5px;
    border-top-width: 5px;
    border-radius: 5px 0 0 0;
}
.ocrloader:after {
    right: 0;
    top: 0;
    border-right-width: 5px;
    border-top-width: 5px;
    border-radius: 0 5px 0 0;
}
.ocrloaderBorder:before {
    left: 0;
    bottom: 0;
    border-left-width: 5px;
    border-bottom-width: 5px;
    border-radius: 0 0 0 5px;
}
.ocrloaderBorder:after {
    right: 0;
    bottom: 0;
    border-right-width: 5px;
    border-bottom-width: 5px;
    border-radius: 0 0 5px 0;
}

@keyframes move {
    0%,
    100% {
        transform: translateY(290px);
    }
    50% {
        transform: translateY(0%);
    }
    75% {
        transform: translateY(260px);
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes x {
    to {
        transform: translateX(-100%);
        left: 100%;
    }
}

@keyframes y {
    33% {
        clip-path: inset(0 0 0 -100px);
    }
    50% {
        clip-path: inset(0 0 0 0);
    }
    83% {
        clip-path: inset(0 -100px 0 0);
    }
}
