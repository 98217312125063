.teacher-courses {
    font-size: 18px;
    line-height: 35px;

    .course-info-panel {
        height: 100%;
    }

    .list-msg {
        background-color: #eee;
        border-radius: 10px;
        padding: 8px;
        color: #888;
    }
}

.ant-spin-nested-loading > div > .ant-spin.teacher-courses-spinner {
    max-height: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
