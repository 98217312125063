.modal-mark-assignment-table {
    .ant-modal-content {
        border-radius: 10px;
        overflow: hidden;
    }

    .ant-table {
        .ant-table-tbody {
            .ant-table-cell {
                text-align: center;
            }
        }
    }

    .table-action {
        .table-action__icon {
            span {
                margin-left: 10px;

                svg {
                    font-size: 25px;
                    color: #1890ff;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

.modal-mark-assignment {
    .ant-modal-content {
        border-radius: 20px;
        overflow: hidden;
    }
}

.mark-assignment-table {
    height: 100%;

    & > div,
    & > div > div,
    .ant-table,
    .ant-table-content {
        height: 100%;
    }

    .ant-table .ant-table-container {
        height: 100%;
        border-radius: 0;
    }
}
