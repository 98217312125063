@use 'src/assets/styles/utilities/colors' as colors;

.exam-formitems.top-bar-setting {
    margin-bottom: 35px;

    > div:first-child {
        padding-right: calc(35px / 2);
    }

    > div:last-child {
        padding-left: calc(35px / 2);
    }

    .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-label {
            line-height: 45px;
            height: auto;

            & > label {
                display: flex;
            }
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .app-select.ant-select.set-tags {
        min-height: 36px;

        &:not(.ant-select-customize-input) .ant-select-selector {
            max-height: 36px;
        }

        &.ant-select-disabled:hover .ant-select-selector {
            height: auto;
            max-height: unset;
        }
    }
}

@media screen and (max-width: 991.98px) {
    .exam-formitems.top-bar-setting {
        > div:first-child {
            padding-right: 24px;
        }

        > div:last-child {
            padding-left: 24px;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .exam-formitems.top-bar-setting {
        .ant-form-item {
            justify-content: space-between;

            .ant-form-item-control {
                max-width: 300px;
            }

            .ant-form-item-label {
                margin-right: 0;
                min-width: 120px;
                text-align: left;
            }
        }

        > div:first-child {
            padding-right: 0;
            margin-bottom: 10px;
        }

        > div:last-child {
            padding-left: 0;
        }

        .ant-select-selector {
            min-width: 230px;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .exam-formitems.top-bar-setting {
        .ant-form-item {
            .ant-form-item-control {
                max-width: none;
            }

            .ant-form-item-label {
                line-height: initial;
            }
        }
    }
}
