@use 'src/assets/styles/utilities/colors' as colors;

.fm-moveto {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin: 8px 0 0 0;
    height: 250px;
    overflow: hidden;

    .fm-moveto-container {
        padding: 10px 20px 10px 11px;
        height: 100%;
        overflow: auto;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        ul {
            margin-left: 10px;
        }
    }

    .fm-moveto-item {
        border-radius: 5px;

        .fm-moveto-item-label {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 2px;
            user-select: none;
        }

        .fm-moveto-item-collapse-target {
            cursor: pointer;
            flex: 0 0 14px;
            text-align: center;
            line-height: 18px;
            margin-right: 5px;
        }

        .fm-moveto-item-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            padding: 2px 8px 3px 8px;
            border-radius: 3px;
            background-color: transparent;
            color: #3f3f3f;
            transition: all 300ms;
            .item-icon {
                display: block;
                height: 20px;
                & > svg {
                    display: block;
                    width: auto;
                    height: 100%;
                }
            }
            .item-name {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                width: 100%;
            }
            &:hover {
                background-color: #e3e3e3;
            }
        }

        &.active {
            > .fm-moveto-item-label > .fm-moveto-item-name {
                background-color: #58a3c9;
                color: #fff;
            }
        }

        &.disabled {
            opacity: 0.8;
            filter: grayscale(1);
            pointer-events: none;
        }

        &.skeleton-loading {
            height: 27px;
            margin-left: 19px;
            animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;
        }

        &.has-scope-line {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 22px;
                left: 6.5px;
                width: 1px;
                height: calc(100% - 22px);
                background-color: rgba($color: #000000, $alpha: 0.2);
            }
        }
    }

    .fm-moveto-item.input-c-fol {
        .fm-moveto-item-name {
            background-color: #f2f2f2;
            border: 1px solid #e3e3e3;
        }

        .fm-moveto-input {
            &,
            &.ant-input.app-input {
                line-height: 24px;
                padding: 0 8px;
                font-size: 14px;
                border: none;
                outline: none;
                box-shadow: none;
                background: none;
            }
        }

        &.focus-danger {
            .fm-moveto-item-name {
                border: 1px solid colors.$danger;
            }
        }
    }

    .input-c-fol-confirm {
        display: block;
        background-color: #eee;
        padding: 8px;
        border-radius: 10px;

        .cfm-title {
            display: block;
            font-size: 14px;
            font-style: italic;
            color: colors.$danger;
            margin: 0 0 8px 0;
        }

        .cfm-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .btn {
                min-width: unset;
            }
        }
    }
}

.fm-moveto-wrapper {
    margin-bottom: 24px;
}

.ant-modal.app-modal.type-basic .ant-form-horizontal.fm-form-moveto .ant-form-item-label {
    min-width: 120px;
}

.fm-moveto-actions {
    text-align: right;
}
