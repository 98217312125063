@use 'src/assets/styles/utilities/colors' as c;

.exam-checkin-steps {
    .checkin-steps {
        padding: 30px 0;

        // Step icon:
        .ant-steps-item-custom {
            .ant-steps-item-icon {
                width: 32px;
                height: 32px;
                background-color: #eee;
            }

            &.ant-steps-item-finish {
                .ant-steps-item-icon {
                    background-color: rgba($color: c.$primary, $alpha: 0.6);
                }
            }

            &.ant-steps-item-process {
                .ant-steps-item-icon {
                    background-color: c.$primary;
                }
            }
        }

        .ant-steps-item-custom {
            & > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                left: 0;

                .anticon {
                    display: block;
                    font-size: 20px;
                }
            }

            &.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon,
            &.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
                color: #fff;
            }
        }

        // Step title:
        .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
        .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
            color: c.$primary;
            font-weight: bold;
        }

        .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
            color: rgba(0, 0, 0, 0.25);
            font-weight: bold;
        }

        .ant-steps-item-title:after {
            height: 2px;
        }

        .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after,
        .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
            background-color: rgba(0, 0, 0, 0.25);
        }

        .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
            background-color: rgba($color: c.$primary, $alpha: 0.6);
        }
    }

    .checkin-content {
        min-height: calc(100vh - 108px - 92px);
        padding-bottom: 30px;

        .system-check {
            min-height: calc(100vh - 108px - 92px - 30px);
            padding: 30px;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            background-color: #fff;
            // max-width: 992px;
            margin: 0 auto;
            overflow: auto;
            animation: appearing 0.5s ease 0s 1 normal forwards;

            .system-check-form {
                .ant-form {
                    min-height: calc(100vh - 108px - 92px - 30px - 162px);
                }
            }

            .system-check-content {
                background-color: #f3f9f7;
                padding: 30px;
                border-radius: 10px;
                width: 100%;
                max-width: 1270px;
                margin: 0 auto;
            }

            .ant-form {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }

            .form-actions {
                margin-top: 30px;
                margin-bottom: 0;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .candidate-information {
            min-height: calc(100vh - 108px - 92px - 30px);
            padding: 30px;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            background-color: #fff;
            max-width: 800px;
            margin: 0 auto;
            overflow: auto;
            animation: appearing 0.5s ease 0s 1 normal forwards;

            .candidate-info-form {
                .ant-form {
                    min-height: calc(100vh - 108px - 92px - 30px - 130px);
                }
            }

            .ant-spin-nested-loading,
            .ant-spin-container,
            .ant-form {
                min-height: inherit;
            }

            .ant-form {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }

            .form-actions {
                margin-top: 16px;
                margin-bottom: 0;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .start-taking-exam {
            min-height: calc(100vh - 108px - 92px - 30px);
            padding: 30px;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            background-color: #fff;
            max-width: 800px;
            margin: 0 auto;
            overflow: auto;
            animation: appearing 0.5s ease 0s 1 normal forwards;

            .access-form {
                min-height: calc(100vh - 108px - 92px - 30px - 130px);
            }
        }
    }

    .ant-spin-nested-loading > div > .ant-spin {
        max-height: unset;
    }
}

@keyframes appearing {
    from {
        transform: translateY(32px);
    }

    to {
        transform: translateY(0);
    }
}
