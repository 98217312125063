@use 'src/assets/styles/utilities/colors' as c;

.record-button-wrapper {
    $size: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .record-button {
        border: none;
        padding: 0;
        border-radius: 100%;
        width: $size;
        height: $size;
        font-size: 20px;
        color: #fff;
        padding: 0;
        margin: 0;
        background: c.$second;
        outline: none;
        position: relative;
        // z-index: 5;
        display: inline-block;
        line-height: $size;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;

        .recording.pulse-ring {
            content: "";
            width: $size;
            height: $size;
            background: c.$second;
            border: 5px solid c.$second;
            border-radius: 50%;
            position: absolute;
            top: 0px;
            left: 0px;
            animation: pulsate infinite 1.3s;
        }

        .record-icon {
            width: $size;
            height: $size;
        }
    }
    .record-note {
        font-size: 14px;
        color: #333;
    }

    &.small {
        .record-icon,
        .record-button {
            width: 28px;
            height: 28px;
            line-height: 28px;
            svg {
                width: 16px;
                height: 16px;
            }
        }
        .recording.pulse-ring {
            width: 28px;
            height: 28px;
        }
        .record-note {
            font-size: 12px;
            display: none;
        }
    }

    @keyframes pulsate {
        0% {
            transform: scale(1, 1);
            opacity: 1;
        }
        100% {
            transform: scale(1.3, 1.3);
            opacity: 0;
        }
    }
}
