.video-player {
    // background-color: rgba(#1111, 0.1);
    width: 100%;
    height: 100%;
    border-radius: 5px;

    &_container {
        height: 100%;
        width: 100%;
    }

    &_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        // border: solid 1px #ccc;
        width: 100%;
        height: 100%;
    }
    &_area {
        width: 100%;
        height: calc(100% - 60px);
        background-color: rgba(#1111, 0.1);
        border-radius: 2px;

        video {
            background-color: #333;
        }
    }
}
