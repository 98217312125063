.breadcumb__control {
    .action-button {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 16px;
        height: 44px;
        border-radius: 8px;
        padding: 2px 20px;
        border: none;

        min-width: 150px;

        cursor: pointer;

        div {
            margin-left: 10px;
        }

        &:hover {
            opacity: 0.9;
        }

        &:active {
            transform: scale(0.97);
        }

        &.action-button--orange {
            background-color: #ffd00d;
        }

        &.action-button--primary {
            color: #0077ff;
            background-color: #ffffff;
            opacity: 0.8;
            font-weight: 700;
        }

        &.action-button--modify {
            color: #fff;
            border: 1px solid #fff;
            background-color: transparent;
        }

        &.disabled {
            &:active {
                transform: scale(1);
            }

            &:hover {
                opacity: 1;
            }

            cursor: not-allowed;
        }
    }
}
