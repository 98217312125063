@use 'src/assets/styles/utilities/colors' as c;

.start-taking-exam {
    .access-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        margin-bottom: 32px;

        .title-icon {
            .anticon {
                display: block;
                font-size: 36px;
            }
        }

        .title-content {
            .content-main {
                font-size: 32px;
                line-height: 36px;
                font-weight: bold;
            }

            .content-descr {
                margin-top: 8px;
            }
        }
    }

    .access-form {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .record-info-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        margin: 0 auto;
        background-color: #f3f9f7;
        padding: 30px;
        border-radius: 10px;
        animation: appearing 0.5s ease 0s 1 normal forwards;

        .record-info {
            text-align: center;
        }
    }

    .start-info-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
        margin: 0 auto;
        background-color: #f3f9f7;
        padding: 30px;
        border-radius: 10px;
        animation: appearing 0.5s ease 0s 1 normal forwards;

        .start-info {
            text-align: center;
        }

        .info-title {
            & > div:first-child {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 8px;
            }
        }

        .info-timer {
            margin-top: 8px;
            text-align: left;
            border-top: 1px solid #d9d9d9;

            & > div:nth-child(2) {
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                transform: scale(0.5);
                height: 56px;
            }
        }
    }
}

@keyframes appearing {
    from {
        transform: translateY(16px);
    }

    to {
        transform: translateY(0);
    }
}
