.editor.mq-editable-field {
    width: 100% !important;
    box-shadow: none !important;
    border: 1px solid rgb(92, 92, 92) !important;
}

.mq-editable-field {
    position: relative;
    &::before {
        content: "";
        display: block;
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
        z-index: -1;
    }

    &:not(.editor) {
        min-width: 80px;
        min-height: 35px;
        border-radius: 2px;
        padding: 5px 4px;
        border: 1px solid rgb(215, 215, 215);
        border-radius: 6px;
    }
}

.response-embed {
    width: 120px;
    height: 28px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
    line-height: 28px;
    border-radius: 4px;
    border: 1px solid #e2e2e2;

    &::before {
        content: "base-line";
        display: inline;
        visibility: hidden;
    }

    .response-embed__chart {
        width: 32px;
        background: rgb(167, 167, 167);
        font-weight: 600;
        color: rgb(255, 255, 255);
        border-top: 1px solid rgb(182, 182, 204);
        border-bottom: 1px solid rgb(182, 182, 204);
        border-left: 1px solid rgb(182, 182, 204);
        border-image: initial;
        border-right: none;
        height: 100%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .response-embed__text {
        width: calc(100% - 32px);
        color: rgb(68, 68, 68);
        background: rgb(255, 255, 255);
        font-size: 10px;
        font-weight: 600;
        border-top: 1px solid rgb(182, 182, 204);
        border-right: 1px solid rgb(182, 182, 204);
        border-bottom: 1px solid rgb(182, 182, 204);
        border-image: initial;
        text-transform: uppercase;
        border-left: none;
        height: 100%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.mq-root-block {
    overflow: visible !important;
}

.tox {
    .mq-math-mode * {
        line-height: 1 !important;
    }
}

.mq-math-mode,
.mq-math-mode .mq-editable-field {
    // cursor: text !important;
    font-family: Symbola, "Times New Roman", serif !important;
}

// #mathquill-editor:
#mathquill-editor-wrapper {
    padding: 30px;
    .tox-dialog-wrap__backdrop {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .tox-dialog {
        width: auto;
        max-width: 530px;
        border: none;
        border-radius: 10px;
        box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    }
    .tox-dialog__body-content {
        max-height: unset;
    }
    .tox-dialog__content-js {
        overflow: auto;
    }
}
#mathquill-editor {
    .editor {
        border: 2px solid #e2e2e2 !important;
        border-radius: 10px;
        background-color: #f5f5f5;
    }
    .button-area {
        margin-top: 10px;
    }
    .mq-root-block {
        min-height: 28px !important;
        padding: 20px !important;
        overflow: auto !important;
    }
}

// #mathquill-mathchars:
#mathquill-mathchars-wrapper {
    padding: 30px;
    .tox-dialog-wrap__backdrop {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .tox-dialog {
        width: auto;
        max-width: 530px;
        border: none;
        border-radius: 10px;
        box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    }
    .tox-dialog__body-content {
        max-height: unset;
    }
    .tox-dialog__content-js {
        overflow: auto;
    }
    &.relative-to-sth {
        padding: 0;
        .tox-dialog-wrap__backdrop {
            display: none;
        }
        .tox-dialog {
            margin: 0;
        }
        // .editor {
        //     display: none;
        // }
    }
}
#mathquill-mathchars {
    .editor {
        border: 2px solid #e2e2e2 !important;
        border-radius: 10px;
        background-color: #f5f5f5;
    }
    .button-area {
        margin-top: 10px;
    }
    .mq-root-block {
        min-height: 28px !important;
        padding: 20px !important;
    }
}
