@use "src/assets/styles/utilities/breakpoint" as *;

.student-courses {
    .course-list-filter {
        display: flex;
        gap: 10px;
    }
    .course-list-filter-title {
        margin-right: 5px;
    }

    .course-item-loadmore {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        min-height: 489px;
        height: 100%;

        .course-btn {
            background: none;
            border: none;
            color: #888;
            font-size: 18px;
            line-height: 35px;
            font-weight: 500;
            cursor: pointer;

            &:hover {
                color: #555;
                opacity: 1;
            }
        }
    }

    @include tablet-down {
        .student-courses-inner {
            padding: 20px;
            padding-top: 40px;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            margin-top: -45px;
            position: relative;
            z-index: 10;
            background-color: #f7f7f7;
        }
    }

    @include mobile-down {
        .list-header {
            flex-direction: column;
            align-items: flex-start;
            .course-list-actions {
                width: 100%;
            }
        }
        .course-list-filter {
            &-title {
                display: none;
            }
        }
    }
}
