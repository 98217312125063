.question-detail.math-matrix {
    .field-title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 35px;
    }

    .question-setanswers {
        margin-bottom: 0;
    }
}
