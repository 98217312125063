.q-result.q-graph {
    .view-graph {
        position: relative;
    }

    .graph-msg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        overflow: hidden;
    }

    .msg-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        .msg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            padding: 8px 16px;
            border-radius: 10px;
            background-color: #fff;
        }
    }
}
