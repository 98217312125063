@use 'src/assets/styles/utilities/variables' as vars;

/*
 * Global class names:
 * .view-mathtextdropdown
 */

.q-mathtextdropdown {
    .q-content-section {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}

.view-mathtextdropdown {
    position: relative;
    width: 100%;
    height: auto;
    background-color: #fbfbfb;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 8px;
    margin: 0px auto;
    user-select: none;
}

.view-unitcv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
}

.view-unitcv-blank {
    &.app-input-latex {
        max-width: 200px;
    }
}
