.latex-options {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: inline-block;
}

.bwf-button__fillblanks {
    width: 100%;
    background-color: transparent;
    border: 1px solid #ddd;
    border-radius: 6px;
    cursor: pointer;

    &:active {
        transform: scale(0.97);
    }
}

.mathchar-btns-w-fltr-8 {
    .bwf-section:first-child {
        display: flex;
        column-gap: 10px;
    }
}
