/*
 * Global class names:
 * .view-writing
 */

.q-writing {
    .q-content-section {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}

.view-writing {
    .input-editor,
    .textarea-editor {
        width: 100%;
    }

    .content-setting-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: -10px;
        padding: 14px 4px 4px 4px;
        border-radius: 0 0 10px 10px;
        background-color: #f5f5f5;
    }

    .word-counter {
        display: inline-block;
        padding: 2px 15px;
        background-color: #dfdfdf;
        border-radius: 10px;
        font-size: 16px;
        letter-spacing: 1px;
    }
}
