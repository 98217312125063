@use 'src/assets/styles/utilities/colors' as c;

.internet-connection-check {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;

    .result-display {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 200px;

        .progress-wrapper {
            position: relative;

            .progress-level {
                position: absolute;

                &.bottom-left {
                    top: calc(100% - 44px);
                    right: 100%;
                }

                &.bottom-right {
                    top: calc(100% - 44px);
                    left: 100%;
                }
            }
        }

        .progress-result {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 8px;

            & > span:nth-child(2) {
                font-size: 1em;
            }

            & > span:nth-child(2) {
                font-size: 0.6em;
            }
        }
    }

    .check-text {
        text-align: center;

        .check-title {
            font-size: 26px;
            line-height: 32px;
        }

        .check-result-info {
            margin-top: 8px;
        }

        .check-result-message {
            .result-tag {
                display: inline-block;
                background-color: #aaa;
                color: #fff;
                padding: 1px 8px;
                border-radius: 4px;

                &.danger {
                    background-color: c.$danger;
                }

                &.success {
                    background-color: c.$success;
                }
            }
        }

        .check-result-desctiption {
            max-width: 350px;
            font-style: italic;
            margin-top: 4px;

            &.danger {
                color: c.$danger;
            }
        }
    }
}
