.exam-bank {
    .table {
        .ant-table {
            .ant-table-tbody {
                .ant-table-cell {
                    text-align: center;
                }
            }
        }
    }

    .exam-bank-table {
        .ant-table table {
            width: 100% !important;
        }

        .ant-table .ant-table-expanded-row-fixed {
            width: auto !important;
        }

        .ant-table table colgroup col {
            &:nth-child(1) {
                width: auto !important;
                min-width: 150px;
            }
            &:nth-child(2) {
                width: auto !important;
                min-width: 150px;
            }
            &:nth-child(3) {
                width: auto !important;
                min-width: 150px;
            }
            &:nth-child(4) {
                width: auto !important;
                min-width: 150px;
            }
            &:nth-child(5) {
                width: auto !important;
                min-width: 100px;
            }
            &:nth-child(6) {
                width: auto !important;
                min-width: 100px;
            }
            &:nth-child(7) {
                width: auto !important;
                min-width: 170px;
            }
            &:nth-child(8) {
                width: auto !important;
                min-width: 170px;
            }
            &:nth-child(9) {
                width: auto !important;
                min-width: 100px;
            }
        }
    }
}

@media screen and (max-width: 1399.98px) {
    .exam-bank {
        .exam-bank-table {
            .ant-table table colgroup col {
                &:nth-child(1) {
                    min-width: 100px;
                }
                &:nth-child(2) {
                    min-width: 100px;
                }
                &:nth-child(3) {
                    min-width: 100px;
                }
                &:nth-child(4) {
                    min-width: 100px;
                }
                &:nth-child(5) {
                    min-width: 80px;
                }
                &:nth-child(6) {
                    min-width: 80px;
                }
                &:nth-child(7) {
                    min-width: 100px;
                }
                &:nth-child(8) {
                    min-width: 100px;
                }
                &:nth-child(9) {
                    min-width: 100px;
                }
            }
        }
    }
}
