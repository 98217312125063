.app-input-latex {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 150px;

    &::before {
        content: "\a0";
        display: inline-block;
        width: 0;
        visibility: hidden;
    }

    .input-latex {
        width: 100%;
        > div {
            background-color: #fff;
        }
    }
}
