@use "src/assets/styles/utilities/breakpoint" as b;

.sptt-conversation_user-answer {
    .label-col {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .label {
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .transcript {
        position: relative;
        p {
            margin: 0;
            font-size: 18px;
            // border: solid 1px #ddd;
            // border-radius: 10px;
            padding: 2px 6px;
            min-height: 34px;

            @include b.small-mobile {
                min-width: 180px;
                min-height: 28px;
            }
        }
    }
    .pinyin-text {
        font-size: 14px !important;
        min-height: unset !important;
    }

    .user-answer_wrapper {
        border: dashed 1px #ddd;
        border-radius: 10px;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &.active .user-answer_wrapper {
        border: none;
        box-shadow: 0px 1px 6px 1px #ddd;
    }

    .user-answer_inner {
        padding-left: 38px;
    }
    &.active .user-answer_inner {
        // padding: 5px;
        // border: solid 1px #6c94fb;
        // border-radius: 10px;
        // box-shadow: 0px 1px 6px 1px #ddd;
        display: flex;
        align-items: center;
        gap: 20px;
        position: relative;
        padding-left: 38px;
    }
    .correct_sentence {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .user-answer-mobile-up {
        display: flex;
        align-items: center;
        height: 100%;
        .diff-text {
            margin-right: 10px;
        }
    }

    .user-answer-mobile {
        display: none;
    }

    @include b.small-mobile {
        .label-col {
            justify-content: flex-start;
        }
        .user-answer-mobile {
            display: flex;
            align-items: center;
            margin-left: 5px;
            .diff-text {
                margin-right: 10px;
            }
        }
        .user-answer-mobile-up {
            display: none;
        }
    }
}
