@use 'src/assets/styles/utilities/colors' as c;

.system-check {
    .system-check-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        margin-bottom: 32px;

        .title-icon {
            .anticon {
                display: block;
                font-size: 36px;
            }
        }

        .title-content {
            .content-main {
                font-size: 32px;
                line-height: 36px;
                font-weight: bold;
            }

            .content-descr {
                margin-top: 8px;
            }
        }
    }

    .system-check-form {
        .system-check-content {
            .ant-row {
                justify-content: center;

                .ant-col {
                    &:not(:last-child) {
                        border-right: 1px solid #d9d9d9;
                    }
                }
            }
        }

        .system-check-item {
            height: 100%;
            border-radius: 10px;
            transition: transform 0.2s ease;

            &.focused {
                transform: scale(1.025);
                box-shadow: 0 0px 16px 0 rgba(0, 0, 0, 0.1);
            }
        }
    }
}
