@use "src/assets/styles/utilities/breakpoint" as *;

.test_content {
    .TestHeader {
        margin: 30px;
    }

    .test_question {
        margin: 30px;
    }

    #webcam-displayer-for-proctoring1 {
        position: fixed;
        top: 16px;
        right: calc(352px + 30px);
        display: block;
        width: 100%;
        height: 100%;
        max-width: 250px;
        max-height: 150px;
        border-radius: 10px;
        background-color: #333;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        transform: rotateY(180deg);
        margin: 0 auto;
    }

    .webcam-displayer-for-proctoring1 {
        position: fixed;
        top: 16px;
        right: calc(352px + 30px);
        display: block;
        width: 100%;
        height: 100%;
        max-width: 250px;
        max-height: 150px;
        border-radius: 10px;
        background-color: #333;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        transform: rotateY(180deg);
        margin: 0 auto;
    }
}

.NumberQuestion_Responsive {
    padding-top: 30px;
    box-shadow: 0 0px 20px #5f5f5f;
    display: none;
    background-color: #fff;

    .Submit_Test {
        display: flex;
        justify-content: center;

        button {
            margin: 20px 8px;
            display: block;
        }
    }

    .test_timeoutTest {
        animation: fadeIn 0.5s ease-in;
        padding: 10px 15px;
        color: red;
        text-align: center;
    }
}

@include tablet-down {
    .test_content {
        margin-bottom: 250px;
        .TestHeader {
            margin: 40px 10px 10px 10px;
            border-radius: 15px;

            .title_wrapper {
                width: 60%;
            }
        }

        .test_question {
            margin: 20px 15px 15px 15px;
        }
    }

    .NumberQuestion_Responsive {
        display: block;
        position: fixed;
        padding-top: 10px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        .NumberQuestion {
            .NumberQuestion_list {
                margin-top: 15px;
                &.NumberQuestion_list--slider {
                    padding: 0 300px;
                }
            }
        }
        .Submit_Test {
            .btn__content {
                font-size: 18px;
                font-weight: 600;
            }
            button {
                min-width: 123px;
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .NumberQuestion_Responsive {
        padding-top: 25px;
        .NumberQuestion {
            .NumberQuestion_list {
                &.NumberQuestion_list--slider {
                    padding: 0 200px;
                }
            }
        }
    }
}

@media screen and (max-width: 690px) {
    .NumberQuestion_Responsive {
        .NumberQuestion {
            .NumberQuestion_list {
                &.NumberQuestion_list--slider {
                    padding: 0 100px;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .NumberQuestion_Responsive {
        .NumberQuestion {
            .NumberQuestion_list {
                &.NumberQuestion_list--slider {
                    padding: 0 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .NumberQuestion_Responsive {
        .NumberQuestion {
            .NumberQuestion_list {
                &.NumberQuestion_list--slider {
                    padding: 0 40px;
                }
            }
        }
    }
}
