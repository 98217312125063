@use 'src/assets/styles/utilities/breakpoint.scss' as b;

.unit-list-table {
    .unit-list {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        row-gap: 30px;
    }

    .unit-list-msg {
        background-color: #eee;
        border-radius: 10px;
        padding: 8px;
        color: #888;
    }

    .loadmore-btn {
        text-align: center;
    }

    .dropdown-btn {
        height: auto;
        padding: 5px 10px;
        border-radius: 10px;

        & > span {
            display: block;

            & > svg {
                display: block;
                font-size: 14px;
            }
        }
    }

    .ant-spin-nested-loading > div > .ant-spin {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: unset;
    }

    @include b.tablet-down {
        .unit-list {
            row-gap: 15px;
        }
    }
}

.ant-dropdown-menu.unit-list-item-dropdown {
    .item-btn {
        width: 100%;
        padding: 5px 12px;

        > div:first-child {
            min-width: 25px;
        }
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        padding: 0;
    }
}
