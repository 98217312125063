@use 'src/assets/styles/utilities/colors' as c;

// ClassNames: app-radio.

// Radio:
.ant-radio-wrapper.app-radio {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    margin-right: 0;

    .ant-radio-inner {
        border: 1px solid #d9d9d9;
        width: 20px;
        height: 20px;

        &::after {
            background-color: c.$second;
            width: 12px;
            height: 12px;
            transform: scale(1) translate(-50%, -50%);
            margin-top: 0;
            margin-left: 0;
            top: 50%;
            left: 50%;
        }
    }

    .ant-radio-checked {
        .ant-radio-inner {
            border: 1px solid c.$second;
        }
    }
}
