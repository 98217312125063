// ClassNames: app-tag, app-tag-label.

// (Ant Design) Tag:
.ant-tag.app-tag {
    display: block;
    position: relative;
    margin-right: 0;
    border-radius: 5px;
    padding: 8px 24px 8px 8px;
    font-size: 14px;
    // white-space: normal;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    & > .ant-tag-close-icon {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translate(0, -50%);

        & > svg {
            font-size: 14px;
        }
    }
}

.ant-tag-hidden.app-tag {
    display: none;
}

.ant-tag.app-tag-label {
    position: relative;
    border-radius: 5px;
    margin-right: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;

    &:hover {
        white-space: pre-wrap;
    }
}
