// ClassNames: app-avatar.

// (Ant Design) Avatar:
.ant-avatar.app-avatar {
    &.layout-full {
        width: 100%;
        height: 100%;
    }

    &.ant-avatar-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        & > .anticon {
            font-size: 32px;
        }
    }
}
