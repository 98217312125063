.mobile-table-list {
    width: 100%;
    overflow: unset;

    .mobile-table-list-items {
        overflow: auto;
    }

    .mobile-table-list-item {
        background-color: #fff;
        // border: 1px solid black !important;
        border-radius: 10px !important;
        // box-shadow: 4px 2px 2px 0px #e7e5e5;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        padding: 12px 0;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 1px;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        &.ant-collapse-item-active {
            .ant-collapse-header {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }
        &.ant-collapse-item-disabled {
            .ant-collapse-header {
                cursor: initial;
                padding-right: 10px !important;
                color: rgba(0, 0, 0, 0.85);
                .ant-collapse-expand-icon {
                    display: none;
                }
            }
        }

        .tags-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 4px;
            overflow-x: auto;
            .ant-tag {
                min-width: fit-content;
                :hover {
                    white-space: nowrap;
                }
            }
        }

        .app-reactions {
            .reaction-btn {
                .btn__icon-wrapper svg {
                    height: 16px;
                }
                &.btn-icon-only .btn__content {
                    width: 26px;
                    height: 26px;
                }
            }

            .reaction-action {
                width: 26px;
                height: 26px;
            }
        }

        .table-content-format .text-limit-lines {
            line-height: 1.4 !important;
        }
    }

    .ant-collapse-content {
        background-color: transparent;
        border-top: 0;
        .ant-collapse-content-box {
            padding: 0 10px 0 10px;
        }
    }
    .ant-collapse-header {
        padding: 0 35px 0 10px !important;
        user-select: none;
        flex-direction: row-reverse;
        align-items: center !important;
        justify-content: space-between;

        .ant-collapse-header-text {
            width: 100%;
        }
    }
    .ant-collapse-arrow {
        user-select: none;
        font-size: 14px !important;
        padding-left: 10px;
        margin-right: 5px !important;
    }
    .ant-collapse {
        overflow: auto;
    }
    .mobile-table-row {
        display: flex;
        margin-bottom: 2px;

        b {
            white-space: nowrap;
        }
        .mobile-table-space {
            display: inline-block;
            margin: 0 5px 0 1px;
        }
        .list-actions {
            display: inline-flex;
            gap: 6px;

            .btn {
                min-height: unset;

                &.btn-icon-only .btn__content {
                    width: 20px;
                    height: 20px;
                    padding: 2px;

                    svg {
                        height: auto;
                    }
                }

                &.list-btn {
                    .btn__content {
                        width: 24px;
                        height: 24px;
                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }
    .empty-datasource-item {
        .ant-collapse-header {
            cursor: initial !important;
            color: rgba(0, 0, 0, 0.85) !important;
        }
    }
}
.mobile-table-pagination {
    margin-top: 10px;
    // margin-bottom: 0 !important;
    .ant-pagination-item,
    .ant-pagination-next button,
    .ant-pagination-prev button {
        border-radius: 50%;
    }
}
