/*
 * Global class names:
 * _
 */

.q-multiple-choice-sentence-quiz {
    .q-content-section {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .view-multiple-choice {
        // padding: 0 30px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        column-gap: 20px;
        max-height: 300px;
        overflow-y: auto;
    }

    .answer-for-multiple-choice {
        width: calc(50% - 20px);
        min-width: 200px;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}
