.question-detail.detail-passage {
    .passage-header {
        margin-bottom: 35px;

        .ant-form-item {
            .ant-form-item-label > label {
                min-width: 100px;
            }
        }
    }
}

.question-detail-preview {
    .passage-child-preview {
        position: relative;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 20px;
        padding: 30px;
        font-size: 18px;
        letter-spacing: -0.03em;
        margin-bottom: 30px;

        .passage-child-heading {
            margin-bottom: 30px;
            font-size: 14px;
            font-style: italic;
            font-weight: 500;
        }

        .passage-child-title {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 18px;
            line-height: 35px;
        }
    }
}
