.ant-modal.modal-add-unit {
    $modal-width: 940px;

    // Form layout:
    & > div.ant-modal-content {
        width: $modal-width;
        max-width: 100%;
        position: relative;
        background-color: #ffffff;
        box-sizing: border-box;
        border-radius: 15px;
    }

    .ant-modal-header {
        border-radius: 15px 15px 0 0;
        border: none;
        padding: 30px;

        .ant-modal-title {
            font-weight: 700;
            font-size: 26px;
            line-height: 35px;
            text-align: left;
            color: #000000;
        }
    }

    .ant-modal-close {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: #fff;
        filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
        border-radius: 5px;
        overflow: hidden;

        &:hover {
            opacity: 0.5;
        }

        .ant-modal-close-x {
            position: relative;
            width: 100%;
            height: 100%;
            display: inline-block;
            display: flex;
            justify-content: center;
            align-items: center;
            fill: #000000;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #c4c4c4;
                opacity: 0.2;
                z-index: -1;
            }
        }
    }

    // Form items:
    .ant-form-horizontal .ant-form-item-label {
        min-width: 200px;
        text-align: left;

        & > label {
            font-weight: 700;
            font-size: 18px;
            line-height: 35px;
            color: #181818;
            line-height: 45px;
            height: auto;
        }
    }

    .ant-form-item-control-input-content .ant-input,
    .ant-form-item-control-input-content .ant-input-number {
        display: block;
        border: 1px solid rgba(0, 0, 0, 0.25);
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 35px;
        padding: 4px 20px;

        &::placeholder {
            color: #636364;
            opacity: 0.5;
        }
    }

    .ant-form-item-control-input-content .ant-input-number {
        width: 100%;
        overflow: hidden;

        .ant-input-number-input {
            height: auto;
            padding: 0;
        }
    }

    .ant-select.app-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid rgba(0, 0, 0, 0.25);
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
    }

    .ant-select.app-select .ant-select-selector .ant-select-selection-placeholder {
        font-style: normal;
        font-weight: 400;
        color: #636364;
    }

    .ant-picker {
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
        width: 100%;
        padding: 4px 20px;

        &:not(.ant-picker-status-error) {
            border: 1px solid rgba(0, 0, 0, 0.25);
        }

        .ant-picker-input > input {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 35px;

            &::placeholder {
                color: #636364;
                opacity: 0.5;
            }
        }
    }

    // Elements:
    .btn-group {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 30px;

        .btn {
            flex: 1 1 0%;
            width: 175px;
            max-width: 175px;
        }
    }
}
