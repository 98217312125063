.sptt-conversation_computer-answer.audio {
    &.active .computer-answer_wrapper {
        justify-content: space-between;
        box-shadow: none;
        .btn-continue {
            padding: 4px;
            border-radius: 50%;
            background-color: #4fe3c1;
            cursor: pointer;
            font-size: 10px;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            &:hover {
                box-shadow: 0 2px 2px rgb(0 0 0 / 30%), 0 0px 3px rgb(0 0 0 / 30%), 0 3px 3px rgb(0 0 0 / 30%);
            }
        }
    }
}
