.ant-modal.modal-add-users {
    .add_users_form {
        .ant-space.ant-space-horizontal {
            display: flex;
        }
        .ant-space-item {
            flex-grow: 1;
        }
        .button_add-new {
            .ant-form-item-control-input {
                display: flex;
                justify-content: center;
                &-content {
                    flex: 0;
                }
            }
        }
    }
}
