.doropdown-wrap:hover .custom-nav-dropdown {
    display: block;
}
.custom-nav-dropdown {
    z-index: 5;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}
.dropdown-item {
    margin: 0;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    cursor: pointer;
    transition: all 0.5s;
    &.active {
        background-color: #f5f5f5;
        a {
            color: #467afc;
        }
    }
}
.dropdown-item:hover {
    background-color: #f5f5f5;
}
.dropdown-link {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    line-height: 100%;
    min-height: 36px;

    .dropdown-icon {
        margin-right: 15px;
        svg {
            width: 24;
            height: 24px;
        }
    }
}
