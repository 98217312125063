@use 'src/assets/styles/utilities/variables' as vars;

.generalLearningProgress_wrapper {
    .filterButton {
        padding: 0 4px;

        .tags_button {
            height: 100%;

            .btn__icon-wrapper {
                display: block;
                height: 29px;

                & > svg {
                    display: block;
                    height: 100%;
                }
            }
        }
    }

    .generalLearningProgress_box {
        margin-top: 30px;
        min-height: 400px;
        .generalLearningProgress_filter {
            display: flex;
            justify-content: flex-end;
            .tags {
                padding: 0 4px;
                display: flex;

                .tag_item_inner {
                    padding: 12px 14px;
                    background: #d9d9d9;
                    border-radius: 10px;
                }
            }

            .PersonalLearningProgressFilter_wrapper {
                .PersonalLearningProgressFilter_inner {
                    display: flex;
                    justify-content: flex-end;
                    height: 100%;
                }
            }
        }

        .generalLearningProgress_dashboard {
            display: flex;
            .dashboard_item {
                padding: 10px;
                flex-basis: 33.333333%;
                .dashboard_content {
                    border-radius: 10px;
                    padding: 12px 0;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 50px;
                    font-weight: bold;

                    &.info {
                        background: #7fb6a1;
                    }

                    &.warning {
                        background: #f9d26d;
                    }

                    &.danger {
                        background: #fb836b;
                    }

                    &.disable {
                        background: rgb(117, 117, 117);
                    }

                    &.very_good {
                        background: #7fb6a1;
                    }

                    &.good {
                        background: #7acbbe;
                    }

                    &.medium {
                        background: #f9d26d;
                    }

                    &.bad {
                        background: #fbb36b;
                    }

                    &.failed {
                        background: #fb836b;
                    }
                }

                .arrowCompare {
                    height: 100%;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .generalLearningProgress_table {
            padding: 10px;
            margin-top: 10px;
            .ant-table-thead {
                @media screen and (max-width: 768px) {
                    th {
                        font-size: 12px;
                    }
                }
            }
            .ant-table-row {
                height: 105px;

                .ant-table-cell {
                    background-color: #fff;
                }

                .reportTable_scoreBar {
                    position: absolute;
                    top: 27px;
                    right: 0;
                    bottom: 27px;
                    left: 0;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    width: 100%;

                    background-color: #2dac81;
                    color: #000;
                }

                .reportTable_scoreCompare {
                    .reportTable_scoreBox {
                        display: flex;
                        height: 50px;
                        justify-content: center;
                        .reportTable_scoreItem {
                            flex-basis: 50%;
                            padding: 3px;
                            display: flex;
                            align-items: flex-end;
                            .reportTable_scoreItemContent {
                                width: 100%;
                                background-color: #000;
                            }
                        }
                    }

                    .reportTable_arrow {
                        img {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }

    .bigger {
        transform: rotate(-45deg);
    }

    .smaller {
        transform: rotate(45deg);
    }
}

.generalLearningProgress_wrapper {
    --scrollbar-mrleft: 0;

    .ant-table-content {
        &::-webkit-scrollbar-track {
            background-color: #f0f2f5;
            margin-left: var(--scrollbar-mrleft);
        }
    }

    .generalLearningProgress_box {
        .generalLearningProgress_table {
            position: relative;
        }
    }

    .table-general-learning-report {
        .ant-table table colgroup col {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                // min-width: calc((100vw - 20px - 85px - 80px) / 2 / 3);
                width: clamp(100px, (100vw - 20px - 85px - 80px) / 2 / 3, 150px) !important;
                min-width: 100px;
            }

            &:nth-child(n + 4) {
                min-width: 150px;
            }
        }

        .ant-table-thead > tr:nth-child(2) > th {
            border-top: 1px solid #f0f0f0;
        }
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
        // color: #fafafa;
        // user-select: none;
        font-style: italic;
        text-decoration: underline;
    }

    .ant-table-tbody > tr:last-child > td:nth-child(-n + 3) {
        border-bottom: none;
    }

    .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
        height: 100%;
    }

    .table-txt-limited-lines.lim-2-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

// RESPONSIVE:
@media screen and (max-width: 1439.98px) {
    .generalLearningProgress_wrapper {
        .filterButton .tags_button {
            min-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
            max-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
            font-size: 16px;
            padding: 4px 8px;
        }
    }
}

@media screen and (max-width: 768px) {
    .generalLearningProgress_wrapper {
        .table-general-learning-report {
            .ant-table table colgroup col {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    // min-width: calc((100vw - 20px - 80px) / 2 / 3);
                    min-width: max(100px, calc((100vw - 20px - 80px) / 2 / 3));
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .generalLearningProgress_wrapper {
        .ant-table-content {
            &::-webkit-scrollbar-track {
                margin-left: 0;
            }
        }
    }
}
