@use 'src/assets/styles/utilities/colors' as colors;

.app-jsxgraph-board {
    width: 100%;

    #my-jsxgraph-container_licenseText {
        display: none;
    }

    .JXG_navigation {
        z-index: 1 !important;
    }

    .JXG_navigation_button {
        display: inline-block;
        width: 26px;
        height: 26px;
        line-height: 26px;
        background-color: #eee;
        border-radius: 50%;
        text-align: center;
        padding: 0 !important;
        margin: 0 2px;

        &:hover {
            opacity: 0.8;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            display: none;
        }
    }

    .app-jsxgraph-actions {
        margin-bottom: 8px;
        padding: 8px;
        background: #fff;
        border: 2px solid #eee;
        border-radius: 10px;
    }

    .app-jsxgraph-navigation {
        position: absolute;
        top: 16px;
        left: auto;
        right: 16px;
        bottom: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        z-index: 10;

        button {
            min-width: 36px;
            min-height: 36px;
            background-color: rgba($color: colors.$primary, $alpha: 0.6);
            filter: opacity(1);
            user-select: none;

            &:hover {
                background-color: rgba($color: colors.$primary, $alpha: 1);
            }
        }
    }

    .app-jsxgraph-elemtags {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px 16px;
        margin-top: 8px;
        padding: 8px;
        background: #fff;
        border: 2px solid #eee;
        border-radius: 10px;
        min-height: 52px;

        .elemtag {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &.has-actions {
                padding-right: var(--has-actions-width);
                border-radius: 10px;
                background: linear-gradient(to left, #eee, #eee 42px, transparent 100%);
            }
        }

        .btn-tag {
            min-width: unset;
            cursor: auto;

            &:active {
                transform: scale(1);
            }
        }

        .btn-action-list {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translate(0, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }

        .btn-action {
            min-height: 30px;

            .btn__content {
                width: 26px;
                height: 26px;
            }

            .btn__icon-wrapper svg {
                height: 12px;
                max-width: 12px;
            }
        }
    }

    .app-jsxgraph-wrapper {
        position: relative;
        width: 100%;
        height: auto;
        background-color: #fff;
        border: 1px solid #eee;
        border-radius: 10px;
        overflow: auto;
    }

    .app-jsxgraph {
        --w: 350px;
        --h: 350px;
        width: var(--w);
        height: var(--h);
        margin: 0 auto;
    }
}

.app-jsxgraph-board.geometry-board {
    .app-jsxgraph-actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        .btn {
            min-width: 120px;

            .btn__content {
                flex-direction: column;
                gap: 8px;
            }

            .btn__icon-wrapper {
                margin-right: 0;
            }

            .btn__icon-wrapper svg {
                max-width: unset;
            }
        }
    }
}

.app-jsxgraph-board.numberline-board {
    $nllblWidth: 120px;

    .app-jsxgraph-actions {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 8px;

        & > .btn {
            width: auto;
        }

        & > *:not(.btn) {
            width: 100%;
        }
    }

    .app-jsxgraph-wrapper {
        &.under-dragelem {
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            transition: box-shadow 0.3s;
        }
    }

    .numberline-label-wrapper {
        padding-bottom: 8px;

        .numberline-label {
            position: relative;
            background: #fff;
            padding: 8px;
            border-radius: 10px;
            border: 1px solid #aaa;
            box-shadow: 0 -2px 0px 2px rgba(0, 0, 0, 0.1);
            font-size: 12pt;
            line-height: 1.5;
            width: 120px;
            min-height: 41.6px;

            &::after {
                content: "";
                position: absolute;
                top: 100%;
                left: calc(50% - 10px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 10px 0px;
                border-color: #000 transparent transparent;
            }

            &.is-correct {
                color: #fff;
                background-color: #52c41a;
            }

            &.is-incorrect {
                color: #fff;
                background-color: #ff4d4f;
            }

            .label {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .label-pos {
                position: absolute;
                top: -16px;
                left: 0;
                min-width: 36px;
                color: #000;
                background-color: #eee;
                padding: 0 8px;
                border-radius: 10px;
                font-size: 8pt;
                line-height: 24px;
                text-align: center;
            }

            .label-btn-out {
                position: absolute;
                top: -16px;
                right: -12px;
                min-width: 24px;
                width: 24px;
                height: 24px;
                background-color: #ddd;
                border-radius: 50%;
                text-align: center;
                cursor: pointer;

                &:hover {
                    box-shadow: 0 0 0 4px #eee;
                }
            }
        }
    }

    .nllbl-input-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        background-color: #f5f5f5;
        border-radius: 36px;

        .nllbl-input {
            width: 100%;
        }

        .nllbl-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 45px;
            overflow: visible;

            &::after {
                content: ".";
                white-space: nowrap;
                width: 0;
                visibility: hidden;
                opacity: 0;
            }

            .action-wrapper {
                height: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .action-btn {
                .btn__content {
                    background-color: #ddd;
                }

                .btn__icon-wrapper > svg {
                    fill: #000;
                    width: 24px;
                }

                &.danger:hover {
                    .btn__content {
                        background-color: colors.$danger;
                    }

                    .btn__icon-wrapper > svg {
                        fill: #fff;
                    }
                }
            }
        }
    }

    .nllbl-btn {
        min-width: $nllblWidth;
        max-width: 250px;
        word-wrap: break-word;
        word-break: break-word;
        white-space: pre-wrap;
    }

    .nllbl-btn-drg.nllbl-drg-wrapper {
        position: relative;
        width: 100%;

        .nllbl-drg {
            min-width: $nllblWidth;
            z-index: 1;

            .drg-wrapper {
                .drg-content {
                    box-shadow: 0px 0px 0px 2px colors.$second;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: calc(50% - 10px);
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 8px 10px 0px;
                        border-color: colors.$second transparent transparent;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.3s;
                        transition-delay: 0.2s;
                    }
                }
            }

            &.react-draggable-dragging {
                cursor: grabbing;

                .drg-wrapper {
                    .drg-content {
                        &::after {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .app-jsxgraph-board.numberline-board {
        .nllbl-input-wrapper {
            .nllbl-actions {
                min-height: 36px;
            }
        }
    }
}
