@use 'src/assets/styles/utilities/colors' as c;

.my-plan {
    .plan-actions-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        margin-top: 15px;
    }

    .organization-detail {
        background-color: #fff;
        border-radius: 20px;
    }

    .org-avatar {
        &.placeholder {
            width: 100px;
            height: 100px;
            min-width: 100px;
            background-color: #eee;
            border-radius: 50%;
        }
    }

    .ant-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    // .form-viewer:
    // .form-viewer {
    // }

    .form-viewer-section {
        &.layout-row-center {
            align-items: center;
        }

        .btn-group.for-organization-detail {
            margin-top: 0;
        }
    }

    .form-viewer-title {
        font-size: 18px;
        font-weight: 700;

        &.highlight-with-singlebar {
            position: relative;
            padding-left: 11px;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                display: inline-block;
                width: 3px;
                height: 20px;
                background-color: c.$second;
            }
        }
    }

    .form-viewer-item {
        .item-title {
            min-width: 200px;
        }
    }

    .form-viewer-list {
        &.list-wrapper {
            border: 1px solid rgba(140, 140, 140, 0.35);
            border-radius: 10px;
            overflow: hidden;
        }

        .list-branches {
            min-height: 45px;
            max-height: calc((66.28px * 3) + (8px * 4));
            overflow-x: hidden;
            overflow-y: auto;
            padding: 8px;
            transition: all 0.3s ease;
        }

        .list-classes {
            max-height: calc((141.7px * 3) + (8px * 4));
            overflow-x: hidden;
            overflow-y: auto;
            padding: 8px;
        }
    }

    .container-for-boxes {
        & > .form-viewer-item {
            &:not(:first-child) {
                margin-top: 30px;
            }
        }
    }

    .container-for-fields {
        & > .form-viewer-item {
            &:not(:first-child) {
                margin-top: 8px;
            }
        }
    }

    .order-list {
        padding: 30px;
        margin-top: 30px;
        background-color: #fff;
        border-radius: 20px;

        .order-info {
            font-size: 16px;

            .info-section {
                margin-top: 8px;
            }

            .info-text {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;

                .text-label {
                    font-weight: bold;
                }

                .text-value {
                    font-size: 16px;
                }
            }

            .info-section.plan-info {
                .info-text {
                    align-items: flex-start;
                }
            }

            .info-section.options {
                .info-text {
                    align-items: flex-start;
                }
            }
        }
    }

    .my-option-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .my-option-listitem {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;

            svg {
                width: 16px;
                height: 25.14px;
                display: block;
            }
        }
    }
}

// RESPONSIVE DESIGN:
@media screen and (max-width: 1399.98px) {
    .organization-detail-wrapper {
        .form-viewer-list {
            .list-branches {
                min-height: 36px;
            }
        }
    }
}
