.filter-gradebook_wrapper {
    background-color: #fff;
    padding: 20px 10px 20px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-height: 400px;
    max-width: 500px;
    overflow-y: auto;

    .ant-form.filter-gradebook_inner {
        max-height: 370px;
        overflow-y: auto;

        .filter-person_input {
            padding: 22px 20px;
            border-radius: 10px;
            height: 35px;
        }

        .ant-form-item {
            margin-bottom: 10px;
        }

        .filter-person_selector {
            .ant-select-selector {
                padding: 0 0 0 20px;
                height: auto;
                border-radius: 10px;
                height: 35px;
                align-items: center;

                // .ant-select-selection-placeholder {
                //     height: 22px;
                //     transform: translateY(-25%);
                // }

                .ant-select-selection-search {
                    display: flex;
                    align-items: center;
                    padding-left: 9px;
                }
            }
        }

        .ant-form-item-label {
            text-align: left;
            width: 100%;
            padding: 0;
        }

        .ant-form-item {
            flex-direction: column;

            padding: 0px 15px;

            .ant-col.ant-form-item-label {
                font-weight: bold;
            }

            .ant-picker.filter-person_input {
                width: 100%;
            }

            .ant-form-item-control {
                flex: auto !important;
            }
        }

        .filter_btn {
            justify-content: center;
            gap: 10px;
            margin-top: 6px;
        }
    }

    @media screen and (max-width: 991px) {
        padding: 20px;
        max-height: unset;
    }
}
