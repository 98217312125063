// COLOR LIST:

// Statuses:
$danger: #ff4d4f;
$success: #52c41a;
$info: #1890ff;
$warning: #faad14;

// Default list:
$primary: #445cfe;
$primary1: #f54e62;
$second: #4fe3c1;
$dream: #8e6cf7;
$sunlight: #fce389;
$fog: #979c9e;

// - Ink:
$color_ink_lightest: #72777a;
$color_ink_lighter: #72777a;
$color_ink_light: #6c7072;
$color_ink_base: #404446;
$color_ink_dark: #303437;
$color_ink_Darker: #202325;
$color_ink_Darkest: #090a0a;

// - Sky:
$color_sky_lightest: #f7f9fa;
$color_sky_lighter: #f2f4f5;
$color_sky_light: #e3e5e6;
$color_sky_base: #cdcfd0;
$color_sky_dark: #979c9e;
$color_sky_Darker: #979c9e;
$color_sky_Darkest: #979c9e;

// - Primary:
// from #FB3B50 to #F54E62.
$color_primary_lightest: linear-gradient(180deg, rgba(251, 59, 80, 0.2) 0%, rgba(245, 78, 98, 0.2) 100%);
$color_primary_lighter: linear-gradient(180deg, rgba(251, 59, 80, 0.4) 0%, rgba(245, 78, 98, 0.4) 100%);
$color_primary_light: linear-gradient(180deg, rgba(251, 59, 80, 0.6) 0%, rgba(245, 78, 98, 0.6) 100%);
$color_primary_base: linear-gradient(180deg, rgba(251, 59, 80, 0.8) 0%, rgba(245, 78, 98, 0.8) 100%);
$color_primary_dark: linear-gradient(180deg, rgba(251, 59, 80, 1) 0%, rgba(245, 78, 98, 1) 100%);
$color_primary_Darker: linear-gradient(180deg, rgba(251, 59, 80, 1) 0%, rgba(245, 78, 98, 1) 100%);
$color_primary_Darkest: linear-gradient(180deg, rgba(251, 59, 80, 1) 0%, rgba(245, 78, 98, 1) 100%);

// - Second:
// from #3F6FEA to #6C94FB.
$color_second_lightest: linear-gradient(180deg, rgba(63, 111, 234, 0.2) 0%, rgba(108, 148, 251, 0.2) 100%);
$color_second_lighter: linear-gradient(180deg, rgba(63, 111, 234, 0.4) 0%, rgba(108, 148, 251, 0.4) 100%);
$color_second_light: linear-gradient(180deg, rgba(63, 111, 234, 0.6) 0%, rgba(108, 148, 251, 0.6) 100%);
$color_second_base: linear-gradient(180deg, rgba(63, 111, 234, 0.8) 0%, rgba(108, 148, 251, 0.8) 100%);
$color_second_dark: linear-gradient(180deg, rgba(63, 111, 234, 1) 0%, rgba(108, 148, 251, 1) 100%);
$color_second_Darker: linear-gradient(180deg, rgba(63, 111, 234, 1) 0%, rgba(108, 148, 251, 1) 100%);
$color_second_Darkest: linear-gradient(180deg, rgba(63, 111, 234, 1) 0%, rgba(108, 148, 251, 1) 100%);

// - Detail:
// #FFD00D, rgba(255, 208, 13, 1).
$color_detail_lightest: rgba(255, 208, 13, 0.2);
$color_detail_lighter: rgba(255, 208, 13, 0.4);
$color_detail_light: rgba(255, 208, 13, 0.6);
$color_detail_base: rgba(255, 208, 13, 0.8);
$color_detail_dark: rgba(255, 208, 13, 1);
$color_detail_Darker: rgba(255, 208, 13, 1);
$color_detail_Darkest: rgba(255, 208, 13, 1);

// Others:
$white: #fff;
$text_black: #090a0a;
$yellow: #ff0;
