@use 'src/assets/styles/utilities/colors' as c;

.multipledisplays-check {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;

    .result-display {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
        padding: 16px 0;

        .displays-icon {
            .anticon {
                font-size: 64px;
                margin-bottom: 16px;
            }
        }
    }

    .check-text {
        text-align: center;

        .check-title {
            font-size: 26px;
            line-height: 32px;
        }

        .check-result-info {
            margin-top: 8px;
        }

        .check-result-message {
            .result-tag {
                display: inline-block;
                background-color: #aaa;
                color: #fff;
                padding: 1px 8px;
                border-radius: 4px;

                &.danger {
                    background-color: c.$danger;
                }

                &.success {
                    background-color: c.$success;
                }
            }
        }

        .check-result-desctiption {
            max-width: 350px;
            font-style: italic;
            margin-top: 4px;

            &.danger {
                color: c.$danger;
            }
        }
    }
}
