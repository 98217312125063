.TestHeader {
    background: #0077ff;
    padding: 15px 30px;
    border-radius: 20px;
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;

    .title {
        font-size: 26px;
        font-weight: bold;
    }

    .description {
        font-size: 16px;
        font-weight: 400;
        max-width: 579px;
        margin-bottom: 0;
    }

    .icon {
    }

    .TestHeader_Clock {
        display: none;

        .ant-progress-text {
            color: white;
        }

        .TestHeader_ClockPanel {
            background: rgba(255, 255, 255, 0.25);
            height: 35px;
            min-width: 35px;
            padding: 0 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 14px;
            border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, 0.5);
        }

        .TestHeader_ClockSeparate {
            margin: 0 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 1024px) {
    .TestHeader {
        .TestHeader_Clock {
            display: flex;
        }

        .description {
            display: none;
        }

        .icon {
            display: none;
        }

        .title {
            font-size: 16px;
            height: 16px;
            line-height: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
