@use 'src/assets/styles/utilities/colors' as c;

.publicasgmtusers-upload {
    .button-import {
        text-align: center;
        position: relative;
        margin-bottom: 18px;

        &_sub-btn {
            cursor: pointer;
            position: absolute;
            left: 0;
            right: 0;
            font-size: 12px;
            line-height: 1.5;

            &:hover {
                color: c.$primary;
            }
        }
    }
}
