.input-suffix {
    border-radius: 10px !important;

    &:has(.ant-input-suffix) {
        padding: 0 !important;
    }

    .delete-button {
        padding: 0 5px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;

        &:hover {
            opacity: 0.3;
        }

        &--disable {
            cursor: not-allowed;

            &:hover {
                opacity: 1;
            }
        }
    }

    .ant-input {
        padding: 5px 16px !important;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .ant-input-suffix {
        padding: 4px;
        background-color: #ddd;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}
