@use 'src/assets/styles/utilities/colors' as colors;

.options-panel {
    background-color: #fff;
    padding: 0 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);

    .options-title {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .options-header {
        padding: 16px 0 8px 0;
        border-bottom: 1px solid #dedfe0;

        &::after {
            clear: both;
            content: "";
            display: block;
            visibility: hidden;
            font-size: 0px;
            height: 0px;
        }
    }

    .options-body {
        padding: 8px 0;
    }

    .option-names {
        .option-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            flex-wrap: wrap;

            & > span:nth-child(2) {
                font-weight: bold;
            }
        }
    }

    .option-price {
        .origin {
            text-decoration: line-through;
        }

        .curr {
            font-weight: bold;
        }
    }

    .options-select {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;

        .ant-select.opt-select {
            width: calc(100% - 100px);
        }

        .btn {
            min-width: unset;
            max-width: 100px;
        }
    }

    .option-quantity {
        .ant-input-number {
            width: 60px;
        }
    }

    .option-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        .option-btn {
            &.btn.btn-icon-only .btn__content {
                background-color: transparent;
                transition: all 0.5s ease;
            }

            .btn__icon-wrapper svg {
                fill: colors.$primary;
                height: 22px;
                max-width: 22px;
                transition: all 0.5s ease;
            }

            &:hover {
                &.btn.btn-icon-only .btn__content {
                    background-color: colors.$primary;
                }

                .btn__icon-wrapper svg {
                    fill: #fff;
                }
            }

            &.btn-danger {
                .btn__icon-wrapper svg {
                    fill: colors.$danger;
                }

                &:hover {
                    &.btn.btn-icon-only .btn__content {
                        background-color: colors.$danger;
                    }

                    .btn__icon-wrapper svg {
                        fill: #fff;
                    }
                }
            }

            &:disabled,
            &.disabled {
                .btn__icon-wrapper svg {
                    fill: #cdcfd0;
                }
            }
        }
    }
}
