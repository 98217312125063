@use 'src/assets/styles/utilities/breakpoint.scss' as b;

.homeStudent_wrapper {
    .score-student-filter {
        display: none;
    }

    .cardList {
        .cardItem {
            .cardItem_info {
                .cardItem_scoreWrapper {
                    color: #0077ff;
                    display: flex;
                    align-items: flex-end;

                    .cardItem_score {
                        font-size: 35px;
                        font-weight: bold;
                        margin-right: 5px;
                    }

                    .cardItem_unit {
                        font-size: 14px;
                        padding: 10px 0;
                    }
                }

                .cardItem_board {
                    font-size: 14px;
                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    @include b.tablet-down {
        .score-student-filter {
            display: block;
            background-color: #fff;
            padding: 10.5px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
        // .student-filter {
        //     display: none;
        // }
        // .homeStudent_header {
        //     display: none;
        // }
        .cardList {
            .cardItem {
                .cardItem_info {
                    .cardItem_score {
                        font-size: 20px;
                    }
                    .cardItem_unit {
                        font-size: 11px;
                        padding: 5px 0;
                    }
                }
            }
        }
    }
}
