@use 'src/assets/styles/utilities/_colors.scss' as colorSet;

.course-header {
    position: relative;
    padding: 50px 30px;
    // background-color: #0077ff;
    background: colorSet.$color_second_dark;
    border-radius: 20px;

    .course-header-wrapper {
        position: relative;

        p {
            color: #fff;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .course-header-title {
            font-size: 26px;
            line-height: 35px;
            font-weight: 700;
            -webkit-line-clamp: 1;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../../../../assets/images/decoration-clock.svg);
            background-repeat: no-repeat;
            background-position: right center;
            z-index: 1;
            mix-blend-mode: soft-light;
            opacity: 0.25;
        }

        .course-header-img {
            position: absolute;
            top: 0;
            right: 0;

            img {
                width: 70%;
                height: 70%;
            }

            @media screen and (max-width: 768px) {
                opacity: 0.2;

                img {
                    width: 50%;
                    height: 50%;
                }
            }
        }
    }
}

.detail-course-header {
    position: relative;
    padding: 30px;
    // background-color: #0077ff;
    background: colorSet.$color_second_dark;
    border-radius: 20px;

    .detail-course-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 16px;

        .course-img-wrapper {
            border-radius: 10px;
            overflow: hidden;
            min-width: fit-content;
        }

        ul {
            width: 100%;
            list-style: none;
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            margin: 0;
            padding: 0;
            // max-width: 1200px;

            li {
                color: #fff;
                overflow-wrap: anywhere;

                p {
                    margin-bottom: 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    span {
                        margin-bottom: 5px;
                        font-size: 16px;
                        line-height: 25px;
                    }

                    .detail-course-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 36px;
                        height: 36px;
                        background-color: #fff;
                        border-radius: 50%;
                        color: #0077ff;
                    }

                    strong {
                        font-size: 20px;
                        line-height: 25px;
                    }

                    strong.show-more {
                        letter-spacing: 2px;
                        font-size: 25px;
                        line-height: 25px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.course-header-dropdown-menu {
    background-color: #fff;
    color: #0077ff;
    font-size: 18px;
    font-weight: lighter;
    line-height: 25px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 0px 10px 5px rgb(0, 0, 0, 0.1);
    max-height: 235px;
    overflow-y: auto;

    strong {
        display: block;
        border-bottom: 1px solid #ececec;
        padding: 10px 20px;
    }
}

@media screen and (max-width: 1399.98px) {
    .detail-course-header {
        .detail-course-header-wrapper {
            flex-direction: row;
            column-gap: 20px;
            row-gap: 0;

            ul {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(111px, max-content));
                grid-gap: 20px;
                justify-content: space-between;
                align-items: flex-start;
            }
        }
    }
}

@media screen and (max-width: 1199.98px) {
    .detail-course-header {
        .detail-course-header-wrapper {
            .course-img-wrapper {
                // display: none;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .detail-course-header {
        .detail-course-header-wrapper {
            flex-direction: column;
            column-gap: 0;
            row-gap: 20px;
        }
    }
}
