.question-number-list {
    .number-list-title {
        color: #fff;
        font-weight: 700;
        font-size: 26px;
        line-height: 26px;
        letter-spacing: -0.03em;
        margin-bottom: 24px;
        text-align: center;
    }

    .number-btn {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: -0.03em;
        background-color: #ffffff;
        color: #0077ff;
        user-select: none;
        cursor: pointer;

        &.active {
            font-weight: 700;
            background-color: #ff1d86;
            color: #fff;
        }

        &:hover {
            opacity: 0.8;
        }

        &:active {
            transform: scale(0.8);
        }
    }

    .number-list-wrapper {
        display: flex;
        // column-gap: 15px;
        // row-gap: 10px;
        flex-wrap: wrap;
        max-height: 220px;
        overflow-y: auto;
        overflow-x: hidden;

        .number-list-itemWrapper {
            padding: 4px 8px;
            flex-basis: 20%;

            @media screen and (max-width: 1024px) {
                flex-basis: 25%;
            }

            .number-list-item {
                height: 44px;
            }
        }
    }
}

.question-number-list.ant-pagination {
    .ant-pagination-item {
        min-width: 40px;
        min-height: 40px;
        border-radius: 8px;
        background: none;
        border: none;
        line-height: 40px;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #0077ff;
        user-select: none;
        cursor: pointer;

        a {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background-color: #ffffff;
            color: inherit;

            &:active {
                transform: scale(0.8);
            }
        }

        &:hover {
            a {
                opacity: 0.8;
            }
        }
    }

    .ant-pagination-item.ant-pagination-item-active {
        font-weight: 700;

        a {
            background-color: #ff1d86;
            color: #fff;
        }
    }

    .ant-pagination-jump-next,
    .ant-pagination-jump-prev,
    .ant-pagination-next,
    .ant-pagination-prev {
        height: auto;
    }

    .ant-pagination-item-link {
        min-width: 40px;
        min-height: 40px;
        border-radius: 8px;
        background: #fff;
        border: none;
        line-height: 40px;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #0077ff;
        user-select: none;
        cursor: pointer;
        position: relative;

        .anticon.anticon-left,
        .anticon.anticon-right {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        cursor: not-allowed;
    }

    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
        font-size: 16px;
    }
}
