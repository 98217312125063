@use 'src/assets/styles/utilities/colors' as c;

.modal-publicasgmtusers {
    &.ant-modal {
        .list-top {
            display: flex;
            justify-content: space-between;
            align-content: center;
            gap: 8px 16px;
            margin-bottom: 8px;
        }

        .list-title {
            font-weight: bold;
            font-size: 18px;
        }

        .list-actions {
            display: flex;
            justify-content: center;
            align-content: center;
            gap: 8px;
            flex-wrap: wrap;
        }

        .user-table {
            .list-actions {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
            }

            .list-btn {
                &.btn.btn-icon-only .btn__content {
                    background-color: transparent;
                    transition: all 0.5s ease;
                }

                .btn__icon-wrapper svg {
                    fill: c.$primary;
                    height: 22px;
                    max-width: 22px;
                    transition: all 0.5s ease;
                }

                &:hover {
                    &.btn.btn-icon-only .btn__content {
                        background-color: c.$primary;
                    }

                    .btn__icon-wrapper svg {
                        fill: #fff;
                    }
                }

                &.btn-danger {
                    .btn__icon-wrapper svg {
                        fill: c.$danger;
                    }

                    &:hover {
                        &.btn.btn-icon-only .btn__content {
                            background-color: c.$danger;
                        }

                        .btn__icon-wrapper svg {
                            fill: #fff;
                        }
                    }
                }

                &:disabled,
                &.disabled {
                    .btn__icon-wrapper svg {
                        fill: #cdcfd0;
                    }
                }
            }
        }
    }
}

// .modal-publicasgmtusers-wrap {
//     &.ant-modal-wrap {
//         z-index: 1020;
//     }
// }

@media screen and (max-width: 991.98px) {
    .modal-publicasgmtusers {
        &.ant-modal {
            .list-top {
                flex-direction: column;
                width: 100%;
            }

            .list-title {
                width: 100%;
            }

            .list-actions {
                width: 100%;
            }
        }
    }
}
