$font_size: 18px;
$title_font_weight: 700;

.list_dragdrop {
    .ant-form-item-control-input-content {
        display: flex;
        column-gap: 10px;
    }
}

.dragdrop_col {
    width: 50%;
    .ant-input {
        border-radius: 6px;
    }
}

.dragdrop_col-header {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
}
