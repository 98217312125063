.question-detail.passage {
    .question-setup-wrapper {
        margin-bottom: 30px;
    }

    .question-passage-wrapper {
        margin-bottom: 30px;

        .question-passage {
            justify-content: flex-start;
            align-items: stretch;

            &:not(:last-child) {
                margin-bottom: 30px;
            }

            .paragraph-wrapper,
            .question-list-wrapper {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: 10px;
                // padding: 25px;
                flex: 1 1 0%;
                // word-break: break-word;
                height: 100%;
            }

            .paragraph-wrapper {
                padding: 25px;
                height: 100%;
            }

            .question-list-wrapper {
                height: auto;
            }
        }

        .paragraph-wrapper {
            .paragraph-title,
            .paragraph-heading {
                line-height: 35px;
                // Limit text length to n lines:
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            .paragraph-title {
                font-weight: 700;
                margin-bottom: 8px;
                font-size: 18px;
            }

            .paragraph-heading {
                font-style: italic;
                font-size: 14px;
            }

            .paragraph-content {
                font-weight: normal;
                font-size: 16px;
                // Limit text length to n lines:
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                & > p {
                    margin-bottom: 0;
                }

                & > pre {
                    white-space: pre-wrap;
                }
            }

            .action-list {
                margin-top: 20px;
            }
        }

        .question-wrapper {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            padding: 10px 25px;
            max-height: 550px;
            overflow-y: auto;
            overflow-x: auto;

            &:first-child {
                // border-top: 1px solid #d9d9d9;
                box-shadow: 0 0 2px 1px #d9d9d9;
            }

            &:not(:last-child) {
                padding-bottom: 21px;
                // border-bottom: 1px solid #d9d9d9;
                box-shadow: 0 0 2px 1px #d9d9d9;
            }

            &:not(:first-child) {
                padding-top: 20px;
            }

            .action-list {
                margin-top: 20px;
            }

            p {
                margin-bottom: 0 !important;
            }

            pre {
                white-space: pre-wrap;
            }

            .q-content-title {
                margin-bottom: 10px;
                // Limit text length to n lines:
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .question-list-wrapper {
            .list-header {
                text-align: center;
                margin-bottom: 30px;
                padding: 25px;
            }
        }
    }

    .action-list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            display: flex;
            align-items: center;
            column-gap: 20px;
            color: #0077ff;
            font-weight: bold;
        }

        .action-button {
            display: block;
            cursor: pointer;
            user-select: none;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #eee;

            &:hover {
                opacity: 0.8;
            }

            &:active {
                transform: scale(0.8);
            }
        }
    }

    .detail-passage-question {
        .ant-form-item {
            margin-bottom: 0;

            .ant-form-item-label {
                margin-right: 20px;
                line-height: 45px;
                height: auto;
            }
        }

        margin-bottom: 30px;
    }

    .detail-passage-total-score {
        .ant-row {
            display: flex;
            align-items: center;
        }

        .app-input {
            background-color: #f5f5f5;

            &:focus-within {
                box-shadow: none;
                border: 1px solid #d9d9d9;
            }

            &:hover {
                border: 1px solid #d9d9d9;
            }
        }
    }
}

// RESPONSIVE:
// @media screen and (max-width: 1399.98px) {
//     .question-detail.passage {
//     }
// }

@media screen and (max-width: 991.98px) {
    .question-detail.passage {
        .question-passage-wrapper {
            .question-passage {
                flex-direction: column;

                .paragraph-wrapper,
                .question-list-wrapper {
                    width: 100%;
                }
            }
        }
    }
}
