.bank-transfer-info {
    margin: -8px 0;

    .info-section {
        padding: 8px 0;

        &:not(:last-child) {
            border-bottom: 1px solid #dedfe0;
        }

        .account-name > *:nth-child(2),
        .payment-content > *:nth-child(2) {
            font-weight: 700;
        }
    }

    .bank-info {
        .bank-info-header {
            margin-bottom: 16px;

            & > * {
                font-weight: 700;
            }
        }

        .bank-info-content {
            & > *:first-child {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                row-gap: 8px;
                column-gap: 16px;
            }
        }
    }

    .bank-logo-wrapper {
        display: inline-block;
        min-width: 80px;
    }

    .bank-logo {
        display: inline-block;
        max-width: 80px;
        height: auto;

        & > img {
            display: block;
            width: 100%;
            height: auto;
            max-height: 100%;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .bank-transfer-info {
        .info-section {
            & > * {
                padding: 8px 0;
            }

            & > *:first-child {
                padding-top: 0;
            }

            & > *:last-child {
                padding-bottom: 0;
            }
        }
    }
}
