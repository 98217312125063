@use 'src/assets/styles/utilities/colors' as c;

.image-labels-container {
    $boxShadow1: 0px 0px 5px 2px rgb(0, 0, 0, 0.1), 0px 0px 0px 4px rgb(255, 255, 255, 0.5),
        0px 8px 16px 0px rgb(0, 0, 0, 0.5);
    $boxShadow2: 0px 0px 5px 2px rgb(0, 0, 0, 0.1), 0px 0px 0px 4px rgb(255, 255, 255, 0.8),
        0px 8px 16px 0px rgb(0, 0, 0, 0.5);
    $boxShadow3: 0px 0px 5px 2px rgb(0, 0, 0, 0.1), 0px 0px 0px 4px rgb(255, 255, 255, 0.8),
        0px 8px 16px 0px rgb(0, 0, 0, 1);

    .image-label {
        position: absolute;
        width: 130px;
        height: 36px;
        border-radius: 10px;
        box-shadow: $boxShadow1;
        transition: box-shadow 0.2s ease;

        &:hover {
            box-shadow: $boxShadow2;

            .image-label-content-wrapper {
                .image-label-pin {
                    box-shadow: $boxShadow2;
                }

                .image-label-close {
                    transform: scale(1);
                }
            }
        }

        &.is-focused {
            z-index: 1;
        }

        &.under-dragelem {
            box-shadow: 0 0 0 5px rgb(255, 255, 255, 0.8), 0 0 0 10px rgb(255, 255, 255, 0.5);
        }
    }

    .image-label.draggable {
        cursor: grab;

        &.dragging {
            box-shadow: $boxShadow3;
            cursor: grabbing;
        }
    }

    .image-label-content-wrapper {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;

        .image-label-pin {
            position: absolute;
            top: -5px;
            left: -5px;
            width: 10px;
            height: 10px;
            background-color: #fff;
            border: 1px solid #d9d9d9;
            border-radius: 100%;
            box-shadow: $boxShadow1;
            transition: box-shadow 0.2s ease;
            // z-index: 10;
            cursor: pointer;
        }

        .image-label-close {
            position: absolute;
            top: -12px;
            left: -12px;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border: 1px solid #d9d9d9;
            border-radius: 100%;
            box-shadow: 0px 0px 5px 2px rgb(0, 0, 0, 0.1), 0px 0px 0px 4px rgb(255, 255, 255, 0.5);
            transform: scale(0);
            transition: transform 0.2s ease;
            z-index: 11;
            cursor: pointer;

            &:hover {
                transform: scale(1);
                box-shadow: 0px 0px 5px 2px rgb(0, 0, 0, 0.1), 0px 0px 0px 4px rgb(255, 255, 255, 0.8);
            }
        }

        .image-label-content {
            width: 100%;
            height: 100%;
            line-height: 32px;
            padding: 0 8px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .image-label-content-withnum {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            width: 100%;
            height: 100%;
            padding: 0 8px;
        }

        .image-label-num {
            display: block;
            min-width: 24px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            background-color: c.$primary;
            color: #fff;
            border-radius: 50%;
            text-align: center;
        }
    }

    // Label answer - Text:
    .image-label.text {
        background-color: #fff;
        border: 2px solid #fff;

        .image-label-input {
            padding: 0 8px;
            margin: 1px;
            font-size: 14px;
            border: none;
            border-radius: unset;
            outline: none;
            box-shadow: none;
            background: none;

            &:hover {
                border: none;
            }
        }

        .image-label-content-withnum {
            .image-label-content {
                padding: 0;
            }

            .image-label-input {
                padding: 0;
            }
        }
    }

    // Label answer - Dropdown:
    .image-label.dropdown {
        background-color: rgba(255, 255, 255, 0.5);
        border: 2px solid #fff;

        &.has-val {
            background-color: #fff;
        }
    }

    // Label answer - Drag & drop:
    .image-label.drag-drop {
        background-color: rgba(255, 255, 255, 0.5);
        border: 2px dashed #fff;

        &.has-val {
            background-color: #fff;
        }
    }
}

.image-labels-container.highlight-dragging {
    pointer-events: none;

    .image-label:not(.dragging) {
        opacity: 0.5;
    }

    .image-label.draggable {
        pointer-events: initial;

        &.dragging {
            z-index: 1;

            .image-label-close {
                transform: scale(0);
            }
        }
    }
}
