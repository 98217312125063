@use "src/assets/styles/utilities/breakpoint" as *;
@use 'src/assets/styles/utilities/variables' as vars;

.app-main-layout {
    min-height: 100vh;

    .layout-header {
        min-height: 88px;

        .header-flexible {
            padding: 8px 135px;
            transition: height 0.5s ease, background-color 0.3s ease;
        }
    }

    .layout-nav-menu {
        padding: 0 135px;
        margin-bottom: 30px;
    }

    .layout-header.fixed-on-top {
        .header-flexible {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            background: #fff;
            box-shadow: 0 2px 15px 0px rgba(0, 0, 0, 0.2);
            z-index: 1000;
        }
    }

    .layout-content {
        background: none;
        padding: 0 135px 20px 135px;
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .app-main-layout {
        .layout-header {
            min-height: 73px;

            .header-flexible {
                padding: 8px 30px;
            }
        }

        .layout-nav-menu {
            padding: 0 30px;
        }

        .layout-content {
            background: none;
            padding: 0 30px 20px 30px;
        }
    }
}

@include tablet-down {
    .app-main-layout {
        background-color: #fff;

        .layout-content {
            background: #f7f7f7;
            border-radius: 30px 30px 0px 0px;
            margin-top: 25px;
            min-height: 100vh;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .app-main-layout {
        .layout-header {
            .header-flexible {
                padding: 8px 16px;
            }
        }

        .layout-nav-menu {
            padding: 0 16px;
        }

        .layout-content {
            // background: none;
            padding: 0 16px 20px 16px;
        }
    }
}
