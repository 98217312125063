.media-audio-action {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;

    &::after {
        content: "\a0";
        display: inline-block;
        width: 0;
        visibility: hidden;
    }

    .audio-icon-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.audio-icon-button {
    display: inline-block;
    width: 100%;
    height: 100%;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    cursor: pointer;

    .audio-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #f5f5f5;

        & > img,
        & > svg {
            display: block;
            width: 24px;
            height: 24px;
        }
    }

    &:hover {
        .audio-icon-wrapper {
            background-color: #eee;
        }
    }

    &:active {
        .audio-icon-wrapper {
            transform: scale(0.8);
        }
    }
}

.audio-icon-button.audio-play {
    // position: relative;
    z-index: 1;

    &::after,
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
        transform-origin: center;
        background-color: #ddd;
        z-index: -1;
    }

    &::after {
        animation-name: pulse;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: initial;
    }

    &::before {
        animation-name: pulse;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-delay: 0.5s;
        animation-iteration-count: infinite;
        animation-direction: initial;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(1.5);
            opacity: 0;
        }
    }
}

.media-audio-element--type-player {
    display: block;
    width: 100%;
    height: 32px;
}

.media-video-element--type-player {
    position: relative;
    z-index: 1;
}

.div-as-p {
    position: relative;
    margin-bottom: 1em;
}

.fill-blank-drag-drop__box-item {
    // width: 225px;
    position: relative;
    // top: 15px;
    height: 40px;
    min-width: 232px;
    border: 1px dashed #e9e9e9;

    margin: 0 5px;
    display: inline-block;

    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;

    > span {
        visibility: hidden;
    }

    .fill-blank-drag-drop__drag-item--answer {
        visibility: visible;
    }

    &.fixHeight {
        height: 45px;
    }

    // &::before {
    //     content: "";
    //     display: inline-block;
    // }
}

.question-content__list-dragdrop {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    min-height: 47px; // 35px height + 10px padding + 2px border
    border: 1px dashed #000;
    border-radius: 5px;
    align-items: center;
    column-gap: 5px;
    // padding-bottom: 20px;

    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    .fill-blank-drag-drop__drag-item__skin {
        padding: 5px;
    }
}

.fill-blank-drag-drop__drag-item {
    min-width: 230px;
    border-radius: 4px;
    // height: 45px;
    // background-color: #0077ff;
    background-color: #fff;
    border: 2px solid #0077ff;
    // color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 0 10px;

    &--disable {
        background-color: #ddd;
        cursor: not-allowed;
    }
}

// RESPONSIVE DESIGN:
@media screen and (max-width: 1399.98px) {
    .media-audio-action {
        width: 36px;
        height: 36px;
    }
}
