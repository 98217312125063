// ClassNames: app-checkbox.

// (Ant Design) Checkbox:
.ant-checkbox-wrapper.app-checkbox {
    display: flex;
    align-items: center;

    .ant-checkbox {
        transform: translateY(-4px);

        .ant-checkbox-inner {
            height: 45px;
            width: 45px;

            border: 2px solid #d9d9d9;
            box-sizing: border-box;
            border-radius: 10px;

            &:hover {
                border: 2px solid #0077ff;
            }

            &::after {
                border-color: #0077ff;
                background-color: #0077ff;
                border-width: 6px;
                width: 0px;
                height: 25px;
                border-radius: 5px;
                left: 35%;
            }

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 43%;
                background-color: #0077ff;
                width: 6px;
                height: 15px;
                border-radius: 5px;
                transform: rotate(45deg) scale(0) translate(-50%, -50%);
                opacity: 0;
                transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
            }
        }

        &:hover {
            .ant-checkbox-inner {
                border: 2px solid #0077ff;
            }
        }
    }

    .ant-checkbox-checked {
        position: relative;

        &:after {
            border: none;
        }

        &:hover {
            .ant-checkbox-inner {
                border: 2px solid #0077ff;
            }
        }

        .ant-checkbox-inner {
            background-color: #fff;
            border-color: #d9d9d9;

            &::before {
                transform: rotate(-45deg) scale(1) translate(-50%, -50%);
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                opacity: 1;
            }
        }
    }

    &.small-checkbox {
        .ant-checkbox-inner {
            height: 20px;
            width: 20px;
            border-radius: 2px;

            &::after {
                border-width: 3px;
                height: 12px;
                border-radius: 5px;
                left: 30%;
            }

            &::before {
                top: 50%;
                left: 40%;
                width: 3px;
                height: 7px;
            }
        }
    }
}
