@keyframes transitionRightToLeft {
    0% {
        opacity: 0.5;
        transform: translateX(100%);
    }

    80% {
        opacity: 0.8;
        transform: translateX(5%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.qc_wrapper {
    &.question-panel {
        .question-content {
            padding: 0;

            & > .qc_panel > .qc_body {
                padding: 90px 30px 30px 30px;
            }
        }
    }

    .empty_question {
        border-radius: 6px;
    }
}

.qc_body {
    > div {
        animation: transitionRightToLeft 0.3s;
    }

    .question-label {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 4px 8px;
        display: inline-block;
        margin: 10px 0;
        user-select: none;
    }
}

.qc_correct-answers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: #eee;
    padding: 16px;
    border-radius: 10px;
    gap: 8px;

    .correct-answer-item {
        .resultanswer-wrapper {
            padding-right: 16px;

            .resultanswer-content {
                padding-right: 0;
            }
        }
    }
}
