.guide-modal {
    width: 65vw !important;
    .ant-modal-content {
        border-radius: 15px;
        overflow: hidden;
    }
    @media screen and (max-width: 1024px) {
        width: 80vw !important;
    }
}

.guide-modal-wraper {
    margin-top: 20px;
    margin-right: 20px;
    .img-delete-btn {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        background-color: #e5e5e5;
        color: #888;
        max-width: 100px;
        justify-content: center;
        border-radius: 4px;
        &:hover {
            cursor: pointer;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 4px;
        }
    }
}

.guide-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
