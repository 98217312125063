.file-manager-container-modal {
    .ant-modal.app-modal.type-basic {
        .ant-modal-body {
            padding: 0;

            .ant-spin-nested-loading,
            .ant-spin-container {
                height: 100%;
            }
        }
    }

    .ant-modal.app-modal {
        max-height: 550px;

        .ant-modal-content {
            min-width: 500px;
        }
    }
}
