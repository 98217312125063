.modal-graphelemconfig {
    &.ant-modal {
        .formfield-point {
            .ant-form-item-control-input-content {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 8px;
            }

            .ant-form-item.formfield-point-name {
                width: 100%;
            }

            .ant-form-item.formfield-point-coord {
                width: 200px;

                .ant-input-number {
                    width: 100%;
                }
            }
        }

        .formfield-nllbl {
            .ant-form-item-control-input-content {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 8px;
            }

            .html-displayer.formfield-nllbl-label {
                width: 100%;
                min-height: 45px;
                border-radius: 10px;
                background-color: #eee;
                padding: 5px 16px;
                font-size: 16px;
                line-height: 35px;
            }

            .ant-form-item.formfield-nllbl-pos {
                width: 200px;

                .ant-input-number {
                    width: 100%;
                }
            }
        }

        .form-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
        }
    }
}

// .modal-graphelemconfig-wrap {
//     &.ant-modal-wrap {
//     }
// }

@media screen and (max-width: 1399.98px) {
    .modal-graphelemconfig {
        &.ant-modal {
            .formfield-nllbl {
                .html-displayer.formfield-nllbl-label {
                    min-height: 36px;
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
    }
}
