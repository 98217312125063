@use "src/assets/styles/utilities/breakpoint" as *;

.list-course-wrapper {
    width: 100%;
}

.list-header {
    padding-bottom: 30px;
    margin: 0 0px 30px 0px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
    row-gap: 8px;

    .header-title {
        color: #6b60df;
        margin-bottom: 0;
        min-width: 100px;
    }

    .course-list-actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 30px;
        row-gap: 8px;

        justify-content: flex-end;
        column-gap: 8px;
        flex-wrap: wrap;

        .anticon-search.input-search-icon {
            background-color: #8e6cf7;
            color: #fff;
        }

        > div {
            height: 100%;
        }
    }
}

.teacher-courses-list {
    .list-content {
        width: 100%;
    }

    .course-list-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 24px;

        & > span {
            &:first-child {
                font-weight: bold;
            }
        }
    }

    .course-checkbox-filter {
        font-size: 18px;
        font-weight: 700;
        label {
            font-size: 18px;
            line-height: 1.6;
        }
    }

    .course-list-wrapper {
        .course-item-wrapper {
            position: relative;
            height: 100%;
            min-width: 290px;
            transition: transform ease-in-out 0.3s;
            outline: none;
            &:hover {
                transform: translateY(-2px);
                // box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
            }

            .course-item-dropdown-btn {
                position: absolute;
                top: 10px;
                right: 10px;
                height: auto;
                padding: 1px 6px;
                border-radius: 10px;
                z-index: 2;

                > span {
                    display: block;

                    svg {
                        display: block;
                        font-size: 20px;
                    }
                }
            }

            & > .ant-spin-nested-loading,
            & > .ant-spin-nested-loading > .ant-spin-container {
                height: 100%;
            }
        }

        .course-item-loadmore {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 10px;
            min-height: 275px;
            height: 100%;

            .course-btn {
                background: none;
                border: none;
                color: #888;
                font-size: 18px;
                line-height: 35px;
                font-weight: 500;
                cursor: pointer;

                &:hover {
                    color: #555;
                    opacity: 1;
                }
            }
        }
    }
}

.course-list-item-menu {
    .item-btn {
        padding: 5px 12px;

        > div:first-child {
            min-width: 25px;
        }
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        padding: 0;
    }
}

@include break-min(1920px) {
    .student-courses.courses,
    .teacher-courses-list .list-course-wrapper {
        .ant-col {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
}

@media screen and (max-width: 991.98px) {
    .teacher-courses-list {
        .course-list-filter {
            & > span {
                &:first-child {
                    display: none;
                }
            }
        }
    }

    .course-item-loadmore {
        background-color: #fff !important;
        min-height: 150px !important;
        .course-btn .button-title {
            display: block;
            font-size: 16px;
            min-width: 200px;
        }
    }
}

@media screen and (max-width: 1365.98px) {
    .teacher-courses-list {
        // .list-header {
        //     // flex-direction: column;
        //     // justify-content: stretch;
        //     // align-items: stretch;

        //     .course-list-actions {
        //         // justify-content: space-between;
        //         justify-content: flex-end;
        //         column-gap: 8px;
        //         // flex-direction: column;
        //         flex-wrap: wrap;
        //         align-items: flex-start;
        //     }
        // }

        .course-list-filter {
            & > span {
                &:first-child {
                    display: none;
                }
            }
        }
    }
}

@include tablet-down {
    .list-header {
        border-bottom: unset;
        padding-bottom: 0;
        margin-bottom: 12px;
        margin-top: 12px;
        .header-title {
            font-size: 18px;
            line-height: 24px;
            color: #000;
        }
    }
    .teacher-courses-list {
        .list-header {
            .course-list-actions {
                .course-btn {
                    min-width: unset;
                    border-radius: 8px;
                    padding: 7px 10px;

                    & > span:first-child {
                        margin-right: 0;
                    }

                    & > *:nth-child(2) {
                        display: none;
                    }
                }
            }
            .header-title {
                font-size: 18px;
                line-height: 24px;
                color: #000;
            }
        }
    }
}
