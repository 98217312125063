@use 'src/assets/styles/utilities/colors' as c;

.modal-feedback {
    &.ant-modal.app-modal.type-primary {
        .ant-modal-header .ant-modal-title {
            text-align: center;
        }
    }

    .feedback-title {
        font-size: 18px;
        margin-bottom: 16px;
    }

    .feedback-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px 16px;
        padding: 20px;

        &_icon {
            display: inline-block;
            color: c.$success;

            & > .anticon {
                display: block;
            }

            & > .anticon > svg {
                width: 80px;
                height: 80px;
                min-width: 80px;
            }
        }

        &_desc {
            text-align: center;
            font-size: 18px;
        }
    }

    .form-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }
}
