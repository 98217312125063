.help-menu-layout {
    .help-menu-title {
        font-size: 18px;
        font-weight: 700;
        text-align: center;

        color: #303030;
        span {
            height: 40px;
            line-height: 40px;
            display: inline-block;
            background-color: rgba($color: #000000, $alpha: 0.08);
            width: calc(100% - 14px);
            height: calc(100% - 4px);
            border-radius: 5px;
            margin-top: 2px;
        }
    }
    .sider-menu-title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .icon_list {
        font-size: 22px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
            margin-top: 5px;
            background-color: rgba($color: #000000, $alpha: 0.08);
            width: 30px;
            height: 30px;
            border-radius: 5px;
            line-height: 30px;
            text-align: center;
            border: solid 1px #ccc;
            &:hover {
                box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 2px 0px;
            }
        }

        svg {
            z-index: 3;
        }
    }
    .help-menu-bottom {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        font-size: 16px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        color: #303030;
        &:hover {
            background-color: rgba($color: #000000, $alpha: 0.08);
            cursor: pointer;
        }
        svg {
            font-size: 16px;
        }
        span[class="minimize"] {
            margin-left: 10px;
        }
    }
    .ant-layout-sider-zero-width-trigger {
        top: 1px;
        right: -28px;
        width: 26px;
        height: 28px;
        line-height: 28px;
    }
    .ant-layout-sider-zero-width-trigger {
        background-color: #0077ff;
        opacity: 0.6;
    }

    .ant-menu-title-content {
        font-size: 16px;
    }
    .ant-layout-sider-collapsed {
        .ant-menu-item {
            display: none;
        }
        .help-menu-title span {
            display: none;
        }
    }
    .ant-layout {
        background: unset;
        background-color: #f5f5f5;
        height: 100%;
        overflow-y: auto;

        &-header {
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: clamp(18px, calc(26 / 1440 * 100vw), 26px);
            font-weight: 700;
            color: #090a0a;
            opacity: 0.6;
        }
        &-sider-children {
            height: calc(100vh - 134px);
            max-height: calc(1200px - 40px);
            overflow-y: auto;
            overflow-x: hidden;
        }
        &-sider-trigger {
            display: none;
        }
    }
}
