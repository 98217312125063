.ant-form.filter-menu {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        text-align: left;

        > label {
            line-height: 36px;
            width: 100%;
            height: auto;
        }
    }

    .ant-select.app-select {
        .ant-select-selector {
            min-height: auto;

            &::after {
                line-height: 26px;
            }

            .ant-select-selection-placeholder {
                line-height: 26px;
            }

            .ant-select-selection-item {
                font-size: 14px;
                line-height: 26px;
            }
        }
    }

    .ant-picker.app-date-picker {
        width: 100%;
        border-radius: 8px;
    }

    .ant-select.ant-select-multiple.app-select {
        .ant-select-selector {
            padding-top: 2px;
            padding-bottom: 2px;

            .ant-select-selection-overflow {
                min-height: 30px;
            }

            & > .ant-select-selection-overflow .ant-select-selection-item {
                line-height: 24px;
                height: auto;
            }
        }
    }

    .filter-menu-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px 20px;

        .btn {
            font-size: 16px;
            line-height: 24px;
            padding: 8px;
            min-width: 120px;
        }
    }
}

// LAYOUT 1: Default layout is used for "vertical" form:
.ant-form.filter-menu.layout-grid {
    max-width: 500px;
    width: calc(100vw - 85px - 16px - 60px);

    .ant-row {
        max-height: 350px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    & > .ant-row {
        padding: 2px 0;
    }

    .ant-form-item {
        overflow: visible;
        margin-bottom: 0;
    }

    .ant-form-item-label {
        font-weight: 700;

        > label {
            line-height: unset;
        }
    }

    .filter-menu-footer {
        margin-top: 20px;
    }
}

// LAYOUT 2: For "horizontal" form:
.ant-form.filter-menu.form-horizontal {
    .ant-form-item {
        align-items: center;
    }

    .ant-form-item-label {
        > label {
            line-height: 36px;
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 575.98px) {
    .ant-form.filter-menu.layout-grid {
        max-width: unset;
        width: calc(100% - 60px);
        margin: 0 30px;
    }
}
