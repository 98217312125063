@use 'src/assets/styles/utilities/variables' as vars;

/*
 * ----------------------------------------------------------------------------------------------------
 * BE CAREFUL TO MODIFY THIS FILE!
 * ANY CHANGING OF THIS FILE WILL AFFECT MANY OTHER FILES!
 * ----------------------------------------------------------------------------------------------------
 */

// ClassNames:
// - Used for .ant-form:
//   app-form, type-default.

// - Used for .ant-form-item:
//   hide-label.

// - Custom styles can be used for any HTML tag and class names:
//   form-viewer, field-title, field-desc.

// (Ant Design) Form:
.ant-form-item {
    &.hide-label {
        .ant-form-item-label > label {
            display: none;
        }
    }
}

.ant-form-item-label {
    & > label::after {
        display: inline-flex;
        order: 1;
    }

    & > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
        display: inline-flex;
        order: 2;
        margin-left: 4px;
        margin-right: 0;
    }
}

.ant-form.app-form {
    &.type-default {
        // Form items:
        &.ant-form-vertical .ant-form-item-label,
        &.ant-form-horizontal .ant-form-item-label {
            min-width: 200px;
            text-align: left;
            margin-right: 24px;

            & > label {
                font-weight: 700;
                font-size: 18px;
                color: #181818;
                line-height: 45px;
                height: auto;
            }
        }

        // ANT DESIGN - Input:
        .ant-form-item-control-input-content .ant-input {
            border: 1px solid rgba(0, 0, 0, 0.25);
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            border-radius: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 35px;
            padding: 4px 20px;

            &::placeholder {
                color: #636364;
                opacity: 0.5;
                font-style: inherit;
            }
        }

        // ANT DESIGN - Select:
        .ant-select.app-select:not(.ant-select-customize-input) .ant-select-selector {
            border: 1px solid rgba(0, 0, 0, 0.25);
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            border-radius: 10px;
        }

        .ant-select.app-select .ant-select-selector .ant-select-selection-placeholder {
            font-style: normal;
            font-weight: 400;
            color: #636364;
        }

        // ANT DESIGN - DatePicker:
        .ant-picker {
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            border-radius: 10px;
            width: 100%;
            padding: 4px 20px;

            &:not(.ant-picker-status-error) {
                border: 1px solid rgba(0, 0, 0, 0.25);
            }

            .ant-picker-input > input {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 35px;

                &::placeholder {
                    color: #636364;
                    opacity: 0.5;
                }
            }
        }

        // ANT DESIGN - InputNumber:
        .ant-input-number {
            display: block;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.25);
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            border-radius: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 35px;
            padding: 2px 10px;
            overflow: hidden;

            &::placeholder {
                color: #636364;
                opacity: 0.5;
            }
        }

        // Elements:
        .btn-group {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 30px;
            margin-top: 30px;

            .btn {
                flex: 1 1 0%;
                width: 175px;
                max-width: 175px;
            }
        }
    }
}

// Custom styles:
.form-viewer {
    // Layout:
    .form-viewer-section {
        padding: 30px;

        &:not(:first-child) {
            border-top: 1px solid #eee;
        }
    }

    // Items:
    .form-viewer-title {
        font-size: 18px;
        font-weight: 700;
    }

    .form-viewer-desc {
        font-size: 16px;
    }

    .form-viewer-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .item-title {
            font-size: 18px;
            font-weight: 700;
        }

        .item-value {
            font-size: 16px;

            &.skeleton-loading {
                position: relative;
                width: 100%;
                border-radius: 10px;
                overflow: hidden;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;
                }
            }
        }

        &.layout-h {
            flex-direction: row;
        }

        &.layout-v {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .form-viewer-list {
        display: block;
        width: 100%;

        .list-item {
            background-color: #eee;
            border-radius: 10px;
            padding: 8px;

            &:not(:first-child) {
                margin-top: 8px;
            }

            &.skeleton-loading {
                animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;

                * {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            .list-item-title {
                font-weight: 700;
            }

            .list-item-info {
                color: #888;
            }
        }

        .form-viewer-item {
            .item-title {
                font-size: 16px;
            }
        }
    }

    &.layout-horizontal {
        .form-viewer-item:not(.layout-v) {
            flex-direction: row;
        }
    }

    &.layout-vertical {
        .form-viewer-item:not(.layout-h) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 575.98px) {
    .ant-form-item-label {
        & > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
            margin-left: 8px;
        }
    }
}

@media screen and (max-width: 1439.98px) {
    .ant-form.app-form.type-default {
        // Form items:
        &.ant-form-vertical .ant-form-item-label,
        &.ant-form-horizontal .ant-form-item-label {
            & > label {
                line-height: clamp(36px, calc(45 / vars.$w * 100vw), 45px);
            }
        }

        // ANT DESIGN - Input:
        .ant-form-item-control-input-content .ant-input {
            line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
        }

        // ANT DESIGN - Select:
        .ant-select.app-select:not(.ant-select-customize-input) .ant-select-selector {
            min-height: clamp(36px, calc(45 / vars.$w * 100vw), 45px);
        }

        .ant-select.app-select .ant-select-selector .ant-select-selection-placeholder,
        .ant-select.app-select .ant-select-selector .ant-select-selection-item {
            line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
            line-height: clamp(26px, calc(30 / vars.$w * 100vw), 30px);
        }

        // ANT DESIGN - Select (multiple):
        .ant-select.app-select.ant-select-multiple {
            min-height: clamp(36px, calc(45 / vars.$w * 100vw), 45px);

            &:not(.ant-select-customize-input) .ant-select-selector {
                padding-top: 2px;
                padding-bottom: 2px;
            }

            .ant-select-selector:after {
                line-height: 18px;
            }

            .ant-select-selector .ant-select-selection-overflow {
                min-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
            }

            .ant-select-selector .ant-select-selection-placeholder,
            .ant-select-selector .ant-select-selection-item,
            &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
                line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
            }

            .ant-select-selector .ant-select-selection-item {
                line-height: clamp(20px, calc(29 / vars.$w * 100vw), 35px);
            }
        }

        // ANT DESIGN - DatePicker:
        .ant-picker {
            .ant-picker-input > input {
                line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
            }
        }

        // ANT DESIGN - InputNumber:
        .ant-input-number {
            line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
        }

        // Elements:
        .btn-group {
            gap: clamp(16px, calc(30 / vars.$w * 100vw), 30px);
            margin-top: clamp(16px, calc(30 / vars.$w * 100vw), 30px);

            .btn {
                min-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
                max-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .form-viewer {
        .form-viewer-list {
            .form-viewer-item {
                flex-direction: column !important;
                align-items: flex-start;
                row-gap: 0;
            }
        }

        &.layout-horizontal {
            .form-viewer-item:not(.layout-v) {
                flex-direction: column !important;
                align-items: flex-start;
                row-gap: 0;
            }
        }
    }
}
