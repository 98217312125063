.conversation_list_answer {
    width: 100%;

    .answer-with-audio {
        .ant-form-item-control-input-content {
            display: flex;
            .ant-space {
                flex-basis: 100%;
            }
        }
    }

    .ant-row.ant-form-item {
        margin-bottom: 0;
    }
}
