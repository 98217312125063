.question-bank.list-feedback {
    .question-bank-table {
        .ant-table table colgroup col {
            &:nth-child(3) {
                min-width: 400px;
            }
            &:nth-child(5) {
                min-width: 180px;
            }
        }
    }
}
