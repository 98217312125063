.candidate-upload-avatar {
    padding: 0 10px 0px 10px;
    text-align: center;

    &_alert {
        height: auto;
    }

    &_inner {
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        .upload_btn {
            border-radius: 10px;
            overflow: hidden;
            width: 166px;
            height: 196px;
            border: dashed 1px #ccc;
            cursor: pointer;
            text-align: center;
            font-size: 16px;

            &:hover {
                border-color: #6c94fb;
            }

            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &_actions {
        text-align: center;
        .btn-upload {
            min-width: unset;
        }
    }
}
