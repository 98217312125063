@use 'src/assets/styles/utilities/variables' as vars;

.mathchar-btn-list {
    // Layout:
    .ant-tabs-nav-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .ant-tabs-tab {
            margin: 0;
            display: inline-flex;
            justify-content: center;
            padding-left: 10px;
            padding-right: 10px;
            img {
                zoom: 0.8;
            }
        }
    }

    .ant-tabs-nav-operations {
        display: none !important;
    }

    .button-tab {
        // padding: 12px;
        margin-left: -16px;
        margin-right: -16px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 8px;
        column-gap: 5.7px;
        max-width: 400px;
        margin: 0 auto;

        button {
            // padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .button-skeleton {
            width: calc(12.5% - 5px);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    // Elements:
    .mathchar-btn {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        border: 1px solid rgb(141, 152, 167);
        box-shadow: rgb(0 0 0 / 50%) 0px 1px 4px 0px;
        background-color: #fff;
        cursor: pointer;
        &:hover {
            background-color: rgba(0, 0, 0, 0.08);
        }
        &:active {
            box-shadow: none;
        }
        img {
            zoom: 0.9;
            max-width: 100%;
            max-height: calc(100% - 4px);
        }
        &.rotate90 {
            img {
                transform: rotate(90deg);
            }
        }
        &.rotate270 {
            img {
                transform: rotate(270deg);
            }
        }
    }
}

// RESPONSIVE DESIGN:
.mathchar-btn-list {
    .mathchar-btn {
        $w_h: clamp(36px, calc(50 / vars.$w * 100vw), 50px);
        width: $w_h;
        height: $w_h;
    }
}

@media screen and (max-width: 575.98px) {
    .mathchar-btn-list {
        .button-tab {
            .button-row {
                gap: 5px;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
    }
}
