.upload-id-card {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // gap: 30px;
    position: relative;

    &_content {
        overflow: hidden;
        width: 556px;
        height: 330px;
        border-radius: 10px;
        border: dashed 1px #ccc;
        margin-bottom: 10px;

        &:hover {
            border-width: 2px;
            border-color: aqua;
        }

        .upload-btn {
            width: 100%;
            height: 100%;
            cursor: pointer;
            position: relative;
            &_init {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                font-size: 16px;
            }
            &_init-icon {
                font-size: 80px;
                color: #555;
            }
            &_init-desc {
                font-size: 18px;
                color: #555;
            }
        }
        #id-card-img {
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
            border-radius: 10px;
        }

        .delete-img {
            position: absolute;
            bottom: 3px;
            right: 6px;
            min-width: unset;
        }
    }

    &_content.has-image {
        border-width: 2px;
    }
}
