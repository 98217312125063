.basic-header-wrapper {
    padding: 0;

    .header-logo-wrapper {
        display: inline-block;
        user-select: none;
        cursor: pointer;

        & > img {
            width: auto;
            height: 68px;
        }

        &.with-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:hover {
                text-shadow: 0 0 5px rgb(0 0 0 / 20%);
            }
        }
    }

    .app-header {
        position: relative;
        background: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 16px;
        min-height: 57px;
    }

    .act-menu {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 40px;

        .act-menu-item {
            position: relative;

            &:not(:last-child) {
                padding-right: 30px;

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    transform: translate(0, -50%);
                    width: 1px;
                    height: 30px;
                    background-color: #979c9e;
                }
            }
        }
    }

    .language-setting {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 9px;

        & > span:nth-child(2) {
            color: #0047ff;
        }

        & > .ant-dropdown-trigger {
            user-select: none;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .basic-header-wrapper {
        .app-header {
            flex-direction: column;
        }
    }
}
