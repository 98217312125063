.teacher-course-detail {
    .table-student-list {
        .ant-table table {
            width: 100% !important;
        }

        .ant-table .ant-table-expanded-row-fixed {
            width: auto !important;
        }

        .ant-table table colgroup col {
            &:nth-child(1) {
                width: auto !important;
                min-width: 150px;
            }

            &:nth-child(2) {
                width: auto !important;
                min-width: 100px;
            }

            &:nth-child(3) {
                width: auto !important;
                min-width: 180px;
            }

            &:nth-child(4) {
                width: auto !important;
                min-width: 50px;
            }

            &:nth-child(5) {
                width: auto !important;
                min-width: 100px;
            }
        }
    }
}
