.backtop-btn-wrapper {
    position: fixed;
    bottom: 30px;
    right: 18px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    z-index: -1;

    .backtop-btn-content {
        display: block;
        width: 100%;
        height: 100%;
        line-height: 40px;
        text-align: center;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 1px 2px 2px rgb(0, 0, 0, 0.2);
        color: #e51e25;
        font-size: 18px;
        user-select: none;
        cursor: pointer;
        transition: all 0.3s ease, border-radius 0s, transform 0s;
        // transition-delay: 250ms;
        opacity: 0;
        visibility: hidden;

        &:active {
            transform: scale(0.8);
        }
    }

    &.visible {
        z-index: unset;

        .backtop-btn-content {
            opacity: 1;
            visibility: visible;
        }
    }
}
