.learningProgressReport_wrapper {
    // .learningProgressReport_header {
    //     margin: 0;
    // }

    .learningProgressReport_container {
        .card-list {
            display: grid;
            gap: 30px;
            grid-template-columns: repeat(3, 1fr);

            .learning-progress-report-nav-card {
                max-width: unset;
            }
        }
    }
}

// RESPONSIVE:
@media screen and (min-width: 1920px) {
    .learningProgressReport_wrapper {
        .learningProgressReport_container {
            .card-list {
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .learningProgressReport_wrapper {
        margin-top: 15px;
        .learningProgressReport_container {
            .card-list {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .learningProgressReport_wrapper {
        .learningProgressReport_container {
            .card-list {
                grid-template-columns: 1fr;
            }
        }
    }
}
