.textarea-editor {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;

    &::after {
        content: "\a0";
        display: inline-block;
        width: 0;
        visibility: hidden;
        margin-left: -10px;
    }

    .textarea-editor__editor {
        width: 100%;
    }

    .textarea-editor__suffix {
        cursor: pointer;
        width: 30px;

        &:hover {
            color: red;
            opacity: 0.7;
        }
    }

    &--disabled {
        cursor: not-allowed;

        .mce-content-body {
            background-color: #cdcfd0;
            pointer-events: none;
        }
    }
}
