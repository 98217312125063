/*
 * Global class names:
 * _
 */

.q-highlight {
    .q-content-section {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .highlight_btn {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 8px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        background-color: #fff;
        cursor: pointer;
        color: red;
        width: 36px;
        height: 36px;

        &:active {
            transform: scale(0.97);
        }

        &:hover {
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
        }

        &:disabled {
            // background: #ddd;
            display: inline-block;
            cursor: default;
            margin: 0 4px;
            background-clip: border-box;

            &:active {
                transform: none;
            }
        }

        &.active {
            background: #ddd;
        }
    }

    .question-editor-hint-text {
        font-size: 14px;
        font-weight: normal;
        font-style: italic;
    }

    .content-highlight {
        max-height: 300px;
        overflow-y: auto;
        // textarea {
        //     text-align: left;
        //     font-weight: 400;
        //     font-size: 18px;
        //     line-height: 35px;

        //     &::placeholder {
        //         font-style: italic;
        //     }
        // }
    }
}
