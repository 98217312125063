.modal-publicasgmtusers-detail {
    &.ant-modal {
        .form-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
        }
    }
}

// .modal-publicasgmtusers-detail-wrap {
//     &.ant-modal-wrap {
//         z-index: 1021;
//     }
// }
