@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

// ClassNames: btn, has-rd.

// Variables:
$bgcolor-primary: linear-gradient(180deg, #3f6fea 0%, #6c94fb 100%);
$bgcolor-normal: linear-gradient(180deg, #fb3b50 0%, #f54e62 100%);

// Mixins:
@mixin btn-type-normal {
    &:hover {
        background: none;
        background-color: #ffd00d;
    }
}

.btn {
    outline: none;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    padding: 7.5px 10px;
    min-width: 175px;
    min-height: 40px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    }

    &:active {
        transform: scale(0.97);
    }

    @media (hover: hover) {
        &:hover {
            filter: opacity(0.8);
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
    }

    &__icon-wrapper {
        display: block;
        margin-right: 10px;

        img {
            display: block;
            width: auto;
            height: 18px;
            max-width: 18px;
        }

        svg {
            display: block;
            width: auto;
            height: 18px;
            max-width: 18px;
        }

        & > .anticon,
        & > .anticon > svg {
            display: block;
        }
    }

    // Types:
    &.btn-simple {
        padding: 0;
        min-width: unset;
        background: none;

        &:hover {
            box-shadow: none;
            color: #6c94fb;
        }
    }

    &.btn-normal {
        background: $bgcolor-normal;
        @include btn-type-normal;
        color: #fff;
    }

    &.btn-grey {
        background-color: #e5e5e5;
        color: #888;

        .btn__content {
            & > div:nth-child(2) {
                color: #000;
                opacity: 0.6;
            }
        }

        .btn__icon-wrapper {
            img {
                filter: grayscale(100%);
            }

            svg {
                fill: rgba($color: #000000, $alpha: 0.6);
            }
        }

        &:hover {
            color: #555;
        }
    }

    &.btn-primary {
        background: $bgcolor-primary;
        @include btn-type-normal;
        color: #fff;

        .btn__icon-wrapper {
            svg {
                fill: #fff;
            }
        }
    }

    &.btn-ghost {
        padding: 6.5px 9px;
        background-color: transparent;
        background: linear-gradient(180deg, #3f6fea 0%, #6c94fb 100%);
        background-clip: text;
        border: 1px solid #6c94fb;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // text-fill-color: transparent;

        .btn__icon-wrapper {
            svg {
                fill: #6c94fb;
            }
        }

        &:hover {
            background-clip: border-box;
            border: 1px solid transparent;
            -webkit-background-clip: border-box;
            -webkit-text-fill-color: #fff;
            // text-fill-color: #fff;

            .btn__icon-wrapper {
                svg {
                    fill: #fff;
                }
            }
        }
    }

    // Others:
    &.btn-icon-only {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        .btn__content {
            width: 30px;
            height: 30px;
            background-color: #ddd;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .btn__icon-wrapper {
            margin-right: 0;
        }
    }

    // DISABLED.
    &.disabled,
    &:disabled {
        background: none;
        background-color: #cdcfd0;
        color: #979c9e;
        box-shadow: none;
        pointer-events: none;
        cursor: auto;
    }

    &.btn-simple {
        &.disabled,
        &:disabled {
            background: none;
            color: #cdcfd0;

            .btn__icon-wrapper {
                svg {
                    fill: #cdcfd0;
                }
            }
        }
    }

    &.btn-ghost {
        &.disabled,
        &:disabled {
            background: none;
            color: #cdcfd0;
            background-clip: unset;
            border: 1px solid #cdcfd0;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;

            .btn__icon-wrapper {
                svg {
                    fill: #cdcfd0;
                }
            }
        }
    }

    &.btn-second {
        background-color: colors.$second;
        color: colors.$white;
        .btn__icon-wrapper {
            svg {
                fill: colors.$white;
            }
        }
    }
}

// RESPONSIVE DESIGN:
.has-rd {
    @media screen and (max-width: 1399.98px) {
        &.btn {
            font-size: 16px;
            line-height: 18px;
            min-height: 36px;
        }
    }
}
