@use 'src/assets/styles/utilities/variables' as vars;

.ant-modal.modal-add-course {
    $modal-width: 940px;

    // Form layout:
    & > div.ant-modal-content {
        width: $modal-width;
        max-width: 100%;
        position: relative;
        background-color: #ffffff;
        box-sizing: border-box;
        border-radius: 15px;
    }

    .ant-modal-header {
        border-radius: 15px 15px 0 0;
        border: none;
        padding: 30px;

        .ant-modal-title {
            font-weight: 700;
            font-size: 26px;
            line-height: 35px;
            text-align: left;
            color: #000000;
        }
    }

    .ant-modal-close {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: #fff;
        filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
        border-radius: 5px;
        overflow: hidden;

        &:hover {
            opacity: 0.5;
        }

        .ant-modal-close-x {
            position: relative;
            width: 100%;
            height: 100%;
            display: inline-block;
            display: flex;
            justify-content: center;
            align-items: center;
            fill: #000000;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #c4c4c4;
                opacity: 0.2;
                z-index: -1;
            }
        }
    }

    // Form items:
    .ant-form-horizontal .ant-form-item-label {
        min-width: 200px;
        text-align: left;
        margin-right: 24px;

        & > label {
            font-weight: 700;
            font-size: 18px;
            color: #181818;
            line-height: 45px;
            height: auto;
        }
    }

    // ANT DESIGN - Input:
    .ant-form-item-control-input-content {
        > .ant-input-affix-wrapper,
        > .ant-input {
            border: 1px solid rgba(0, 0, 0, 0.25);
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            border-radius: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 35px;
            padding: 4px 20px;

            &::placeholder {
                color: #636364;
                opacity: 0.5;
                font-style: inherit;
            }
        }
    }

    // ANT DESIGN - Select:
    .ant-select.app-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid rgba(0, 0, 0, 0.25);
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
    }
    .ant-select.app-select .ant-select-selector .ant-select-selection-placeholder {
        font-style: normal;
        font-weight: 400;
        color: #636364;
    }

    // ANT DESIGN - DatePicker:
    .ant-picker {
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
        width: 100%;
        padding: 4px 20px;

        &:not(.ant-picker-status-error) {
            border: 1px solid rgba(0, 0, 0, 0.25);
        }

        .ant-picker-input > input {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 35px;

            &::placeholder {
                color: #636364;
                opacity: 0.5;
            }
        }
    }

    // ANT DESIGN - InputNumber:
    .ant-input-number {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.25);
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 35px;
        padding: 2px 10px;

        &::placeholder {
            color: #636364;
            opacity: 0.5;
        }
    }

    // Elements:
    .course-bgimage-field {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 8px;

        .ant-form-item {
            width: 100%;
        }

        .btn {
            min-width: auto;
            padding: 4px 20px;
            margin-bottom: 24px;
            border: 1px solid transparent;

            .btn__icon-wrapper,
            .anticon,
            svg {
                display: block;
            }

            svg {
                display: block;
            }
        }
    }

    .btn-group {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 30px;

        .btn {
            flex: 1 1 0%;
            width: 175px;
            max-width: 175px;
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1439.98px) {
    .ant-modal.modal-add-course {
        .ant-modal-header {
            padding-bottom: clamp(24px, calc(30 / vars.$w * 100vw), 30px);

            .ant-modal-title {
                font-size: clamp(22px, calc(26 / vars.$w * 100vw), 26px);
                line-height: clamp(24px, calc(35 / vars.$w * 100vw), 35px);
            }
        }

        // Form items:
        .ant-form-horizontal .ant-form-item-label {
            & > label {
                line-height: clamp(36px, calc(45 / vars.$w * 100vw), 45px);
            }
        }

        // ANT DESIGN - Input:
        .ant-form-item-control-input-content {
            .ant-input,
            .ant-input-affix-wrapper {
                line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
            }
        }

        // ANT DESIGN - Select:
        .ant-select.app-select:not(.ant-select-customize-input) .ant-select-selector {
            min-height: clamp(36px, calc(45 / vars.$w * 100vw), 45px);
        }

        .ant-select.app-select .ant-select-selector .ant-select-selection-placeholder {
            line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
            line-height: clamp(26px, calc(30 / vars.$w * 100vw), 30px);
        }

        // ANT DESIGN - DatePicker:
        .ant-picker {
            .ant-picker-input > input {
                line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
            }
        }

        // ANT DESIGN - InputNumber:
        .ant-input-number {
            line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
        }

        // Elements:
        .course-bgimage-field {
            .btn.btn-icon-only .btn__content {
                width: auto;
                height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
            }
        }

        .btn-group {
            gap: clamp(16px, calc(30 / vars.$w * 100vw), 30px);
            margin-top: clamp(16px, calc(30 / vars.$w * 100vw), 30px);

            .btn {
                min-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
                max-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .ant-modal.ant-modal.modal-add-course {
        & > div.ant-modal-content {
            width: 100%;
        }
    }
}
