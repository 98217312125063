.candidate-information {
    .candidate-info-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        margin-bottom: 32px;

        .title-icon {
            .anticon {
                display: block;
                font-size: 36px;
            }
        }

        .title-content {
            .content-main {
                font-size: 32px;
                line-height: 36px;
                font-weight: bold;
            }

            .content-descr {
                margin-top: 8px;
            }
        }
    }

    .ant-spin-nested-loading > div > .ant-spin {
        max-height: unset;
    }
}
