.drawing-image {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 10px 8px 20px 8px;
    min-width: 540px;

    .canvas-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: #f5f5f5;
        border-width: 2px;
        border-style: dashed;
        border-color: #d9d9d9;

        .canvas-inner {
            overflow: auto;
        }

        &-img {
            background-color: #f5f5f5;
            border-width: 2px;
            border-style: dashed;
            border-color: #d9d9d9;
            margin: 20px;
            position: relative;
        }
    }
}
