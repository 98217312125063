.modal-contactus {
    &.ant-modal {
        .form-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
        }
    }
}

.modal-contactus-wrap {
    &.ant-modal-wrap {
        z-index: 1020;
    }
}
