.questionpanel__multiplechoicetable__table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;

    td,
    th {
        // border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    tr {
        background-color: #fff;
    }

    .ant-input {
        min-width: 150px;
    }

    .multiplechoicetable__table-skin {
        position: relative;
        overflow: auto;
        white-space: nowrap;
        max-height: 228px;

        &::-webkit-scrollbar-track {
            background: #fff;
        }
    }

    .multiplechoicetable__table__skeleton {
        width: 100%;
    }

    .sticky-row {
        position: -webkit-sticky;
        top: 0;
        z-index: 3;
        position: sticky;
        background-color: white;

        > td {
            text-align: center;
        }
    }

    .multiplechoicetable_item {
        max-width: 400px;
        overflow-x: auto;
        overflow-y: hidden;
        margin: 0 5px;
    }

    .sticky-col {
        position: -webkit-sticky;
        position: sticky;
        background-color: white;
    }

    .text-center {
        text-align: center;
    }

    .first-col {
        padding: 0;
        z-index: 1;
        // box-shadow: 5px 10px 18px #888888;
        background-color: #fff;
        width: 35%;
        // width: 100%;
        // min-width: 100px;
        // max-width: 100px;
        left: 0px;
        height: 0;
        border: none;

        div {
            height: 100%;
            // border: 1px solid #ddd;
            padding: 8px;
            min-width: 200px;
        }
    }
}
