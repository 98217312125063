.langlist-menu {
    .langlist-menu-item {
        display: flex;
        align-items: center;

        .lang-icon {
            width: 30px;
            height: auto;
            margin-right: 8px;

            & > svg {
                display: block;
            }
        }
    }
}
