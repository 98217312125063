@use 'src/assets/styles/utilities/colors' as c;

.modal-displayschk {
    .check-info-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        color: c.$danger;
    }
}
