@use 'src/assets/styles/utilities/colors' as c;

.image-with-labels-wrapper {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 16px 8px;
}

.image-with-labels-tools {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
}

.image-with-labels-configs {
    margin-bottom: 16px;

    .config-imgsize {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .app-input {
            width: 120px;
        }

        input {
            text-align: center;
        }
    }
}

.image-with-labels {
    position: relative;
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    background-color: #f5f5f5;
    border-width: 2px;
    border-style: dashed;
    border-color: #d9d9d9;
    border-radius: 10px;
    padding: 8px;
    margin: 0px auto;
    user-select: none;
    overflow: auto;

    .image-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba(0, 0, 0, 0.5);
    }

    .image-src-wrapper {
        position: relative;
        display: inline-flex;
        // justify-content: center;
        align-items: center;
        border: 1px solid #d9d9d9;
        margin: auto;
        // resize: both;
        // overflow: auto;

        &.empty,
        &.empty .image-src {
            width: 100%;
            height: 100%;
        }
    }

    .image-src {
        position: relative;
        display: block;
        margin: auto;
        // resize: both;
        // overflow: auto;

        img {
            display: block;
            margin: auto;
            min-width: 22px;
            min-height: 22px;
            width: 100%;
            height: auto;
        }
    }

    .image-resizer {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10px;
        height: 10px;
        cursor: nwse-resize;

        &.bottom-right {
            bottom: 0;
            right: 0;

            .image-resizer-icon {
                position: relative;
                width: 100%;
                height: 100%;

                &::after {
                    content: "";
                    position: absolute;
                    top: -8px;
                    left: -8px;
                    width: 16px;
                    height: 16px;
                    border-right: 3px solid #000;
                    border-bottom: 3px solid #000;
                    border-bottom-right-radius: 5px;
                    box-shadow: -2px -2px 0 0 #fff inset;
                    z-index: 2;
                    cursor: nwse-resize;
                }
            }
        }

        &:hover {
            .image-resizer-icon {
                &::after {
                    content: "";
                    border-color: c.$primary;
                }
            }
        }
    }
}

.image-with-labels.resizing {
    pointer-events: none;

    .image-resizer {
        pointer-events: initial;
    }
}
