@use 'src/assets/styles/utilities/colors' as c;

.microphone-check {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;

    .result-display {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 200px;

        .micro-icon {
            .anticon {
                font-size: 32px;
                margin-bottom: 16px;
            }
        }

        .voice-check-displayer {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 12px;
            width: 100%;
            max-width: 350px;
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.1);
            overflow: hidden;

            & > canvas {
                width: 100%;
            }

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 14%;
                transform: translate(0, -50%);
                width: 2px;
                height: 100%;
                background-color: c.$success;
            }
        }
    }

    .check-text {
        text-align: center;

        .check-title {
            font-size: 26px;
            line-height: 32px;
        }

        .check-result-info {
            margin-top: 8px;
        }

        .check-result-message {
            .result-tag {
                display: inline-block;
                background-color: #aaa;
                color: #fff;
                padding: 1px 8px;
                border-radius: 4px;

                &.danger {
                    background-color: c.$danger;
                }

                &.success {
                    background-color: c.$success;
                }
            }
        }

        .check-result-desctiption {
            max-width: 350px;
            font-style: italic;
            margin-top: 4px;

            &.danger {
                color: c.$danger;
            }
        }
    }
}
