.imglbl-drg-wrapper {
    position: relative;
    width: 100%;

    .imglbl-drg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        cursor: grab;

        .drg-wrapper {
            position: relative;

            *:not(.drg-zone) {
                pointer-events: none;
                touch-action: none;
                user-select: none;
            }

            .drg-zone {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            .drg-content {
                box-shadow: -5px 5px 5px 0px rgb(0 0 0 / 20%);
                border-radius: 10px;
                overflow: hidden;
            }
        }

        &.react-draggable-dragging {
            cursor: grabbing;
        }
    }
}
