.exam-chart-controller-wrapper {
    position: relative;

    .control-menu {
        margin-top: 20px;
        text-align: center;
    }
}

.exam-chart-controller-wrapper.with-y-label {
    padding: 0 50px;

    .y-label {
        position: absolute;
        font-weight: 400;
        font-size: 16px;
        line-height: 35px;
        color: rgba(64, 64, 69, 0.5);
        text-transform: uppercase;
        transition: all 1s ease;
        min-width: 400px;
        text-align: center;

        &.y-label-left {
            top: 50%;
            left: 0;
            transform-origin: center center;
            transform: translate(-180px, -50%) rotateZ(-90deg);
        }

        &.y-label-right {
            top: 50%;
            right: 0;
            transform-origin: center center;
            transform: translate(180px, -50%) rotateZ(-90deg);
        }
    }
}
