@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as c;

.subscription-plan-card {
    $borderColor: #e8ecf5;

    .card-subsc-plan {
        position: relative;
        border: 1px solid $borderColor;
        overflow: hidden;

        .card-wrapper {
            position: relative;
            width: 264px;

            .card-header {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-bottom: 1px solid $borderColor;
                background-color: #ffffff;

                .header-title {
                    width: 100%;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 36px;
                    letter-spacing: 0em;
                    text-align: center;
                    padding: 20px 20px 16px 20px;
                    color: c.$primary;

                    &.display-as-placeholder {
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                    }
                }
            }

            .card-price {
                position: relative;
                text-align: center;
                margin-bottom: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 184px;

                .price-current {
                    position: relative;
                    display: inline-block;
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 36px;
                    letter-spacing: 0em;
                    text-align: justified;
                    color: #090a0a;
                    z-index: 1102;
                }

                .price-current .price-prefix {
                    position: absolute;
                    font-size: 18px;
                    top: 14px;
                    left: -32px;
                    line-height: 18px;
                    display: block;
                    font-weight: normal;
                }

                .price-current .price-value {
                    margin-right: 4px;

                    &.display-as-placeholder {
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                    }
                }

                .price-current .price-unit {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 26px;
                    letter-spacing: 0em;

                    &.display-as-placeholder {
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                    }
                }

                .price-current .price-for {
                    display: block;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 36px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #090a0ab2;

                    &.display-as-placeholder {
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: auto;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: calc(100% - 16px);
                    background-color: #f6f8fe;
                    z-index: 1101;
                }

                &.free {
                    &::after {
                        display: none;
                    }
                }
            }

            .card-price-list {
                margin-bottom: 16px;

                &.ant-select {
                    width: auto;
                }

                &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                    height: unset;
                    border: none;
                    padding: 0;
                }

                &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
                &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
                    padding-right: 32px;
                }

                .card-price {
                    display: inline-flex;
                    margin-bottom: 0;
                }
            }

            .card-actions {
                width: 100%;
                padding: 0 16px 0 16px;
                margin-bottom: 20px;

                .btn {
                    width: 100%;
                }

                .display-as-placeholder {
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                }
            }
        }
    }

    ul.content-list {
        position: relative;
        list-style-type: none;
        margin: 0;
        padding: 0;

        & > li {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            padding: 18px 24px;
            background-color: #ffffff;

            & > span {
                display: block;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $borderColor;
            }

            &.display-as-placeholder {
                & > span {
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                }
            }
        }
    }
}

.subscription-plan-card.as-title {
    $borderColor: #e8ecf5;

    .card-subsc-plan {
        .card-wrapper {
            .card-header {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                border-bottom: 1px solid $borderColor;
                height: 221px;
                padding: 16px;

                .header-title {
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 36px;
                    letter-spacing: 0em;
                    text-align: left;
                    padding: 0;
                    color: c.$primary;
                    margin-bottom: 12px;
                }

                .header-title-desc {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                }
            }
        }
    }

    ul.content-list {
        & > li {
            justify-content: flex-start;
            background-color: #2b75fa;
            color: #ffffff;
        }
    }
}

.subscription-plan-card.skeleton {
    .card-subsc-plan {
        .card-wrapper {
            .card-header {
                position: relative;

                .header-title {
                    z-index: 1102;
                }

                &::before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: hsl(200, 20%, 70%);
                    animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;
                    z-index: 1101;
                }
            }

            .card-price {
                z-index: 1102;
            }

            .card-actions {
                z-index: 1102;
            }
        }
    }

    ul.content-list {
        position: relative;

        & > li {
            position: relative;

            & > span {
                z-index: 1102;
            }

            &::before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: hsl(200, 20%, 70%);
                animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;
                z-index: 1101;
            }
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .subscription-plan-card.as-title {
        $borderColor: #e8ecf5;

        .card-subsc-plan {
            .card-wrapper {
                .card-header {
                    height: 217px;
                }
            }
        }
    }
}
