@use 'src/assets/styles/utilities/colors' as c;

.authed-role-picker {
    min-height: calc(100vh - 108px);
    display: flex;
    justify-content: center;
    align-items: center;

    .role-wrapper {
        width: 100%;
        max-width: 560px;
        text-align: center;
        padding: 0 30px;

        & > h1 {
            font-size: 36px;
            color: c.$primary1;
        }
    }

    .role-items {
        justify-content: center;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
