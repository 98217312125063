.detail_phonetic_table {
    margin: auto;
    width: 600px;
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;

    @keyframes fadeIn {
        from {
            opacity: 0.5;
            transform: translateY(-30px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    td,
    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    tr {
        background-color: #fff;
    }

    .text-center {
        text-align: center;
    }

    .animation-fadeIn {
        animation: fadeIn 0.3s ease-out;
    }

    .ant-input {
        min-width: 150px;
    }

    .phonetictable__table__skeleton {
        width: 100%;
    }

    .multiplechoicetable__correct_answer__input {
        min-width: 160px;
        // max-height: 40px;
    }

    .phonetictable__table-skin {
        max-height: 348px;
        overflow: hidden;
        position: relative;
        overflow: auto;
        white-space: nowrap;

        &::-webkit-scrollbar-track {
            background: #fff;
        }
    }

    .sticky-row {
        position: -webkit-sticky;
        top: 0;
        z-index: 3;
        position: sticky;
        background-color: white;

        > td {
            padding: 0;
            height: 0;
            border: 0;
        }

        > td > div {
            height: 100%;
            padding: 8px;
            border-color: #ddd;
            border-style: solid;

            border-top-width: 1px;
            border-bottom-width: 1px;
            // border-top: 1px;
            border-left-width: 0px;
            border-right-width: 1px;
        }

        .sticky-col.first-col > div {
            border: 1px solid #ddd;
            // border-bottom-width: 0;
            // border-left-width: 0;
            border-right-width: 2px;
        }
    }

    .sticky-col {
        position: -webkit-sticky;
        position: sticky;
        background-color: white;

        border: none;
    }

    .first-col {
        padding: 0px;
        z-index: 1;
        // box-shadow: 5px 10px 18px #888888;
        background-color: #fff;
        width: 35%;
        // min-width: 100px;
        // max-width: 100px;

        left: 0px;
        height: 0;
        // border: none;

        > div {
            height: 100%;

            padding: 8px;
            border: 0;

            &:nth-child(odd) {
                border-color: #ddd;
                border-style: solid;

                border-top-width: 0px;
                border-bottom-width: 1px;
                // border-top: 1px;
                border-left-width: 1px;
                border-right-width: 1px;
            }
        }
    }

    .title-col {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fill-blank-drag-drop__correct_answer__delete {
        margin: 0 6px;
    }

    .ant-input-suffix {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ddd;
        padding: 0 6px;

        svg {
            transition: color 0.2s ease-out;
        }

        &:hover {
            color: red;
        }
    }

    .add_button {
        font-size: 25px;
        // padding-bottom: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        color: #fff;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        outline: none;
        border: none;
        cursor: pointer;
    }

    .audio-item {
        display: flex;
        justify-content: space-between;
        min-height: 22px;
        align-items: center;

        > span {
            cursor: pointer;
        }
    }
}
