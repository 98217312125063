@use 'src/assets/styles/utilities/colors' as c;

.feedback_drawer {
    .feedback-title {
        text-align: center;
    }
    .feedback_input {
        background-color: #eee;
        font-size: 16px;
        line-height: 1.6;
        border-radius: 20px;
        overflow-x: hidden;

        &::-webkit-scrollbar-track {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            // border-bottom-right-radius: 10px;
            // border-top-right-radius: 10px;
        }
    }
    .form-actions {
        text-align: center;
        margin-top: 50px;
    }

    .feedback-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px 16px;
        padding: 20px;

        &_icon {
            display: inline-block;
            color: c.$success;
            background: #f7f3f3;
            border-radius: 60%;

            & > .anticon {
                display: block;
                margin: -2px;
            }

            & > .anticon > svg {
                width: 80px;
                height: 80px;
                min-width: 80px;
            }
        }

        &_desc {
            text-align: center;
            font-size: 18px;
        }
    }

    .ant-drawer-content-wrapper {
        width: auto;
        height: auto;
        border-radius: 20px;
        overflow: hidden;
        margin: 20px;
        background: rgba(247, 248, 249, 0.66);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
    }

    &.ant-drawer-right .ant-drawer-content-wrapper {
        height: auto;
    }
    .ant-drawer-content,
    .ant-drawer-header {
        background-color: transparent;
    }
    .ant-drawer-body {
        // background-color: #f1e8e8;
        margin: 40px;
        margin-top: 15px;
        border-radius: 20px;
        height: auto;
        padding-bottom: 15px !important;
    }

    @media screen and (max-width: 576px) {
        .ant-drawer-content-wrapper {
            margin: 10px;
        }
        .ant-drawer-body {
            margin: 16px;
        }
    }
}
