@use 'src/assets/styles/utilities/variables' as vars;

.app-input-latex.app-input-latex--fillblanks {
    .mq-root-block {
        white-space: normal !important;
    }

    .mq-math-mode .mq-paren {
        line-height: calc(100% - 0.2em);
    }
}
