@use 'src/assets/styles/utilities/variables' as vars;

.reportStudent_wrapper {
    // padding: 30px;

    .filterButton {
        padding: 0 4px;

        .tags_button {
            height: 100%;

            .btn__icon-wrapper {
                display: block;
                height: 29px;

                & > svg {
                    display: block;
                    height: 100%;
                }
            }
        }
    }

    .reportStudent_box {
        margin-top: 14px;
    }

    .reportStudent_filter {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        min-height: 47px;
    }

    .tags {
        padding: 0 4px;
        display: flex;
        margin-left: auto;
        flex-wrap: wrap;
        justify-content: flex-end;

        .tag_item_inner {
            margin-top: 8px;
            padding: clamp(8px, calc(8 / 1440 * 100vw), 12px) clamp(10px, calc(14 / 1440 * 100vw), 14px);
            background: #d9d9d9;
            border-radius: 10px;
        }
    }

    .reportStudent_filter_inner {
        margin-top: 8px;
        display: flex;
        justify-content: flex-end;
    }

    .reportStudent_item {
        display: flex;
        margin-top: 12px;
    }

    .reportStudent_chartPie_wrapper {
        padding: 10px;
    }

    .reportStudent_chartPie {
        min-width: 320px;
        background-color: #fff;
        padding: 50px 20px;
        border-radius: 10px;
        height: 100%;

        .reportStudent_title {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 50px;
        }
    }

    .reportStudent_table_wrapper {
        padding: 10px;
        width: 100%;
        .reportStudent_table_inner {
            min-height: 100%;
            padding: 35px 30px 27px 30px;
            background-color: #fff;
            border-radius: 10px;
        }
    }

    .userBoard_wrapper {
        width: 340px;
    }

    @media screen and (max-width: 500px) {
        .reportStudent_chartPie {
            min-width: auto;
        }
    }
}

.ant-modal.reportStudent_modal {
    width: 100% !important;

    .ant-modal-close,
    .ant-modal-footer {
        display: none;
    }
}

.progress-filter {
    max-width: 80%;
}

// RESPONSIVE:
@media screen and (max-width: 1439.98px) {
    .reportStudent_wrapper {
        .filterButton .tags_button {
            min-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
            max-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
            font-size: 16px;
            padding: 4px 8px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .reportStudent_item {
        flex-wrap: wrap;
        > div {
            flex-basis: 100%;
        }
    }
}
