@use "src/assets/styles/utilities/breakpoint" as *;

.course-assignment-info-panel {
    position: relative;
    height: 100%;
    cursor: pointer;

    .course-assignment-info {
        position: relative;
        background-color: #ffffff;
        box-shadow: 0px 5px 15px rgb(0 0 0 / 10%);
        border-radius: 15px;
        height: 100%;
        min-height: 200px;
        overflow: hidden;

        &:hover {
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
        }

        &::before {
            background: #445cfe;
            background: linear-gradient(180deg, rgb(251, 59, 80) 0%, rgb(245, 78, 98) 100%);
            border-radius: 50%;
            content: "";
            height: 100px;
            left: -45px;
            position: absolute;
            top: -45px;
            width: 100px;
        }
    }

    .assignment-quick-info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: clamp(10px, calc(20 / 1920 * 100vw), 20px);
        height: 100%;
        padding: 40px 16px 16px 16px;
        @media screen and (min-width: 1920px) {
            padding: 50px 30px 30px 30px;
        }

        .assignment-icon {
            .icon-wrapper {
                min-width: 50px;
                width: 50px;
                height: 50px;
            }

            .icon-wrapper > img {
                width: 100%;
                height: 100%;
            }

            .icon-wrapper > svg {
                display: block;
            }
        }

        .assignment-label,
        .assignment-title {
            color: #263238;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;

            .label-content {
                font-size: 26px;
                line-height: 28px;
            }

            @media screen and (min-width: 1920px) {
                .label-content {
                    font-size: 32px;
                    line-height: 40px;
                }
                .title-content {
                    font-size: 24px;
                    line-height: 30px;
                }
            }
        }

        .assignment-title {
            font-weight: 400;
            word-break: break-word;
        }
    }

    .assignment-actions {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    .course-assignment-label-range {
        position: absolute;
        top: 0;
        left: 0px;
        width: 60px;
        height: 60px;
        z-index: 10;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(-6px, -6px);
        font-weight: 700;
        font-size: 18px;
        padding: 4px;
    }

    @include tablet-down {
        .course-assignment-info {
            min-height: 100px;
            border-radius: 8px;
        }

        .assignment-quick-info {
            padding: 25px 16px 16px 16px;
            // display: unset;
            .assignment-label {
                font-weight: 700;
                // color: #ffd00d;
                .label-content {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .assignment-title {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                margin-top: 12px;
                word-break: break-word;
            }
            .app-txt-h2 {
                line-height: 16px;
            }
        }
        .course-assignment-label-range {
            width: 30px;
            height: 30px;
            transform: translate(2px, 2px);
            font-size: 12px;
        }
        .course-assignment-info {
            &::before {
                height: 60px;
                left: -25px;
                top: -25px;
                width: 60px;
            }
        }
        .assignment-actions {
            top: 2px;
            right: 6px;
        }
    }
    @include small-mobile {
        .assignment-quick-info {
            .assignment-label {
                .label-content {
                    font-size: 14px;
                }
            }
            .assignment-title {
                font-size: 12px;
                line-height: 16px;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                margin-top: 10px;
            }
        }
        .course-assignment-info {
            &::before {
                height: 48px;
                left: -20px;
                top: -20px;
                width: 48px;
            }
        }
        .course-assignment-label-range {
            transform: translate(-2px, -2px);
            font-size: 10px;
        }
    }
}
