.video-with-violation {
    display: flex;
    justify-content: center;

    &_right {
        flex-grow: 1;
        // height: 64vh;
        padding-top: 38px;
    }

    &_left {
        height: 100%;
        flex: 20%;
    }
}
