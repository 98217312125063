@use "src/assets/styles/utilities/breakpoint" as *;

.course-info-panel {
    .course {
        &-title {
            font-size: 18px;
            font-weight: 700;
            line-height: 1.5;
            color: rgba(0, 0, 0, 0.85);
            // transition: color 0.5s ease;
            cursor: pointer;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            // &:hover {
            //     color: #6b60df;
            // }
        }
        &-item {
            box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            overflow: hidden;
            height: 100%;
            // transition: transform ease-in-out 0.3s;
            // &:hover {
            //     transform: translateY(-2px);
            //     box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
            // }
            &--finish {
                position: relative;
                .course-title,
                .course-info,
                .course-info__item,
                .text-bold {
                    color: rgba(38, 50, 56, 0.8);
                }
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: rgba(38, 50, 56, 0.2);
                    z-index: 2;
                }
                .ant-progress-bg {
                    background-color: rgba(38, 50, 56, 0.3) !important;
                    background-image: unset !important;
                    &::after,
                    &::before {
                        display: none !important;
                    }
                }
                .ant-card-actions button {
                    border-color: #979c9e;
                    color: #979c9e;
                    background: linear-gradient(#979c9e, #979c9e);
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            div.ant-card-cover {
                display: block;
                // height: 160px;
                // height: calc(3 / 8) * 100%;
                > div {
                    padding-bottom: calc((2 / 5) * 100%);
                    width: 100%;
                    position: relative;
                }
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                .card-link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                .no-img-wrapper {
                    display: block;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 0.2;
                    & > img {
                        width: clamp(96px, calc(120 / 1440 * 100vw), 180px);
                        height: clamp(96px, calc(120 / 1440 * 100vw), 180px);
                    }
                }
            }
            .course-body {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 20px;
            }
            .ant-card {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background-color: #fcfcfc;
            }
            .ant-card-body {
                flex: 1;
                padding: 0;
            }
            .card-img-mobile {
                display: none;
            }
        }
        &-info {
            color: rgba(0, 0, 0, 0.85);
            span.text-bold {
                font-weight: 700;
                margin-right: 4px;
            }
            &__item {
                // white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 6px;
            }
        }
        &-progress {
            margin-top: 35px;
            &__info {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .ant-progress-inner {
                background-color: #fff;
                overflow: unset;
            }
            .ant-progress-bg {
                height: 10px !important;
                max-width: 100%;
                &::after {
                    content: "";
                    display: block;
                    height: 16px;
                    width: 16px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 2;
                    right: -4px;
                    bottom: 0;
                    background-color: #fff;
                    border: solid 4px #f54c60;
                    border-radius: 50%;
                }
            }
            .ant-progress.hide-progressbar-dot {
                .ant-progress-bg::after {
                    display: none;
                }
            }
        }
    }

    // Customize Ant Design components:
    .course-item .ant-card-actions {
        display: flex;
        border: none;
        background-color: #fcfcfc;
        padding: 0 20px;
        margin-left: -4px;
        margin-right: -4px;
        margin-bottom: 8px;

        li {
            border: none;
            flex: 1;
            margin-left: 4px;
            margin-right: 4px;
        }

        button {
            min-width: auto;
            width: 100%;
            font-size: 14px;
            white-space: nowrap;
        }
    }

    //responsive mobile, tablet
    @include tablet-down {
        .course {
            &-item {
                padding: 14px 12px;
                display: flex;
                column-gap: 5px;
                background-color: #fff;

                .course-body {
                    padding: 5px;
                }

                .card-link {
                    width: 100%;
                }

                div.ant-card-cover {
                    display: none;
                }

                .ant-card {
                    background-color: #fff;
                    flex: 1;
                }

                .card-img-mobile {
                    display: inline-block;
                    overflow: hidden;
                    width: 25%;
                    min-width: 50px;
                    max-width: 120px;
                    padding-top: 5px;

                    &-wrap {
                        padding-bottom: 100%;
                        position: relative;
                    }

                    img {
                        position: absolute;
                        border-radius: 15px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    img.no-image {
                        opacity: 0.2;
                    }
                }

                .ant-card-body {
                    display: flex;
                }

                .ant-card-actions {
                    padding: 0;
                    background-color: #fff;
                    margin-bottom: 0;
                    > li {
                        margin-top: 8px;
                        margin-bottom: 8px;
                    }
                    button {
                        font-size: 12px;
                    }
                }

                .course-title {
                    font-size: 14px;
                }
            }
            &-info {
                &__item {
                    font-size: 12px;
                    margin-bottom: 2px;
                }
                span.text-bold {
                    font-weight: 600;
                }
            }

            &-progress {
                &__info {
                    font-size: 11px;
                    .info-status {
                        font-weight: 700;
                    }
                }
                .ant-progress-bg {
                    height: 8px !important;
                    &::after {
                        width: 11.4px;
                        height: 11.4px;
                        border-width: 2.7px;
                    }
                }
            }
        }

        .course-progress .ant-progress-inner {
            background-color: #f6f6f6;
        }
    }

    @include small-mobile {
        .course {
            &-item {
                .course-title {
                    font-size: 12px;
                }
            }
            &-info {
                &__item {
                    font-size: 10px;
                }
            }
            &-progress {
                &__info {
                    font-size: 10px;
                }
                .ant-progress-bg {
                    height: 7px !important;
                    &::after {
                        width: 10.4px;
                        height: 10.4px;
                        border-width: 2.7px;
                    }
                }
            }
        }
    }
}
