.sign-in-container {
    $input-text-color: rgba(99, 99, 100, 1);
    $input-icon-color: rgba(99, 99, 100, 0.7);
    .sign-in-form {
        .register-for {
            margin-bottom: 3;
        }
        .radio-btn {
            margin-bottom: 16px;
        }
        .radio-btn-item {
            position: relative;
            width: 130px;
            height: 48px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            cursor: pointer;
            &.checked {
                background: rgba(79, 227, 193, 0.05);
                /* #4FE3C1 */
                border: 1px solid #4fe3c1;
                p {
                    color: #4fe3c1;

                    font-weight: 700;
                }
                svg {
                    fill: #4fe3c1;
                }
            }
            svg {
                width: 22px;
                height: 30px;
                fill: rgba(99, 99, 100, 0.7);
            }

            p {
                margin-bottom: 0;
                font-size: 16px;
                color: $input-text-color;
                flex: 1;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                margin-left: 5px;
                text-align: center;
            }
            .ant-radio-wrapper {
                // margin-right: -10px;
                position: absolute;
                top: 16px;
                left: 0;
                opacity: 0;
            }
            .ant-radio {
                width: 0;
                height: 0;
            }
        }
        .sign-in-option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 18px;
        }

        .submit-btn {
            width: 100%;
        }

        .radio-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > h4 {
                font-weight: 700;
                font-size: 16px;
                margin-right: 14px;
            }

            .ant-radio-group {
                display: flex;
                flex-wrap: wrap;
                row-gap: 16px;
                column-gap: 30px;
            }
        }
    }
    @media screen and (max-width: 1599px) {
        .sign-in-form {
            .radio-btn {
                margin-bottom: 0;
            }
            .radio-btn-item {
                height: 40px;
            }
            .radio-btn .ant-radio-group {
                column-gap: 16px;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .sign-in-wrapper {
            .sign-in-form h4.register-for {
                margin-right: 12px;
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .sign-in-wrapper {
            .sign-in-form {
                .radio-btn {
                    justify-content: flex-start;
                }
                h4.register-for {
                    margin-right: 16px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .sign-in-wrapper .sign-in-form {
            .radio-btn {
                flex-direction: column;
                align-items: flex-start;
            }
            .radio-btn-item {
                width: 150px;
            }
            p {
                text-align: center;
            }
        }
    }
    @media screen and (max-width: 479px) {
        .sign-in-wrapper .sign-in-form {
            .ant-radio-group {
                width: 100%;
                justify-content: space-between;
            }
            .radio-btn label {
                margin-right: 0;
            }
        }
    }
    @media screen and (max-width: 389px) {
        .sign-in-wrapper .sign-in-form {
            .radio-btn-item {
                width: 130px;
            }
        }
    }
}
