@use 'src/assets/styles/utilities/colors' as c;

.login-tabs-wrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 36px 36px;
}

.login-tabs {
    width: 600px;
    background-color: #fff;
    box-shadow: 15px 15px 35px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 0 36px;

    .ant-tabs > .ant-tabs-nav {
        width: 100%;
        height: 55px;
        padding: 5px;
        background: rgba($color: c.$primary1, $alpha: 0.1);
        border-radius: 50px;
        margin-bottom: 36px;

        .ant-tabs-nav-list {
            width: 100%;
            border-radius: 30px;
            overflow: hidden;
            transition: transform 0.3s linear;
        }

        &::before {
            display: none;
        }
    }

    .ant-tabs-tab {
        justify-content: center;
        width: 50%;
        font-size: 16px;

        & + .ant-tabs-tab {
            margin-left: 0;
        }

        &:hover {
            color: unset;
        }

        &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: #fff;
                text-align: center;
                font-weight: 700;
            }
        }
    }

    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
        height: 100%;
        background-color: c.$primary1;
        border-radius: 30px;
        z-index: -1;
    }

    .ant-tabs-nav-operations {
        display: none !important;
    }

    .separator {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            background-color: #fff;
            padding: 0 10px;
            z-index: 3;
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            border-top: 1px solid #bfbfbf;
        }
    }

    .ant-tabs-tabpane {
        display: block !important;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease;

        &:nth-child(2) {
            transform: translateX(-100%);
        }

        &.ant-tabs-tabpane-active {
            visibility: visible;
            opacity: 1;
        }
    }
}

// RESPONSIVE DESIGN:
@media screen and (max-width: 1399.98px) {
    .login-tabs {
        width: 500px;

        .ant-tabs > .ant-tabs-nav {
            height: 46px;
        }
    }
}

@media screen and (max-width: 991.98px) {
    .login-tabs {
        width: 100%;
        background-color: transparent;
        box-shadow: unset;

        .ant-tabs > .ant-tabs-nav {
            height: 46px;
            // background: rgba(255, 255, 255, 0.5);
            // border: 1px solid #0077ff;
        }

        // .ant-tabs-tab {
        //     &.ant-tabs-tab-active {
        //         .ant-tabs-tab-btn {
        //             color: c.$primary1;
        //         }
        //     }
        // }

        // .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
        //     background-color: rgba($color: c.$primary, $alpha: 0.1);
        // }
    }
}
