@use 'src/assets/styles/utilities/colors' as c;

.qp-speech-to-text-conversation {
    // min-width: 360px;
    // overflow-x: auto;

    .speech-to-text-conversation__container {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .content-speech-to-text {
    }
    .speech-and-text {
        display: flex;
        align-items: center;
        justify-content: center;

        .speech-recognition {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .text-record {
            margin: 10px 0;
        }
        .record-button-wrapper {
            margin-top: 10px;
        }
    }
    .speech-to-text-frame {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 20px;

        .sample-sentence {
            margin-top: 20px;
        }
    }
    .note {
        .record-icon {
            background: c.$second;
            padding: 5px;
            border-radius: 50%;

            width: 20px;
            height: 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            // color: #fff;
        }
    }
}
