.candidate-info-avatar {
    $borderRadious: 10px;
    width: 100%;

    .avatar-wrapper {
        position: relative;
        width: 200px;
        height: 242px;
        border-radius: $borderRadious;
        overflow: hidden;
    }
    .avatar-placholer {
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            border-radius: $borderRadious;
            object-fit: cover;
        }
        .image-skeleton-item {
            height: 100%;
        }
    }
    .avatar-overlay {
        transition: all 3s;
        display: none;
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        &-inner {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .actions {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }
    }

    &:hover {
        .avatar-overlay {
            display: block;
        }
    }
}
