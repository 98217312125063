@use 'src/assets/styles/utilities/colors' as c;

.exam-access {
    min-height: calc(100vh - 108px);
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    .access-content {
        padding: 30px;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        background-color: #fff;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        overflow: auto;

        .ant-form {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }

        .form-actions {
            margin-top: 16px;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .access-info {
        margin-bottom: 30px;

        .info-title {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            font-size: 26px;
            line-height: 32px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.5);
            transition: all 0.2s ease;

            .info-icon {
                background: rgba(0, 0, 0, 0.5);
                width: 45px;
                height: 45px;
                min-width: 45px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
                color: #fff;
                transition: all 0.2s ease;
            }
        }

        .info-title.active {
            color: c.$success;

            .info-icon {
                background: c.$success;
                box-shadow: 0 0 0 5px rgba($color: c.$success, $alpha: 0.2);
            }
        }
    }

    .exam-access-code {
        .ant-form-item {
            max-width: 450px;
            margin: 0 auto;
            background-color: #f3f9f7;
            padding: 30px;
            border-radius: 10px;
        }

        .ant-form-item-label {
            font-weight: bold;
        }
    }
}
