.assignment-report-wrapper .overview {
    .overview-statistical {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 20px;
        height: 100%;

        .ant-spin-nested-loading,
        .ant-spin-container {
            height: 100%;
        }

        .ant-spin-nested-loading > div > .ant-spin {
            max-height: unset;
        }

        .statistics-data {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            height: 100%;
        }

        span {
            font-size: 18px;
            line-height: 35px;
            display: inline-block;
        }

        .course-name {
            background-color: rgba(0, 255, 42, 0.1);
            color: #129d29;
            padding: 2.5px 20px;
            border-radius: 10px;
            margin-bottom: 30px;
        }

        .progress-bar {
            p {
                font-size: 16px;
                line-height: 32px;
                opacity: 0.6;
                margin-bottom: 5px;
            }

            h1 {
                font-size: 36px;
                line-height: 36px;
                margin-bottom: 5px;
                font-weight: bold;
            }
        }

        .estimate {
            margin-top: 30px;
            border-radius: 25px;
            background-color: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            padding: 7px 20px;
        }
    }

    .overview-chart {
        position: relative;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 20px;
        padding: 50px 20px;
        height: 100%;
        width: 100%;

        .ant-spin-nested-loading {
            min-height: 400px;
            height: 100%;
        }

        .chart-null {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
        }
    }

    .overview-student-info-list {
        margin-top: 40px;

        .option-menu-wrapper {
            display: flex;
            align-items: center;
        }

        .option-choose-all {
            display: flex;
            align-items: center;
            margin-right: 55px;

            span {
                font-size: 18px;
                line-height: 35px;
                cursor: pointer;
            }

            .app-checkbox {
                margin-right: 15px;
            }
        }

        .option-filter {
            & > span {
                display: inline-block;
                margin-right: 15px;
                font-weight: 400;
                font-size: 18px;
                line-height: 45px;
            }

            .app-select {
                .ant-select-selector {
                    border-color: #0077ff;
                    color: #0077ff;

                    .ant-select-selection-item {
                        font-weight: bold;
                    }
                }

                .ant-select-arrow {
                    font-weight: bold;
                    color: #0077ff;
                    font-size: 14px;
                }
            }
        }

        .student-info-list {
            margin-top: 15px;

            .student-card {
                height: 100%;

                &:hover {
                    box-shadow: 1px 1px 2px rgb(0 0 0 / 50%);
                    // cursor: pointer;
                }
            }

            .ant-spin-nested-loading {
                min-height: 176.28px;
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .assignment-report-wrapper .overview {
        .overview-student-info-list {
            .option-menu-wrapper {
                justify-content: space-between;
                align-items: flex-start;
            }

            .option-filter {
                > span {
                    display: block;
                }
            }
        }
    }
}
