@use "src/assets/styles/utilities/breakpoint" as b;

.sptt-conversation_computer-answer {
    .label-col {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .label {
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .transcript {
        margin: 0;
        font-size: 18px;
    }

    .speaker {
        padding: 4px;
        background-color: #eee;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        width: 36px;
        height: 36px;

        > span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 22px;
            height: 22px;
        }

        // &.active .computer-answer_inner {
        //     padding: 5px;
        //     border: solid 1px #ddd;
        //     border-radius: 10px;
        //     min-width: 376px;
        //     display: inline-block;
        // }
        &.play {
            &::after,
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                top: 0;
                left: 0;
                transform-origin: center;
                background-color: #ddd;
                z-index: -1;
            }

            &::after {
                animation-name: pulse;
                animation-duration: 1s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-direction: initial;
            }

            &::before {
                animation-name: pulse;
                animation-duration: 1s;
                animation-timing-function: linear;
                animation-delay: 0.5s;
                animation-iteration-count: infinite;
                animation-direction: initial;
            }
        }
    }
    .pinyin-text {
        font-size: 14px !important;
        min-height: unset !important;
    }

    &:hover .speaker {
        cursor: pointer;
        opacity: 0.7;
    }

    // &.active .computer-answer_inner {
    //     padding: 5px;
    //     // border: solid 1px #6c94fb;
    //     border-radius: 10px;
    //     box-shadow: 0px 1px 6px 1px #ddd;
    // }
    .computer-answer_wrapper {
        // border: dashed 1px #ddd;
        border-radius: 10px;
        padding: 8px;
        display: flex;
        align-items: center;
    }
    &.active .computer-answer_wrapper {
        border: none;
        box-shadow: 0px 1px 6px 1px #ddd;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(1.5);
            opacity: 0;
        }
    }
    @include b.small-mobile {
        .label-col {
            justify-content: flex-start;
        }
    }
}
