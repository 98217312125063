@use 'src/assets/styles/utilities/variables' as vars;

.mathchar-btns-w-fltr {
    // display: inline-block;
    .bwf-section {
        display: flex;
        align-items: center;
        column-gap: 15px;
        padding: 8px 0;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }

    .mathchar-btn-list {
        width: 100%;
    }

    .bwf-button-row {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.mathchar-select {
    &.ant-select {
        width: 100%;
    }
}

.mathchar-dropdown {
    &.ant-select-dropdown {
        z-index: 1500;
    }
}

// RESPONSIVE DESIGN:
.mathchar-btns-w-fltr {
    $width_btn: clamp(36px, calc(50 / vars.$w * 100vw), 50px);
    $width_gap: 10px;
    width: calc($width_btn * 6 + $width_gap * 5);
}

.mathchar-btns-w-fltr-8 {
    $width_btn: clamp(36px, calc(50 / vars.$w * 100vw), 50px);
    $width_gap: 10px;
    width: calc($width_btn * 8 + $width_gap * 5);
}

@media screen and (max-width: 575.98px) {
    .mathchar-btns-w-fltr {
        $width_btn: clamp(36px, calc(50 / vars.$w * 100vw), 50px);
        $width_gap: 5px;
        width: calc($width_btn * 6 + $width_gap * 5);

        .bwf-button-row {
            gap: $width_gap;
            &:not(:last-child) {
                margin-bottom: $width_gap;
            }
        }
    }
}
