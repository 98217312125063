@use 'src/assets/styles/utilities/colors' as c;

.screencammic-recorder {
    .recording-status {
        position: fixed;
        bottom: 8px;
        right: 8px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;

        .recording-actions {
            display: flex;
            justify-content: center;
            align-content: center;
            gap: 8px;

            .action-button {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 14px;
                width: 26px;
                height: 26px;
                border: none;
                outline: none;
                border-radius: 10px;
                cursor: pointer;

                &.start {
                    background-color: c.$primary;
                }

                &.stop {
                    background-color: c.$danger;
                }

                &:hover {
                    opacity: 0.8;
                }

                &:active {
                    transform: scale(0.95);
                }
            }
        }

        .save-button {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: c.$primary;
            font-size: 14px;
            width: 26px;
            height: 26px;
            border-radius: 10px;

            &:hover {
                opacity: 0.8;
            }

            &:active {
                transform: scale(0.95);
            }
        }

        .satus-on {
            .icon {
                display: inline-block;
                color: c.$danger;
                animation-name: winking;
                animation-duration: 0.5s;
                animation-timing-function: ease;
                animation-delay: 0s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-fill-mode: forwards;
            }

            .abbr {
                display: inline-block;
                color: c.$danger;
                margin-left: 6px;
            }
        }
    }
}

@keyframes winking {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
