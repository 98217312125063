@use 'src/assets/styles/utilities/colors' as colors;

@mixin styleText($type: default) {
    @if $type==text45 {
        line-height: 45px;
    } @else if $type==text36 {
        line-height: 36px;
    }
}

.question-detail.math-text-dropdown {
    .question-formitems.question-setanswers {
        .ant-form-item-label > label {
            min-width: 50px;
        }

        .action-btn {
            .btn__content {
                background-color: #ddd;
            }

            .btn__icon-wrapper > svg {
                fill: #000;
                width: 24px;
            }

            &.danger:hover {
                .btn__content {
                    background-color: colors.$danger;
                }

                .btn__icon-wrapper > svg {
                    fill: #fff;
                }
            }
        }

        .question-answer-list {
            & > div:not(:first-child) {
                margin-top: 16px;
            }

            .list-item {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                gap: 8px;
                background-color: #fff;
                padding: 8px;
                border-radius: 10px;

                &:hover {
                    box-shadow: 0px 0px 15px rgb(0, 0, 0, 0.1);
                }
            }

            .list-item-order {
                font-size: 16px;
                @include styleText($type: text45);
            }

            .list-item-body {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                gap: 8px;
                // 48px = padding-left (list-item) + padding-right (list-item) + width (list-item-actions).
                width: calc(100% - 48px);
            }

            .list-item-answer {
                position: relative;
                width: calc(100% - 50px - 8px);
            }

            .list-item-actions {
                @include styleText($type: text45);
            }

            .list-item-text {
                display: inline-block;
                font-size: 16px;
                min-height: 36px;
                line-height: 32px;
                padding: 2px 0;
            }
        }
    }

    .item-w-answer {
        // Variables:
        $wGap: 8px;
        // CSS:
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: $wGap;

        .child-operand {
            // Variables:
            $wGapChild: 4px;
            // CSS:
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: $wGapChild;
            width: calc(50% - $wGap - 5px);

            & > .ant-form-item {
                width: calc(50% - 2px);
                margin-bottom: 0;
            }
        }

        .child-val-ans {
            // &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
            // & .mce-content-body,
            &.app-input-latex .mq-editable-field {
                font-weight: bold;
                border: 2px solid colors.$second !important;
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }

        .child-unit {
            // &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
            // & .mce-content-body,
            &.app-input-latex .mq-editable-field {
                background-color: #eee;
            }
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .question-detail.math-text-dropdown {
        .question-formitems.question-setanswers {
            .question-answer-list {
                .list-item-order {
                    @include styleText($type: text36);
                }

                .list-item-actions {
                    @include styleText($type: text36);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: visible;

                    &::after {
                        content: ".";
                        white-space: nowrap;
                        width: 0;
                        visibility: hidden;
                        opacity: 0;
                    }

                    .action-wrapper {
                        height: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199.98px) {
    .question-detail.math-text-dropdown {
        .item-w-answer {
            .child-operand {
                flex-direction: column;

                & > .ant-form-item {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .question-detail.math-text-dropdown {
        .item-w-answer {
            flex-wrap: wrap;

            .child-operand,
            .child-char {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .question-detail.math-text-dropdown {
        .question-formitems.question-setanswers {
            .question-answer-list {
                .list-item {
                    flex-direction: column;
                }

                .list-item-body {
                    flex-direction: column;
                    width: 100%;
                }

                .list-item-actions {
                    align-self: flex-end;
                }

                .list-item-answer {
                    width: 100%;
                }
            }
        }
    }
}
