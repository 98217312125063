/*
 * Global class names:
 * .view-graph
 */

.q-graph {
    .q-content-section {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}

.view-graph {
    background-color: #eee;
    padding: 8px;
    border-radius: 10px;
}
