@use "src/assets/styles/utilities/breakpoint" as *;

.studentFrame_wrapper {
    background: #fff;
    border-radius: 20px;
    padding: 10px 30px;
    margin: 30px 0;

    .studentFrame_navItem {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .studentFrame_header {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
    }

    .studentFrame_title {
        font-size: 16px;
        font-weight: bold;
    }

    .studentFrame_select {
        display: none;

        .ant-select-selector {
            min-width: 100px;
        }
    }
}

@include tablet-down {
    .studentFrame_wrapper {
        background-color: transparent;
        padding: 10px 0 0 0;
        margin: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .studentFrame_wrapper {
        min-width: 150px;
        position: relative;

        .studentFrame_header {
            .studentFrame_title {
                white-space: nowrap;
            }

            .studentFrame_select {
                display: block;
            }
        }
    }
}
