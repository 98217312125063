@use 'src/assets/styles/utilities/colors' as colors;

// ClassNames: app-input-search.

.app-input-search {
    position: relative;
    display: block;

    .input-search-icon {
        position: absolute;
        top: 50%;
        right: 6.5px;
        transform: rotate(90deg) translateX(-50%);
        width: 32px;
        height: 32px;
        font-size: 18px;
        background-color: #eeeeee;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &.style-dream {
        .input-search-icon {
            background-color: colors.$dream;

            & > svg {
                fill: #fff;
            }
        }

        .input-search-icon:hover {
            opacity: 0.7;
        }
    }
}

// RESPONSIVE DESIGN:
@media screen and (max-width: 1399.98px) {
    .app-input-search {
        .input-search-icon {
            right: 4px;
            width: 28px;
            height: 28px;
        }
    }
}
