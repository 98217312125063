.ant-modal.app-modal.modal-detail-course {
    max-width: 1400px;
    .ant-modal-content {
        // padding: 20px;
        .table-action {
            gap: 4px;
            justify-content: right;
        }
    }

    .ant-modal-body {
        margin-top: 45px;
    }
}
