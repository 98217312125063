.manage-history_filter-menu {
    .ant-picker {
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
        width: 100%;
        padding: 4px 16px;
    }
    .filter-toolbar-wrapper .filter-toolbar {
        display: block;
    }
}
