.identity-verification {
    &_content {
        display: flex;
        justify-content: center;
        min-height: calc(100vh - 108px - 92px - 30px);
        min-width: 1200px;
        padding: 30px;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        background-color: #fff;
        margin: 0 auto;
        overflow: auto;
        animation: appearing 0.5s ease 0s 1 normal forwards;
    }
}
