.file-viewer {
    &.view-file-error {
        font-size: 18px;
        color: #fff;
        user-select: none;
    }

    &.view-file-wrapper {
        width: 100%;
        height: 100%;

        &.align-center {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.align-bottom {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        & > audio {
            width: 100%;
        }

        & > video {
            width: auto;
            height: 100%;
            border-radius: 10px;
        }
    }

    &.view-other-file-wrapper {
        width: 100%;
        height: 100%;
        background-color: #fff;
    }
}
