@use 'src/assets/styles/utilities/colors' as c;
@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/breakpoint' as b;

.breadcumb {
    font-size: 24px;
    line-height: 40px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
    color: #fff;

    &-mobile__back {
        display: none;
    }

    .breadcumb__container {
        display: flex;
        justify-content: space-between;
        height: 60px;
        padding: 10px 20px;

        background-image: c.$color_second_dark;
        border-radius: 15px;

        .breadcumb__content {
            display: flex;
            align-items: center;
            row-gap: 8px;
            column-gap: 16px;

            .breadcumb__home-icon {
                display: flex;
                align-items: center;
                height: 100%;
                margin-right: 18px;
                cursor: pointer;

                svg {
                    color: #fff;
                    width: 28px;
                    height: 26px;
                }

                &:hover {
                    svg {
                        color: c.$primary1;
                    }
                }
            }

            .breadcumb__back {
                display: flex;
                align-items: center;
                padding: 0 8px;
                cursor: pointer;

                svg {
                    height: 30px;
                    fill: #fff;
                }

                &:hover {
                    opacity: 0.6;
                }
            }

            .breadcumb__title {
                display: flex;
                align-items: center;
                // height: 100%;
                color: #fff;

                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;

                .breadcrumb-item {
                    color: #fff;
                    &:hover {
                        text-decoration: underline;
                    }
                }

                // .breadcrumb-item.unlink {
                // }

                &-slash {
                    color: #fff;
                }
            }

            // :not(.breadcumb__icon):not(.breadcumb__back) {
            //     margin-left: 18px;
            // }
            .breadcumb__home-icon {
                margin-right: 0;
                margin-top: -6px;
            }
        }

        .breadcumb__control {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 10px;
        }
    }

    @include b.tablet-down {
        &.mobile {
            .breadcumb__container {
                height: auto;
                min-height: 200px;
                padding-top: 25px;
                border-radius: 0;
                .breadcumb__content {
                    flex: 1;
                    flex-direction: column;
                    align-items: flex-start;
                    .breadcumb__home-icon,
                    .breadcumb__back {
                        display: none;
                    }
                    .breadcumb__title {
                        width: 100%;
                        height: auto;
                        display: flex;
                        justify-content: center;
                        margin-top: 25px;
                        margin-bottom: 50px;
                        a {
                            font-size: 20px;
                            line-height: 26px;
                            letter-spacing: 0em;
                            text-align: center;
                        }
                        :not(:first-child) {
                            display: none;
                        }
                    }
                }
                .breadcumb-mobile__back {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    > span {
                        font-size: 14px;
                        font-weight: 400;
                        margin-left: 10px;
                        color: #fff;
                    }
                }
            }
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1439.98px) {
    .breadcumb {
        margin-bottom: clamp(16px, calc(30 / vars.$h * 100vh), 30px);
    }
}
