@use 'src/assets/styles/utilities/colors' as c;

.PassageResult_wrapper {
    .PassageResult_inner {
        .PassageResult_tabs {
            > .ant-tabs-nav {
                &::before {
                    border-bottom: 0;
                }

                .ant-tabs-nav-wrap {
                    .ant-tabs-nav-list {
                        padding: 5px;
                        border: 1px solid c.$second;
                        border-radius: 10px;

                        .ant-tabs-tab {
                            width: 150px;
                            height: 35px;
                            border-radius: 10px;
                            justify-content: center;
                            background-color: #fff;
                            border: none;
                            font-size: 18px;

                            &.ant-tabs-tab-active {
                                background-color: c.$second;

                                div {
                                    color: #fff;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }

                @media screen and (max-width: 768px) {
                    .ant-tabs-nav-wrap {
                        justify-content: center;
                    }
                }
            }

            .PassageResult_itemQuestion {
                .title {
                    font-weight: bold;
                    font-size: 18px;
                    margin-bottom: 30px;
                }

                .passage_content {
                    p {
                        margin-bottom: 30px;
                    }
                }
            }

            .PassageResult_answer {
                .teacher-mark.passage {
                    margin-top: 20px;
                    margin-bottom: 50px;

                    strong,
                    span {
                        font-size: 16px;
                    }

                    .ant-input-number.app-input {
                        font-size: 16px;
                        line-height: 16px;
                    }
                }

                .student-view-mark.passage {
                    margin-bottom: 50px;

                    strong,
                    span {
                        font-size: 16px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}
