@use 'src/assets/styles/utilities/breakpoint.scss' as b;

.teacher-detail-unit.unit-detail {
    .unit-detail-item {
        margin-bottom: 30px;
    }

    .list-action-mobile {
        display: none;
    }

    .course-list-filter {
        margin: 16px 0;
        text-align: right;
    }

    .course-list-filter_skin {
        display: block;
        margin-left: auto;

        > span {
            margin-right: 4px;
        }
    }

    .unit-assignment-list {
        .list-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;
            margin-bottom: 30px;
        }

        .list-wrapper {
            .assignment-item-wrapper {
                height: 100%;

                //style for antd spin
                div[class*="ant-spin"],
                div[class*="ant-spin"] div[class^="ant-spin"] {
                    height: 100%;
                }
                .dropdown-btn {
                    height: auto;
                    padding: 2px 6px;
                    border-radius: 8px;

                    & > span {
                        display: block;

                        & > svg {
                            display: block;
                            font-size: 20px;
                        }
                    }
                    @include b.tablet-down {
                        border-radius: 5px;
                        span > svg {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .list-msg {
            background-color: #eee;
            border-radius: 10px;
            padding: 8px;
            color: #888;
        }
    }

    .assignment-item-loadmore {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f1f1f1f1;
        border: 1px solid #ddd;
        border-radius: 10px;
        min-height: 200px;
    }
    .course-topic-info-panel .topic-quick-info {
        min-height: 60px;

        .label-content {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
    }

    @include b.tablet-up {
        .course-topic-info-panel .topic-quick-info .topic-label {
            min-width: 250px;
            width: 250px;
        }
    }
    @include b.tablet-down {
        .unit-detail-item {
            margin-bottom: 20px;
        }
        .unit-assignment-list {
            .list-actions {
                display: none;
            }
        }
        .list-action-mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            margin-bottom: 15px;
            font-size: 16px;
            font-weight: 700;
        }
        .teacher-unit-wrapper {
            margin-top: -45px;
            padding: 40px 15px 15px 15px;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            background-color: #f7f7f7;
        }
        .assignment-item-loadmore {
            min-height: 100px;
        }

        .course-topic-info-panel .topic-quick-info .topic-label {
            min-width: 170px;
            height: 100%;
        }
    }
    @include b.small-mobile {
        .unit-detail-item {
            margin-bottom: 15px;
        }
        .course-topic-info-panel .topic-quick-info {
            min-height: unset;
        }
        .topic-title .title-content {
            font-weight: 700;
        }
        .list-action-mobile {
            margin-bottom: 15px;
        }
    }
}

.ant-dropdown-menu.asgmt-list-item-dropdown {
    .item-btn {
        width: 100%;
        padding: 5px 12px;

        > div:first-child {
            min-width: 25px;
        }
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        padding: 0;
    }
}
