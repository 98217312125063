@use 'src/assets/styles/utilities/variables' as vars;

.q-result.q-writing {
    .view-writing {
        padding: 16px;
        border-radius: 10px;
        background-color: #eee;
        max-height: 500px;
        overflow-y: auto;
        // [Text-displaying-related components]:
        font-size: vars.$txt-font-size;
        line-height: vars.$txt-line-height;
    }
}
