.drag-drop-detail__answer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 100%;

    &--align-right {
        flex-direction: row-reverse;
    }

    .drag-drop-detail__deleteIcon {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
            opacity: 0.3;
        }
    }

    .drag-drop-detail__label {
    }

    .drag-drop-detail__input {
        width: 100%;

        .input-editor {
            width: 100%;
        }
    }

    .drag-drop-detail__answer-skeleton {
        display: flex;
        align-items: center;
        column-gap: 5px;
        width: 100%;

        &--align-right {
            flex-direction: row-reverse;
        }
    }
}
