@use 'src/assets/styles/utilities/colors' as c;

.detail_conversation {
    .list-actors {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 20px;
        .actor-input {
            width: auto;
        }
    }
    .conversation-list {
        // .conversation-item {
        //     display: flex;
        //     align-items: center;
        //     gap: 10px;
        // }
        .ant-space-item {
            &:first-child {
                width: 96%;
            }
        }
        .conversation-item_delete {
            font-size: 24px;
            cursor: pointer;
        }
    }
    .site-form-item-icon {
        &.active {
            svg {
                background-color: c.$primary;
                border-radius: 50%;
                font-size: 20px;
            }
        }
    }

    .speech_to_text_select .ant-form-item-label {
        overflow: unset;
        .form_item_label {
            &-parent {
                position: relative;
            }
            &-child {
                position: absolute;
                bottom: -22px;
                left: 4%;
            }
        }
    }
}
