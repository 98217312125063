.student-courses {
    .assignment-item-wrapper {
        height: 100%;
    }
    .assignment-item-loadmore {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f1f1f1f1;
        border: 1px solid #ddd;
        border-radius: 10px;
        min-height: 200px;
    }
    @media screen and (max-width: 991px) {
        .assignment-item-loadmore {
            min-height: 100px;
        }
    }

    .unit_detail-step .ant-steps-item-title {
        padding-right: 0;
    }
}
