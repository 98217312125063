.notification_menu {
    padding: 10px 20px;
    box-shadow: 1px 2px 5px #000;
    z-index: 20;
    background: #fff;
    border-radius: 4px;

    .notification_title {
        font-size: 16px;
        font-weight: bold;
        padding: 10px 0;
        text-align: center;
    }

    .notification_testList {
        .notification_testItem {
            display: flex;
            justify-content: space-between;
            margin: 5px 0;
            align-items: center;

            .testItem_name {
                font-size: 16px;
            }

            .testItem_button {
                background-color: orangered;
                border: none;
                margin-left: 10px;
                transition: none;

                &:active {
                    transform: scale(0.95);
                    transform-origin: center;
                }
            }
        }
    }
}
