.question-history {
    overflow-x: auto;
    .table-answer {
        border-collapse: collapse;
        width: 100%;

        th {
            background-color: #e8f3fa;
        }

        th,
        td {
            border: 1px solid #aaa;
        }

        th,
        td {
            padding: 8px;
        }

        td {
            vertical-align: top;
            min-width: 200px;
        }
    }

    .viewScoreStudent_listAnswer.history {
        gap: 10px;
        flex-wrap: wrap;
    }

    .list-drag_drop_group {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .table_conversation {
        border: 1px dashed #aaa;
        th,
        td {
            border: 1px dashed #aaa;
            font-size: 16px;
        }
        tr:nth-child(odd) {
            background-color: #f5f6e5;
        }
    }

    .history-list-passage-question {
        border: none;
        .passage-question-item {
            width: 100%;
        }
        .question {
            border: solid 1px #ccc;
            padding: 10px;
            margin-top: 10px;
            border-radius: 4px;
            &:hover {
                cursor: pointer;
                border: solid 2px #546fea;
            }
        }
        .question-title {
            margin-left: 10px;
        }
    }
}
