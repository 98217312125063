[zwibbler] {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
}

[z-canvas] {
    flex: 1 1 auto;
}
.zwibbler-main {
    flex: 1 1 auto;
}

[z-has="AnyNode"] {
}

.zwibbler-draw-container {
    min-height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: auto;
}

.toolbar-wrapper {
    width: 100%;
}

.zwibbler-container {
    position: relative;
}

.custom-zwibbler {
    position: absolute;
    height: 100%;
    width: 100%;
    // z-index: 999;
    top: 0px;
    left: 0px;
    display: block;
}

.zwibbler-canvas-holder {
    border: 2px dotted #ccc;
    padding: 20px;
}
.zwibbler-scrollbar {
    display: none !important;
}
.canvas-background-img {
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 100%;
}

.zwibbler-image-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
        width: 100%;
        height: 100%;
    }
}

.zwibbler-colour-panel {
    display: none;
}

.zwibbler-draw-container {
}
