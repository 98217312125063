@use 'src/assets/styles/utilities/breakpoint.scss' as b;
@use 'src/assets/styles/utilities/colors' as colors;

.teacher-course-detail {
    .detail-course-header {
        border-radius: 10px;
        padding: 16px;
    }

    .table-student-list .filter-toolbar-item.w-50 {
        max-width: 40% !important;
    }

    .course-detail-top {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding-bottom: 30px;
        border-bottom: 1px solid #d9d9d9;

        .course-detail-title {
            font-size: 26px;
            line-height: 35px;
            font-weight: 700;
            margin-bottom: 0;
        }

        p:last-child,
        .course-detail-actions-top {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            align-items: center;
            flex-direction: column;
            margin-bottom: 0;

            .adduser-manual-sec {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-wrap: wrap;
                gap: 8px;
                width: 100%;
                margin-bottom: 8px;
            }

            .adduser-auto-sec {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-wrap: wrap;
                gap: 8px;
                width: 100%;

                .button-import-export {
                    margin-bottom: 18px;
                }
            }
        }
    }

    .course-detail-management {
        margin-top: 30px;

        .ant-tabs {
            & > .ant-tabs-nav {
                margin-bottom: 30px;

                &::before {
                    display: none;
                }
            }

            .ant-tabs-nav-list {
                border: 1px solid #0077ff;
                padding: 5px;
                border-radius: 10px;

                .ant-tabs-tab {
                    display: flex;
                    justify-content: center;
                    background-color: transparent;
                    border: none;
                    border-radius: 10px;

                    &.ant-tabs-tab-active {
                        // background-color: #0077ff;
                        z-index: 2;

                        span {
                            color: #fff;
                            font-weight: bold;
                            @include b.tablet-down {
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            .ant-table {
                .ant-table-thead {
                    .owner-ship-wrapper {
                        display: flex;
                        align-items: center;

                        span {
                            margin-right: 5px;
                        }
                    }
                }

                .ant-table-tbody {
                    .ant-table-cell {
                        text-align: center;

                        &:first-child {
                            span {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .icon {
                                    position: absolute;
                                    top: 50%;
                                    left: -5px;
                                    transform: translateY(-50%);
                                    cursor: pointer;

                                    &:hover {
                                        opacity: 0.6;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .table-student-list {
        .list-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        .list-btn {
            &.btn.btn-icon-only .btn__content {
                background-color: transparent;
                transition: all 0.5s ease;
            }

            .btn__icon-wrapper svg {
                fill: colors.$primary;
                height: 22px;
                max-width: 22px;
                transition: all 0.5s ease;
            }

            &:hover {
                &.btn.btn-icon-only .btn__content {
                    background-color: colors.$primary;
                }

                .btn__icon-wrapper svg {
                    fill: #fff;
                }
            }

            &.btn-danger {
                .btn__icon-wrapper svg {
                    fill: colors.$danger;
                }

                &:hover {
                    &.btn.btn-icon-only .btn__content {
                        background-color: colors.$danger;
                    }

                    .btn__icon-wrapper svg {
                        fill: #fff;
                    }
                }
            }

            &:disabled,
            &.disabled {
                .btn__icon-wrapper svg {
                    fill: #cdcfd0;
                }
            }
        }
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
        top: 5px;
        bottom: 5px;
        height: auto !important;
        border-radius: 10px;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar {
        visibility: visible;
        z-index: 0;
    }

    .course-topic-info-panel .topic-quick-info .topic-label {
        min-width: 236px;
        height: 100%;
    }
    @include b.tablet-down {
        .course-detail-management .ant-tabs .ant-tabs-nav-list {
            border-radius: 30px;
            height: 40px;
        }
        .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
            border-radius: 30px;
        }
        .course-topic-info-panel .topic-quick-info .topic-label {
            min-width: 170px;
            height: 100%;
        }
    }
}

// Modals:
.course-detail-modal.create-exam {
    .ant-modal-content {
        height: 100%;
        background: none;

        .ant-modal-close {
            background: none;
            filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
            width: 35px;
            height: 35px;
            line-height: 35px;
            position: absolute;
            top: -8px;
            right: -8px;
            border-radius: 5px;
            overflow: hidden;

            .ant-modal-close-x {
                background-color: #fff;
                position: relative;
                width: 100%;
                height: 100%;
                line-height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                fill: #000000;
                border-radius: 5px;
            }

            &:hover {
                .ant-modal-close-x {
                    background-color: #f8f8f8;
                }
            }

            &:active {
                .ant-modal-close-x {
                    transform: scale(0.8);
                }
            }
        }

        .ant-modal-body {
            height: 100%;
            background-color: #fff;
            padding: 0;
            border-radius: 10px;
            overflow: hidden;

            & > div:first-child {
                height: 100%;
                padding: 35px 30px 35px 30px;
                overflow-y: auto;
            }
        }
    }
}

.course-detail-modal.detail-learning-progress,
.course-detail-modal.detail-marking {
    .ant-modal-content {
        height: 100%;

        .ant-modal-body {
            height: calc(100% - 68px); // Exclude header's height!
            overflow-y: auto;
        }
    }
}

// Others:
.table-column-filter-menu {
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: 1px solid #0077ff;
    list-style: none;
    border-radius: 4px;
    overflow: hidden;

    li {
        font-size: 18px;
        line-height: 35px;
        padding: 3px 10px;
        cursor: pointer;

        &:hover {
            background-color: #f0f7ff;
        }

        &.active {
            background-color: #f0f7ff;
        }
    }
}

// Responsive:
@media screen and (max-width: 991.98px) {
    .teacher-course-detail {
        .course-detail-top {
            flex-direction: column;

            .course-detail-title {
                width: 100%;
            }

            p:last-child,
            .course-detail-actions-top {
                width: 100%;
            }
        }
    }
}
