@use "src/assets/styles/utilities/breakpoint" as *;

.grade-book {
    &__container {
        background-color: #fff;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 20px 10px;
        border-radius: 10px;
        min-width: 600px;
        min-height: 500px;
    }

    &__tabs {
        position: relative;

        .ant-tabs-top > .ant-tabs-nav:before {
            border: none;
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
            border: 1px solid #f7475b;
            padding: 5px;
            height: 46px;
            border-radius: 4px;
        }

        .ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar {
            visibility: visible;
            top: 4px;
            bottom: 4px;
            height: auto;
            z-index: 0;
            border-radius: 4px;
            background: linear-gradient(180deg, #fb3b50 0%, #f54e62 100%);
        }
        .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
            border: none;
            background-color: transparent;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #fff;
            font-weight: 700;
            z-index: 2;
        }

        .filterButton {
            position: absolute;
            right: 50px;
            top: 3px;
            &.teacher {
                right: 10px;
                top: 0;
            }

            .filter-btn {
                cursor: pointer;
                height: 40px;
                font-weight: 700;
                background: linear-gradient(180deg, #fb3b50 0%, #f54e62 100%);
                border-radius: 4px;
                border: none;
                color: #fff;
                &:hover {
                    opacity: 0.9;
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
                }
                svg {
                    font-weight: 700;
                }
                span[aria-label="filter"] {
                    margin-right: 5px;
                }
            }
        }

        /* table antd */

        .table-top {
            border: solid 1px #d9d9d9;
            border-radius: 8px;
            padding: 10px 15px;
            margin: 0 50px;
            margin-bottom: 30px;
            table {
                td,
                th {
                    border: 0 !important;
                }
                th {
                    border-bottom: 1px solid #d9d9d9 !important;
                }
                thead th {
                    background-color: #fff !important;
                }
            }
            .ant-table-container {
                border: none;
            }
        }
        .table-bottom {
            margin-top: 20px;

            .ant-table .ant-table-container .ant-table-thead tr > th {
                background: linear-gradient(180deg, #fb3b50 0%, #f54e62 100%);
                border-left: none;
                color: #fff;
                &:first-child {
                    border-radius: 8px 0 0 8px;
                }
                &:last-child {
                    border-radius: 0 8px 8px 0;
                }
            }
        }
    }

    @include tablet-down {
        &__tabs {
            padding-top: 30px;
        }

        &__container {
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            background-color: #f7f7f7;
            padding-top: 45px;
            margin-top: -44px;
            min-width: unset;
            box-shadow: unset;
        }

        &__tabs {
            .filterButton {
                // top: 0;
                .filter-btn {
                    height: 35px;
                    border-radius: 30px;
                    font-size: 14px;
                    padding: 0 10px;
                    font-weight: 500;
                    background: linear-gradient(180deg, #3f6fea 0%, #6c94fb 100%);
                }
                &.student {
                    right: 10px;
                    top: -30px;
                }
            }
            .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
                height: 40px;
                border-radius: 30px;
                background: rgba(63, 111, 234, 0.1);
            }
            .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
                border-radius: 30px;
            }
            .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
                border-color: #5b86f4;
            }
            .ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar {
                background: linear-gradient(180deg, #3f6fea 0%, #6c94fb 100%);
                border-radius: 30px;
            }
            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                font-weight: 400;
            }
        }
    }
}
