.q-result.q-drag-drop-group {
    .qc_selected-answers {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 8px;
    }

    .qc_correct-answers {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .group_of_drag_drop {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 8px;
        width: 100%;

        .answer-for-drag-drop-group {
            width: 100%;
        }
    }
}
