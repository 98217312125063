@use 'src/assets/styles/utilities/colors' as c;

// .question-content {
//     // Global
//     .title-success,
//     .title-danger {
//         margin: 4px 0;
//         font-size: 18px;
//     }

//     .title-success {
//         color: green;
//         font-weight: bold;
//     }

//     .title-danger {
//         color: red;
//         font-weight: bold;
//     }
// }

.question-result-panel {
    $title-font-weight: 700;
    $title-font-size: 26px;
    $title-line-height: 35px;
    animation: transitionRightToLeft 0.3s;

    // Elements:
    .question-header-wrapper {
        margin-bottom: 20px;
        color: #000;

        .question-header {
            font-weight: $title-font-weight;
            font-size: $title-font-size;
            line-height: $title-line-height;
        }
    }

    .question-number-wrapper {
        width: 40px;
        height: 40px;
        background-color: c.$second;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #fff;
        border-radius: 50%;
        margin-bottom: 20px;
        display: none;

        .question-number {
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 40px;
        }

        @media screen and (max-width: 768px) {
            display: block;
        }
    }

    .question-content {
        margin-bottom: 60px;
    }

    .question-comment {
        color: #000;

        .question-comment-title {
            font-weight: $title-font-weight;
            font-size: $title-font-size;
            line-height: $title-line-height;
            margin-bottom: 20px;
        }

        .question-comment-content {
            textarea {
                background-color: rgba(238, 238, 238, 0.5);
                border-radius: 10px;
                font-weight: 400;
                font-size: 18px;
                line-height: 25px;
                color: #000;
                padding: 12px 30px 12px 12px;
                border-color: transparent;
            }

            &.role-teacher textarea {
                background-color: rgb(248, 248, 248);
            }
        }
    }

    .label-wrapper-circle {
        width: 40px;
        height: 40px;
        background-color: #4fe3c1;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 10px;
    }

    .question-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;

        .nav-btn-wrapper {
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;

            .nav-btn {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: c.$primary;
                display: flex;
                justify-content: center;
                align-items: center;
                user-select: none;

                &:hover {
                    opacity: 0.8;
                }

                &:active {
                    transform: scale(0.8);
                }

                > svg {
                    fill: #fff;
                    width: 10px;
                }
            }
        }

        .nav-btn-wrapper.disabled {
            cursor: auto;

            .nav-btn {
                background-color: #eee;

                &:hover {
                    opacity: 1;
                }

                &:active {
                    transform: none;
                }
            }
        }

        @media screen and (max-width: 768px) {
            position: fixed;
            bottom: 40px;
            left: 0;
            right: 0;
        }
    }

    //score view
    .teacher-mark {
        margin: 10px 0;
        display: flex;
        align-items: center;

        strong {
            margin-right: 10px;
            font-size: 26px;
            line-height: 35px;
        }

        span {
            margin-left: 5px;
            font-size: 18px;
            line-height: 35px;
        }
    }

    .student-view-mark {
        margin-top: 20px;
        margin-bottom: 5px;
        color: #ff0000;

        &.correct {
            color: rgb(18, 157, 41);
        }

        strong {
            margin-right: 10px;
            font-size: 26px;
            line-height: 35px;
        }

        span {
            font-size: 26px;
            line-height: 35px;
            font-weight: bold;
        }
    }

    @media screen and (max-width: 768px) {
        .label-wrapper-circle {
            display: none;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .question-result-panel {
        // Elements:
        .question-header-wrapper {
            .question-header {
                font-size: 16px;
                line-height: 22px;
            }
        }

        .question-content {
            margin-bottom: 30px;
        }

        .question-comment {
            .question-comment-title {
                font-size: 18px;
                line-height: 22px;
            }

            .question-comment-content textarea {
                font-size: 14px;
                line-height: 19px;
            }
        }

        // score view
        .teacher-mark,
        .student-view-mark {
            span,
            strong {
                font-size: 18px;
                line-height: 22px;
            }

            .ant-input-number.app-input {
                height: 35px;
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
}
