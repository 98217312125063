// Scrollbar:
::-webkit-scrollbar {
    width: 16px;
}

::-webkit-scrollbar-track {
    background-color: #f0f2f5;
}

::-webkit-scrollbar-track:hover {
    background-color: #eee;
}

::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
    border: calc((16px / 2) / 2) solid transparent;
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
    border: calc((16px / 2) / 2) solid transparent;
    background-clip: padding-box;
}

.scroll_thin_grey {
    *::-webkit-scrollbar {
        width: 6px;
    }
    *::-webkit-scrollbar-thumb {
        background-color: rgba(187, 187, 187, 0.5) !important;
        border: calc((6px / 2) / 2) solid transparent;
    }
    *::-webkit-scrollbar-thumb:hover {
        background-color: rgb(187, 187, 187) !important;
        border: calc((6px / 2) / 2) solid transparent;
    }
}
