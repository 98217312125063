.qc_matrix {
    // display: flex;
    .matrix-group {
        margin: 20px 0;
    }

    .StaticMath {
        user-select: none;
        pointer-events: none;
    }
}
