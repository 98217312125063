@use 'src/assets/styles/utilities/colors' as c;

.qp-speech-to-text {
    .content-speech-to-text {
    }
    .speech-and-text {
        display: flex;
        align-items: center;
        justify-content: center;

        .speech-recognition {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .text-record {
            margin: 10px 0;
        }
        .record-button-wrapper {
            margin-top: 10px;
        }
    }
    .speech-to-text-frame {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 20px;

        .sample-sentence {
            margin-top: 20px;
        }
    }
}
