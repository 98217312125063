.teacher-course-detail {
    .table-exam-list {
        .ant-table table {
            width: 100% !important;
        }

        .ant-table .ant-table-expanded-row-fixed {
            width: auto !important;
        }

        .ant-table table colgroup col {
            &:nth-child(1) {
                width: auto !important;
                min-width: 170px;
            }

            &:nth-child(2) {
                width: auto !important;
                min-width: 170px;
            }

            &:nth-child(3) {
                width: auto !important;
                min-width: 170px;
            }

            &:nth-child(4) {
                width: auto !important;
                min-width: 50px;
            }

            &:nth-child(5) {
                width: auto !important;
                min-width: 50px;
            }

            &:nth-child(6) {
                width: auto !important;
                min-width: 50px;
            }

            &:nth-child(7) {
                width: auto !important;
                min-width: 100px;
            }

            &:nth-child(8) {
                width: auto !important;
                min-width: 100px;
            }
        }

        .action-icon {
            font-size: 22px;
            color: #0077ff;
            fill: #0077ff;
        }
    }
}
