.dragging-area {
    position: relative;
    &:after {
        background-color: #ffffff70;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    &.dragging {
        &:after {
            content: "";
        }
    }
    .dragging-content {
        overflow: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .dragging-fog {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        transform: translate(-50%, -50%);
        font-size: 40px;
        width: 100%;
        text-align: center;
    }
}
