.forget-password {
    min-height: calc(100vh - 108px);
    display: flex;
    justify-content: center;
    align-items: center;

    .sign-in-wrapper {
        width: 100%;
        max-width: 500px;
        text-align: center;

        .forget-password-wrapper {
            margin: auto;
            text-align: left;

            @media screen and (max-width: 767px) {
                width: 100%;
            }

            .forget-password-img {
                text-align: center;
                margin-bottom: 20px;
            }

            .sign-in-register {
                text-align: center;

                & > a {
                    margin-left: 8px;
                }
            }

            .btn {
                width: 100%;
                padding: 8px 10px;
            }

            //email sent
            .redirect-btn {
                margin-top: 70px;
                margin-bottom: 20px;
            }
        }
        @media screen and (min-width: 1920px) {
            margin-top: 10%;
        }
        @media screen and (max-width: 320px) {
            .forget-password-img img {
                width: 220px;
            }
        }
    }
}

.reset-password {
    .sign-in-wrapper {
        animation: swipeFromLeft 0.3s ease-in-out;
    }
}

@keyframes swipeFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
