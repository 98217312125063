@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

.q-title {
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 4px 8px;
    font-size: 16px;
    user-select: none;
}

.q-content-title {
    padding: 8px 0;
    margin-bottom: 8px;
    border-top: 1px solid #eee;
    max-height: 500px;
    overflow-y: auto;
    // [Text-displaying-related components]:
    font-size: vars.$txt-font-size;
    line-height: vars.$txt-line-height;
}

@keyframes transitionRightToLeft {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
}

.question-show-answer {
    margin: 10px 0;
    border-radius: 10px;
    padding: 10px;
    background-color: #fff;
    border: 2px solid colors.$primary;
    cursor: pointer;

    &:active {
        transform: scale(0.97);
    }
}

.question-panel {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    overflow: hidden;

    // Label:
    .question-label-wrapper {
        position: relative;

        &::after {
            position: absolute;
            content: "";
            top: -48px;
            left: -47px;
            width: 115px;
            height: 115px;
            background: colors.$primary;
            border-radius: 50%;
        }

        .question-label {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 68px;
            height: 67px;
            z-index: 1;

            .label-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #fff;
                padding: 5px 24px 15px 20px;
            }

            .label-question {
                font-size: 16px;
                line-height: 26px;
                letter-spacing: -0.03em;
            }

            .label-number {
                font-weight: 700;
                font-size: 25px;
                line-height: 26px;
                letter-spacing: -0.03em;
                margin-top: -5px;
            }
        }
    }

    // Nav:
    .question-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px 30px 30px;

        .nav-btn-wrapper {
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            padding: 0;
            outline: none;
            border: none;
            cursor: pointer;

            .nav-btn {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: colors.$primary;
                display: flex;
                justify-content: center;

                align-items: center;
                user-select: none;

                &:hover {
                    opacity: 0.8;
                }

                &:active {
                    transform: scale(0.8);
                }
            }
        }

        .nav-btn-wrapper.disabled {
            opacity: 0.2;
            cursor: not-allowed;

            .nav-btn {
                background-color: #333;
                &:hover {
                    opacity: 1;
                }

                &:active {
                    transform: none;
                }
            }
        }
    }

    // Content:
    .question-content {
        padding: 90px 30px 40px 30px;
        animation: transitionRightToLeft 0.3s;

        img {
            max-width: 100%;
        }
    }

    .ant-select-selector {
        max-width: 200px;
    }

    @media screen and (max-width: 768px) {
        .question-content {
            padding: 90px 30px 50px 30px;
        }
    }

    // Content actions:
    .content-actions {
        position: relative;

        .btn-show-pinyin {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            z-index: 10;

            &.is-off {
                svg {
                    --light-color: #ffffff;
                }
            }

            &.is-on {
                .btn__content {
                    background-color: #ffd700;
                    box-shadow: 0 0 0 4px rgba($color: #ffd700, $alpha: 0.4);
                }

                svg {
                    --light-color: #ffd700;
                }
            }
        }
    }
}
