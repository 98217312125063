@use 'src/assets/styles/utilities/colors' as c;

.exam-asset.exam-asset-reup {
    min-height: calc(100vh - 108px - 60px);

    .form-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
    }

    .exam-asset-upload {
        background-color: #f3f9f7;
        padding: 8px 30px;
        min-height: 40px;

        & > .ant-row {
            margin-bottom: 0;
        }
    }

    .exam-asset-upload-progress {
        margin-bottom: 16px;

        .progress-bar {
            position: relative;
            display: block;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.06);
            overflow: hidden;

            .progress-fulfilled {
                --width: 0;
                position: relative;
                width: var(--width);
                height: 8px;
                transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

                &.pending {
                    background-color: c.$info;
                }

                &.success {
                    background-color: c.$success;
                }

                &.danger {
                    background-color: c.$danger;
                }
            }
        }
    }

    .exam-asset-redirecting {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-style: italic;
        margin-bottom: 16px;

        .ant-spin-dot-spin {
            display: block;
        }
    }
}
