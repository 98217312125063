.face-verification {
    .image-item {
        text-align: center;
        border-radius: 10px;
        overflow: hidden;

        img {
            border: solid 2px #ccc;
            border-radius: 10px;
            aspect-ratio: 300/400;
            min-width: 300px;
            min-height: 400px;
            object-fit: cover;
        }
    }

    &_images {
        display: flex;
        justify-content: center;
        gap: 40px;
    }

    &_info {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .identity-verification_info {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    @keyframes appearing {
        from {
            transform: translateY(32px);
        }

        to {
            transform: translateY(0);
        }
    }
}
