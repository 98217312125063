.filter-toolbar-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 10px;

    .filter-toolbar {
        flex: 1 1 0%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 10px;

        .filter-toolbar-item {
            flex: 1 1 0%;
            min-width: 200px;
            position: relative;

            &:nth-child(1) {
                max-width: 500px;
            }

            &.filtermenu-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;
                min-height: unset;

                .filter-keys-bar-wrapper {
                    max-width: calc(100% - 175px - 8px);
                }
            }
        }
    }

    .filter-toolbar-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }
}

.filter-keys-bar-wrapper {
    width: 100%;
    height: 100%;
    // min-height: 45px;
    // background-color: #fff;
    // border: 1px solid #d9d9d9;
    border-radius: 10px;
    font-size: 14px;
    font-style: italic;
    color: rgba(0, 0, 0, 0.5);
    // padding: 4px 11px;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
        border-color: #40a9ff;
    }

    .filter-keys-bar {
        width: 100%;
        height: 100%;
        min-height: 35px;
        justify-content: right;

        .ant-space-item {
            max-width: 100%;
            line-height: initial;
        }
    }
}

@media screen and (max-width: 1399.98px) {
    .filter-toolbar-wrapper {
        column-gap: 16px;

        .filter-toolbar {
            .filter-toolbar-item {
                &:nth-child(1) {
                    max-width: unset;
                }
            }
        }
    }
}

@media screen and (max-width: 1199.98px) {
    .filter-toolbar-wrapper {
        .filter-toolbar {
            .filter-toolbar-item {
                .btn {
                    min-width: unset;
                }
            }
        }

        .filter-toolbar-actions {
            .btn {
                min-width: unset;
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .filter-toolbar-wrapper {
        .filter-toolbar-actions {
            width: 100%;
            text-align: right;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .filter-toolbar-wrapper {
        .filter-toolbar {
            align-items: stretch;
            flex-direction: column;
        }
    }
}
