.assignment-bank {
    .assignment-bank-table {
        .ant-table table {
            width: 100% !important;
        }

        .ant-table .ant-table-expanded-row-fixed {
            width: auto !important;
        }

        .assignment-bank-action {
            justify-content: right !important;
        }

        .ant-table table colgroup col {
            &:nth-child(1) {
                width: auto !important;
                min-width: 170px;
            }
            &:nth-child(2) {
                width: auto !important;
                min-width: 170px;
            }
            &:nth-child(3) {
                width: auto !important;
                min-width: 170px;
            }
            &:nth-child(4) {
                width: auto !important;
                min-width: 170px;
            }
            &:nth-child(5) {
                width: auto !important;
                min-width: 170px;
            }
            &:nth-child(6) {
                width: auto !important;
                min-width: 150px;
            }
            &:nth-child(7) {
                width: auto !important;
                min-width: 150px;
            }
            &:nth-child(8) {
                width: auto !important;
                min-width: 100px;
            }
        }

        .btn-action-dropdown {
            border: 1.4px solid #445cfe;

            &:hover {
                background: linear-gradient(180deg, #3f6fea 0%, #6c94fb 100%);
                color: #fff;
                // border-color: #fff;
            }
        }
    }

    &.action-btn-menu {
        .ant-dropdown-menu-item {
            &:hover {
                background-color: #3f6fea;
                color: #fff;
            }
            svg {
                width: 16px;
                height: 16px;
            }
            .ant-space-item {
                display: flex;
                align-items: center;
            }
        }
    }
}

.assignment_filter_menu {
    .ant-col.ant-form-item-label {
        width: auto;
    }
}

@media screen and (max-width: 1399.98px) {
    .assignment-bank {
        .assignment-bank-table {
            .ant-table table colgroup col {
                &:nth-child(1) {
                    min-width: 100px;
                }
                &:nth-child(2) {
                    min-width: 100px;
                }
                &:nth-child(3) {
                    min-width: 100px;
                }
                &:nth-child(4) {
                    min-width: 100px;
                }
                &:nth-child(5) {
                    min-width: 100px;
                }
                &:nth-child(6) {
                    min-width: 80px;
                }
                &:nth-child(7) {
                    min-width: 80px;
                }
                &:nth-child(8) {
                    min-width: 100px;
                }
            }
        }
    }
}
