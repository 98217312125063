.exam-notes-wrapper {
    background-color: #f3f9f7;
    padding: 30px;
    border-radius: 10px;

    .exam-notes {
        margin-bottom: 8px;

        .title-head {
            font-weight: bold;
        }
    }

    .notes {
        margin-bottom: 16px;

        & > ul {
            margin-bottom: 16px;
        }

        .ant-checkbox-wrapper {
            font-size: 16px;
            user-select: none;
        }
    }

    .notes-actions {
        display: flex;
        justify-content: center;
        align-content: center;
        gap: 8px;
    }
}
