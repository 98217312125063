.app-nav-menu {
    max-height: 500px;
    overflow: auto;

    .ant-dropdown-menu-title-content {
        color: rgba(0, 0, 0, 0.6);
    }

    .nav-menu-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 8px;
        padding-right: 8px;
        position: relative;

        &:hover .custom-nav-dropdown {
            display: block;
        }

        .nav-icon {
            width: 50px;
            height: 30px;
            text-align: left;

            & > svg {
                display: block;
                width: 30px;
                height: 30px;
            }

            [data-icon="history"] {
                width: 25px;
                height: 25px;
                fill: url(#paint0_linear_1895_1189);
            }
        }
    }
}

.subnav-dropdown-menu {
    margin-top: -2px;
    .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child,
    .ant-dropdown-menu-item {
        padding: 0;
    }
    .subnav-link {
        width: 100%;
        height: 100%;
        // display: flex;
    }
    .subnav-item {
        padding: 8px 12px;
        width: 100%;
        height: 100%;
        position: relative;
        ::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 1.4px;
            background-color: #6c94fb;
            transition: width 0.2s linear;
        }
        &:hover {
            background-color: rgb(232, 230, 230);
            ::after {
                width: 100%;
            }
        }
    }
    .subnav-item.active {
        background-color: #eee !important;
        ::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1.4px;
            background-color: #6c94fb;
        }
    }
}
