.question-detail-wrapper {
    // Form items::
    .ant-form-item {
        align-items: flex-start;

        .ant-form-item-label > label {
            display: flex;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            height: 100%;
            line-height: 45px;
        }
    }

    // ANT DESIGN - Input, Input.Number:
    .app-input.ant-input,
    .app-input.ant-input-affix-wrapper {
        font-size: 18px;
    }

    .app-input.ant-input-number {
        display: block;
        min-width: 200px;
        font-size: 18px;
    }

    .app-input.ant-input-group-wrapper,
    .app-input.ant-input-number-group-wrapper {
        .ant-input {
            font-size: 18px;
        }
    }

    // Header:
    .page-header-wrapper {
        margin-bottom: 30px;
    }

    // Button list:
    .btn-group {
        margin-top: 30px;

        button.btn {
            padding-top: 5.5px;
            padding-bottom: 5.5px;

            .btn__icon-wrapper {
                height: 30px;

                & > svg {
                    height: 100%;
                }
            }
        }

        button.btn.btn-primary {
            .btn__icon-wrapper > svg {
                max-width: unset;
            }
        }

        .ant-form-item {
            margin-bottom: 0;
        }

        .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 30px;
        }
    }

    // Layout:
    .question-detail,
    .question-detail-preview {
        // Variables:
        $sthHasPaddingY: 20px; // padding-top + padding-bottom.
        $sthHasMarginBottom: 60px; // = 30px + 30px.
        $headerHeight: 208px; // = 88px + 60px + 60px.
        // Properties:
        min-height: calc(100vh - ($sthHasPaddingY + $sthHasMarginBottom + $headerHeight));
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    // Question detail - Layout & elements:
    .question-detail {
        .question-formitems {
            .ant-form-item .ant-form-item-label > label {
                margin-right: 20px;
                // width: 30px;
            }
        }

        .question-formfield--v {
            margin-bottom: 30px;

            > .formfield-title {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 35px;
            }

            > div {
                display: block;
                width: 100%;
            }

            .ant-form-item {
                margin-bottom: 0;
            }
        }

        .question-formfield-msg {
            width: 100%;
            font-style: italic;
            margin-bottom: 8px;

            .msg-header {
                font-weight: 700;
                margin-right: 8px;
            }
        }

        .question-editor {
            margin-bottom: 30px;

            > .question-editor-title {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 35px;
            }

            > div {
                display: block;
                width: 100%;
            }

            .ant-form-item {
                margin-bottom: 0;
            }
        }

        .question-imgwithlbls {
            margin-bottom: 30px;

            > .question-imgwithlbls-title {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 35px;
            }

            .image-with-labels-wrapper {
                width: 100%;
            }
        }

        .question-setanswers {
            margin-bottom: 30px;

            > .question-setanswers-title {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 35px;
            }

            .question-answer-list {
                width: 100%;
            }

            .question-answer-actions {
                .action-add {
                    display: inline-flex;
                    align-items: center;
                    cursor: pointer;
                    font-size: 16px;
                    line-height: 24px;

                    &:hover {
                        text-decoration: underline;
                    }

                    & > span {
                        margin-right: 5px;
                    }

                    button {
                        font-size: 25px;
                        padding-bottom: 10px;
                        background-color: #000;
                        color: #fff;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        outline: none;
                        border: none;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

        .question-setcheck {
            label {
                font-size: 18px;
            }
        }

        .question-editor {
            .question-editor-hint-text {
                font-size: 14px;
                font-weight: normal;
                font-style: italic;

                .hint-text.highlighted {
                    color: #0077ff;
                    font-weight: bold;
                }
            }
        }
    }

    // Question preview - Layout & elements:
    .question-detail-preview {
        .question-panel {
            margin-bottom: 30px;
        }

        .question-formitems {
            margin-bottom: 30px;
        }

        .question-formitems.set-publishing-status {
            justify-content: center;
            margin-bottom: 0;
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .question-detail-wrapper {
        .ant-form-item {
            margin-bottom: 8px;

            .ant-form-item-label > label {
                line-height: 36px;
            }
        }

        .app-input.ant-input,
        .app-input.ant-input-affix-wrapper {
            line-height: 26px;
        }

        .app-input.ant-input-number {
            line-height: 26px;
        }

        &.app-input.ant-input-group-wrapper,
        &.app-input.ant-input-number-group-wrapper {
            .ant-input {
                line-height: 26px;
            }
        }

        .app-editor-input .mce-content-body {
            line-height: 26px;
        }

        .btn-group {
            button.btn {
                min-width: 140px;
                line-height: 20px;

                .btn__icon-wrapper {
                    height: 24px;
                }
            }
        }

        .question-detail,
        .question-detail-preview {
            // Variables:
            $sthHasPaddingY: 20px; // padding-top + padding-bottom.
            $sthHasMarginBottom: 60px; // = 30px + 30px.
            $headerHeight: 194px; // = 74px + 60px + 60px.
            // Properties:
            min-height: calc(100vh - ($sthHasPaddingY + $sthHasMarginBottom + $headerHeight));
        }
    }
}

@media screen and (max-width: 991.98px) {
    .question-detail-wrapper {
        .question-detail,
        .question-detail-preview {
            // Variables:
            $sthHasPaddingY: 50px; // padding-top + padding-bottom.
            $sthHasMarginBottom: 30px;
            $headerHeight: 70px;
            // Properties:
            min-height: calc(100vh - ($sthHasPaddingY + $sthHasMarginBottom + $headerHeight));
        }
    }
}

@media screen and (max-width: 767.98px) {
    .question-detail-wrapper {
        .btn-group {
            .ant-form-item-control-input-content {
                justify-content: flex-end;
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .question-detail-wrapper {
        .btn-group {
            button.btn {
                width: 100%;
            }
        }
    }
}
