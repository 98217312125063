@use "src/assets/styles/utilities/breakpoint" as b;

.teacher-unit-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
}
.unit-list-table {
    @include b.tablet-down {
        .teacher-unit-wrapper {
            margin-top: -45px;
            padding: 40px 15px 15px 15px;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            background-color: #f7f7f7;
        }
    }
}
