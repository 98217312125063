.student-header {
    .student-header_container {
        padding: 50px 30px;
        background-color: #0077ff;
        border-radius: 20px;
        position: relative;
    }

    .student-header_controlPanel {
        position: relative;
        z-index: 2;
        display: none;
        justify-content: space-between;
        align-items: center;
        .student-header_showMore {
        }

        .student-header_avatarWrapper {
            img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
            }
        }

        .student-header_notification {
            margin-left: auto;
            padding: 0 20px;

            img {
                width: 15.81px;
                height: 18.79px;
                filter: brightness(0) invert(1);
            }
        }
    }
    .student-header-wrapper {
        // width: 100%;
        // svg {
        //     display: block;
        // }

        // p {
        //     display: block;
        // }
        // // display: none;
    }

    .student-header-wrapper {
        width: 60%;

        p {
            color: #fff;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .student-header-title {
            font-size: 26px;
            line-height: 35px;
            font-weight: 700;
            -webkit-line-clamp: 1;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../../assets/images/decoration-clock.svg);
            background-repeat: no-repeat;
            background-position: center right;
            z-index: 1;
            mix-blend-mode: soft-light;
            opacity: 0.25;
        }

        &::before {
            content: "";
            position: absolute;
            top: 12%;
            right: 15%;
            width: 164px;
            height: 180px;
            background-image: url(../../../assets/images/girl_and_book.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            z-index: 1;
        }
    }
}
